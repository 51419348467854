<template>
  <dropdown
    :placement="placement"
    :triggers="triggers"
    :distance="distance"
    :eager-mount="true"
    :shown="isShow"
    :auto-hide="autoHide"
    :shift="shift"
    :disabled="isDisabled"
    :container="container"
    :delay="delay"
    @apply-hide="hideEventListener()"
  >
    <slot name="button"></slot>
    <template #popper>
      <slot name="tooltip"></slot>
    </template>
  </dropdown>
</template>

<script>
import { Dropdown } from "floating-vue";

export default {
  name: "Tooltip",
  components: {
    Dropdown,
  },
  props: {
    placement: {
      type: String,
      default: "auto",
      validator(value) {
        return [
          "auto",
          "auto-start",
          "auto-end",
          "top",
          "top-start",
          "top-end",
          "right",
          "right-start",
          "right-end",
          "bottom",
          "bottom-start",
          "bottom-end",
          "left",
          "left-start",
          "left-end",
        ].includes(value);
      },
    },
    triggers: {
      type: Array,
      default: function () {
        return ["click"];
      },
      validator(arr) {
        return ["click", "hover", "focus", "touch"].some(
          (r) => arr.indexOf(r) >= 0
        );
      },
    },
    distance: {
      type: Number,
      default: 10,
    },
    isShow: {
      type: Boolean,
      required: false,
    },
    autoHide: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    shift: {
      type: Boolean,
      default: false,
    },
    container: {
      type: String,
      default: ".console",
    },
    delay: {
      type: Object,
      default: () => {
        return {
          show: 0,
          hide: 400,
        };
      },
    },
  },
  setup(props, { emit }) {
    const hideEventListener = () => {
      emit("hideEvent");
    };

    return { hideEventListener };
  },
};
</script>
