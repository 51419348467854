<template>
  <div class="alias-section">
    <div class="text-section">
      <h2 class="title">
        크리에이터님만의 주소를 <br class="mo" />
        만들어 주세요
      </h2>
      <p class="sub-text-s2 text-gray-second desc">
        만들어진 주소는 브랜드 홈과 모든 판매 페이지에 사용됩니다.
        <br class="pc" />나중에 변경하는 것이 불가하므로 신중하게 결정해 주세요.
      </p>
    </div>
    <input-prefix
      class="user-alias sub-text-s2"
      fixed-value="bigc.im/"
      placeholder="yourname"
      :default-value="state.aliasName"
      :fixed-value-style="{
        background:
          'linear-gradient(89.61deg,#eb7484 0%,#df4eef 35.42%,#672ff2 100%)',
        webkitBackgroundClip: 'text',
        webkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }"
      @updateData="(value) => actions.updateAliasName(value)"
    ></input-prefix>
    <p v-if="state.errorMessage" class="error sub-text-s3 text-red-50">
      {{ state.errorMessage }}
    </p>

    <button-basic
      class="save-btn"
      text="만들기"
      :disabled="!state.activeBtn"
      @action="actions.createAliasName()"
    ></button-basic>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import swal from "../../../../helper/swal";
import InputPrefix from "@/components/console/inputs/InputPrefix";
import ButtonBasic from "@/components/console/buttons/ButtonBasic";
import ApiService from "@/api";
import _ from "lodash";

export default {
  name: "OnBoardingAlias",
  components: { InputPrefix, ButtonBasic },
  props: {
    aliasName: {
      type: String,
      default: "",
    },
  },
  emits: ["updateAliasName", "complete"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      aliasName: "",
      errorMessage: "",
      isAvailableAlias: false,
      activeBtn: computed(() => {
        return !state.errorMessage && state.isAvailableAlias;
      }),
    });

    onMounted(() => {
      if (props.aliasName) {
        state.aliasName = props.aliasName;
        state.isAvailableAlias = true;
      }
    });

    const removeTextSpace = (text) => {
      let value = text;
      let regex = / /gi;
      return value.replace(regex, "");
    };

    const existAliasName = _.debounce((value) => {
      getAliasAvailable(value);
    }, 500);

    const getAliasAvailable = async (value) => {
      let result = await ApiService.getUserAliasAvailable({
        aliasName: value,
      });
      if (!result.data.success) {
        state.errorMessage = result.data.message;
        state.isAvailableAlias = false;
      } else {
        if (state.errorMessage) {
          state.errorMessage = "";
        }
        state.isAvailableAlias = true;
      }

      return state.isAvailableAlias;
    };

    const actions = {
      createAliasName: async () => {
        if (!state.aliasName) {
          swal.errorToast("사용하려는 주소를 입력해주세요.");
          return;
        }

        let isAvailable = await getAliasAvailable(state.aliasName);

        let payload = {
          aliasName: state.aliasName,
        };

        if (isAvailable) {
          emit("complete");
          await store
            .dispatch("onBoardings/putOnBoardingAlias", payload)
            .then(() => {
              emit("complete");
            });
        }
      },
      updateAliasName: (value) => {
        state.aliasName = value;

        if (state.aliasName) {
          existAliasName(state.aliasName);
        }
        emit("updateAliasName", removeTextSpace(state.aliasName));
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
