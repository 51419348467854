<template>
  <div class="preview-section">
    <div class="preview-wrapper">
      <div class="tooltip">
        <div class="tooltip-text">
          <span class="deco-font">bigc.im/</span>
          <span>{{ aliasName }}</span>
        </div>
      </div>
      <div class="profile-wrapper">
        <div class="profile">
          <avatar class="user-avatar" :avatar-img="avatarImg" size="58">
          </avatar>
          <div class="creator-name-area">
            <div
              v-if="state.isCreatorName"
              class="creator-name text-gray-reverse"
            >
              {{ creatorName }}
            </div>
            <div v-if="!state.isCreatorName" class="empty-text"></div>
          </div>
          <div class="introduction-area">
            <div
              v-if="state.isDescription"
              class="introduction text-gray-reverse"
            >
              {{ description }}
            </div>
            <div v-if="!state.isDescription" class="empty-text"></div>
          </div>
          <div class="follow">
            <div class="share-icon">
              <share-icon
                width="12"
                height="12"
                fill-color="#ffffff"
              ></share-icon>
            </div>
            <div class="text">
              <heart-icon width="8" height="8"></heart-icon>
              <span> 팔로우 </span>
            </div>
          </div>
          <div class="channel">
            <div
              v-for="(channel, index) in state.channels"
              :key="`channel-${index}`"
              :class="{ active: channel.url }"
              class="channel-logo-cover"
            >
              <div class="channel-logo-color"></div>
              <img :src="channel.icon" class="channel-logo" />
            </div>
          </div>
          <div class="empty-content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import Avatar from "@/components/console/avatars/Avatar";
import ShareIcon from "@/components/console/icons/ShareIcon";
import HeartIcon from "@/components/console/icons/HeartIcon";

export default {
  name: "OnBoardingPreview",
  components: { Avatar, ShareIcon, HeartIcon },
  props: {
    aliasName: {
      type: String,
      default: "",
    },
    avatarImg: {
      type: String,
      default: "",
    },
    creatorName: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    channels: {
      types: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    const state = reactive({
      isCreatorName: computed(() => {
        return props.creatorName;
      }),
      isDescription: computed(() => {
        return props.description;
      }),
      channels: computed(() => {
        let channels = props.channels;
        if (channels.length > 5) {
          return channels.slice(0, 5);
        }
        return channels;
      }),
    });

    const actions = {};

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
