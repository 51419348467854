<template>
  <div class="interest-section">
    <div class="interest-input-area">
      <h2 class="title">어떤 주제로 활동하시나요?</h2>
      <p class="sub-text-s2 text-gray-second desc">
        해당 주제에 관심있는 팬들에게 추천해 드립니다.<br />
        (최대 5개까지 선택 가능합니다.)
      </p>
      <ul class="interest-list">
        <li
          v-for="interest in state.interests"
          :key="`interest-item-${interest.id}`"
        >
          <button-basic
            bg-color="#f6f8fa"
            shape="round"
            :color="
              state.selectedInterests.includes(interest.name)
                ? '#8E1EFF'
                : '#0D0D10'
            "
            :border="true"
            :border-color="
              state.selectedInterests.includes(interest.name)
                ? `#8E1EFF`
                : `#F6F8FA`
            "
            text-size="s2"
            padding="7px 16px"
            :text="interest.name"
            @action="actions.toggleInterest(interest)"
          >
          </button-basic>
        </li>
      </ul>
    </div>

    <button-basic
      class="save-btn"
      text="다음"
      :disabled="state.disableSaveBtn"
      @action="actions.saveInterests()"
    >
      다음
    </button-basic>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import { computed, onBeforeMount, reactive } from "vue";
import ApiService from "@/api";
import swal from "@/helper/swal";

export default {
  name: "OnBoardingInterest",
  components: { ButtonBasic },
  emits: ["complete"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      interests: [],
      selectedInterests: [],
      disableSaveBtn: computed(() => {
        return state.selectedInterests.length === 0;
      }),
    });

    onBeforeMount(() => {
      ApiService.getOnBoardingInterest().then((res) => {
        if (res.data.success) {
          state.interests = res.data.meta.interestTags;
          state.selectedInterests = res.data.data.reduce((result, current) => {
            result.push(current.name);
            return result;
          }, []);
        }
      });
    });

    const actions = {
      saveInterests: () => {
        store
          .dispatch("onBoardings/putOnBoardingInterest", {
            tags: state.selectedInterests,
          })
          .then(() => {
            emit("complete");
          });
      },
      toggleInterest: (interest) => {
        let interestIndex = state.selectedInterests.findIndex((item) => {
          return item === interest.name;
        });

        if (interestIndex > -1) {
          state.selectedInterests.splice(interestIndex, 1);
        } else {
          if (state.selectedInterests.length < 5) {
            state.selectedInterests.push(interest.name);
          } else {
            swal.messageAlert("최대 5개까지만 선택할 수 있습니다.");
          }
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
