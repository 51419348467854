import { createStore } from "vuex";
import auth from "./modules/auth";
import locale from "./modules/locale";
import notifications from "./modules/notifications";
import dashboard from "./modules/dashboard";
import contents from "./modules/contents";
import meetings from "./modules/meetings";
import comments from "./modules/comments";
import articles from "./modules/articles";
import plans from "./modules/plans";
import clubs from "./modules/clubs";
import channels from "./modules/channels";
import connects from "./modules/connects";
import communities from "./modules/communities";
import space from "./modules/space";
import clipboard from "./modules/clipboard";
import newClubs from "./modules/newClubs";
import onBoardings from "./modules/onBoardings";
import memberships from "./modules/memberships";
import notices from "./modules/notices";
import file from "./modules/file";
import settlements from "./modules/settlements";
import lectures from "./modules/lectures.ts";
import chats from "./modules/chats";
import polls from "./modules/polls";
// 모듈 추가시 logout 에 모듈 초기화 할것!
export default createStore({
  modules: {
    auth,
    locale,
    notifications,
    dashboard,
    contents,
    meetings,
    comments,
    articles,
    clubs,
    plans,
    channels,
    connects,
    communities,
    space,
    clipboard,
    newClubs,
    onBoardings,
    memberships,
    notices,
    settlements,
    file,
    lectures,
    chats,
    polls,
  },
});
