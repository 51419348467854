export default {
  contentType: {
    all: {
      type: null,
      label: "전체",
    },
    article: {
      type: 1,
      label: "아티클",
    },
    video: {
      type: 3,
      label: "영상",
    },
    link: {
      type: 5,
      label: "링크",
    },
    videoDrm: {
      type: 7,
      label: "Video DRM",
    },
    eduk: {
      type: 300,
      label: "eduk",
    },
  },

  blankImage: "https://cdn.bigc.im/statics/commons/images/empty.png",

  clubStatus: {
    reservation: {
      value: 1,
      label: "판매 예정",
    },
    open: {
      value: 2,
      label: "판매중",
    },
    finished: {
      value: 4,
      label: "판매종료",
    },
    hidden: {
      value: 8,
      label: "숨김",
    },
  },

  planStatus: {
    open: {
      value: 2,
      label: "판매중",
    },
    finished: {
      value: 4,
      label: "판매종료",
    },
    hidden: {
      value: 8,
      label: "숨김",
    },
  },

  CLUB_LOCKED_THEME: 2,

  // 유료콘텐츠 판매시작시 현재시간 or 예약 판매
  SALE_START_RESERVE: 1, // 예약 판매 시작
  SALE_START_NOW: 2, // 바로 판매 시작

  communityType: {
    list: {
      text: "리스트형",
      value: "list",
    },
    gallery: {
      text: "앨범형",
      value: "gallery",
    },
    feed: {
      text: "피드형",
      value: "feed",
    },
  },

  communityNoticeType: {
    all: {
      text: "전체",
      value: false,
    },
    creator: {
      text: "크리에이터",
      value: true,
    },
  },

  communityPublicStatus: {
    show: {
      text: "공개",
      value: true,
    },
    hide: {
      text: "비공개",
      value: false,
    },
  },

  sessionTypes: {
    vod: {
      text: "VOD",
      color: "#A44AFF",
    },
    live: {
      text: "LIVE",
      color: "#56DA2A",
    },
    content: {
      text: "CONTENT",
      color: "#00A3FF",
    },
    poll: {
      text: "VOTE",
      color: "rgb(255, 27, 148)",
    },
  },

  meetingTypes: {
    streaming: {
      text: "streaming",
      color: "#56DA2A",
    },
    seminar: {
      text: "seminar",
      color: "#56DA2A",
    },
    conference: {
      text: "conference",
      color: "#56DA2A",
    },
  },

  mimeTypes: {
    png: {
      value: "image/png",
      label: "png",
    },
    jpg: {
      value: "image/jpg",
      label: "jpg",
    },
    jpeg: {
      value: "image/jpeg",
      label: "jpeg",
    },
    gif: {
      value: "image/gif",
      label: "gif",
    },
    svg: {
      value: "image/svg+xml",
      label: "svg",
    },
    webp: {
      value: "image/webp",
      label: "webp",
    },
    avi: {
      value: "video/x-msvideo",
      label: "avi",
    },
    mp4: {
      value: "video/mp4",
      label: "mp4",
    },
    webm: {
      value: "video/webm",
      label: "webm",
    },
    aac: {
      value: "audio/x-aac",
      label: "aac",
    },
    midi: {
      value: "audio/midi",
      label: "midi",
    },
    ogg: {
      value: "audio/ogg",
      label: "ogg",
    },
    wav: {
      value: "audio/wav",
      label: "wav",
    },
    mp3: {
      value: "audio/mpeg",
      label: "mp3",
    },
    m4a: {
      value: "audio/mp4",
      label: "m4a",
    },
    txt: {
      value: "text/plain",
      label: "txt",
    },
    pdf: {
      value: "application/pdf",
      label: "pdf",
    },
    allVideo: {
      value: "video/*",
      label: "avi, mp4, webm",
    },
    allImage: {
      value: "image/*",
      label: "png, jpg, jpeg, gif, svg, webp",
    },
  },

  settlementStatus: {
    wait: {
      text: "입금 대기중",
      value: "W",
    },
    complete: {
      text: "정산 완료",
      value: "C",
    },
    fail: {
      text: "정산 실패",
      value: "F",
    },
  },

  currency: {
    KRW: "KRW",
    USD: "USD",
  },

  meetingStatus: {
    create: {
      type: 0,
      text: "예정",
    },
    waitting: {
      type: 1,
      text: "대기",
    },
    started: {
      type: 3,
      text: "시작",
    },
    finished: {
      type: 5,
      text: "종료",
    },
    deleted: {
      type: 9,
      text: "삭제",
    },
  },

  userType: {
    stater: 11,
    prePro: 21,
    pro: 22,
    enterprise: 31,
  },

  captchas: ["hCaptcha", "reCaptcha", "naverCaptcha", "bigcCaptcha"],

  bigcAwsBucket: process.env.VUE_APP_BIGC_AWS_BUCKET,
};
