import ApiService from "@/api";
import { CLUBS } from "../mutation-types";
import _ from "lodash";

const getDefaultState = () => {
  return {
    clubs: [],
    clubsMeta: {
      filters: {
        perPage: null,
        q: null,
        status: null,
      },
      statuses: [],
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
    clubsDashboard: {
      clubCount: 0,
      salesTicketCount: 0,
      salesAmountTotal: 0,
    },
    club: {
      allPlans: [],
      chargeTickets: 0,
      chat: {},
      clubCreatorDescription: null,
      clubDescriptions: [],
      community: {},
      cover: null,
      createdAt: "",
      description: null,
      descriptionHtml: "",
      faqs: [],
      favoritersCount: 0,
      featuredImage: "",
      finishAt: "",
      headline: null,
      host: {},
      introHost: null,
      introHostHtml: "",
      introSchedule: null,
      introScheduleHtml: "",
      introSession: null,
      introSessionHtml: "",
      introTicket: null,
      introTicketHtml: "",
      isComing: false,
      isDuration: false,
      isFavorited: false,
      isJoined: true,
      isFunding: false,
      isLiked: false,
      isPolicy: false,
      isPublic: true,
      isPublished: true,
      likersCount: 0,
      mFeaturedImage: "",
      meetings: [],
      personnel: 0,
      plan: null,
      plans: [],
      policy: null,
      progress: 0,
      publishedAt: "",
      resourceId: "",
      reviews: [],
      startAt: "",
      status: 0,
      statusabel: "",
      summarise: [],
      summary: {},
      tags: [],
      title: "",
      type: 0,
    },
    clubMeta: {
      iconTypes: {}, // key = 1,2,3,4
    },
    clubMembers: [],
    clubMembersMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
  };
};

const state = getDefaultState();

const getters = {
  clubs: (state) => {
    return state.clubs;
  },
  clubsMeta: (state) => {
    return state.clubsMeta;
  },
  clubsDashboard: (state) => {
    return state.clubsDashboard;
  },
  club: (state) => {
    return state.club;
  },
  clubMeta: (state) => {
    return state.clubMeta;
  },
  clubDescriptions: (state) => {
    return state.club.clubDescriptions ? state.club.clubDescriptions : [];
  },
  plans: (state) => {
    return state.club.plans;
  },
  clubSections: (state) => {
    return state.club.clubSections;
  },
  clubMembers: (state) => {
    return state.clubMembers;
  },
  clubFaqs: (state) => {
    return state.club.faqs;
  },
  clubReviews: (state) => {
    return state.club.reviews;
  },
  clubMeetings: (state) => {
    return state.club.meetings;
  },
};

const actions = {
  resetClub({ commit }) {
    commit(CLUBS.RESET_CLUB);
  },
  async getClubs({ commit }, payload) {
    if (payload) {
      await ApiService.getClubs(payload).then(async (res) => {
        if (res.data.success) {
          await commit(CLUBS.SET_CLUBS, res.data);
        }
      });
    } else {
      await ApiService.getClubs({
        status: state.clubsMeta.filters.status,
        page: 1,
      }).then(async (res) => {
        if (res.data.success) {
          await commit(CLUBS.SET_CLUBS, res.data);
        }
      });
    }
  },
  async getClubsDashboard({ commit }) {
    await ApiService.getClubDashboard().then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.SET_CLUBS_DASHBOARD, res.data);
      }
    });
  },
  async fetchClub({ commit }) {
    await ApiService.getClub(state.club.resourceId).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.SET_CLUB, res.data);
      }
    });
  },
  async getClub({ commit }, payload) {
    await ApiService.getClub(payload.clubResourceId).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.SET_CLUB, res.data);
      }
    });
  },
  async postClub({ commit, dispatch }, payload) {
    await ApiService.postClubs(payload.data).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.UPDATE_CLUB, res.data.data);
        await dispatch("getClubs");
      }
    });
  },
  async putClub({ commit, dispatch }, payload) {
    await ApiService.putClub(payload.clubResourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await commit(CLUBS.UPDATE_CLUB, res.data.data);
          await dispatch("getClubs");
        }
      }
    );
  },
  async changeClubStatus({ commit, dispatch }, payload) {
    let params = payload.data ? payload.data : null;

    await ApiService.changeClubStatus(
      payload.clubResourceId,
      payload.code,
      params
    ).then((res) => {
      if (res.data.success) {
        console.log(commit, dispatch);
        console.log(res.data);
      }
    });
  },
  async deleteClub({ commit, dispatch }, payload) {
    console.log(payload);
    await ApiService.deleteClub(payload.clubResourceId).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.POP_CLUB, {
          clubResourceId: payload.clubResourceId,
        });
        dispatch("getClubs");
      }
    });
  },
  async deleteClubFeaturedImage({ commit, dispatch }, payload) {
    await ApiService.deleteClubFeaturedImage(payload.clubResourceId).then(
      async (res) => {
        if (res.data.success) {
          await commit(CLUBS.UPDATE_CLUB, res.data.data);
          dispatch("fetchClub");
        }
      }
    );
  },
  async postClubSectionChapter({ commit, dispatch }, payload) {
    await ApiService.postClubSectionChapter(
      payload.clubResourceId,
      payload.clubSectionResourceId,
      payload.params
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.ADD_CLUB_SECTION_CHAPTER, res.data.data);
        dispatch("fetchClub");
      }
    });
  },
  async postClubDescription({ dispatch }, payload) {
    await ApiService.postClubDescription(
      payload.clubResourceId,
      payload.formData
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("fetchClub");
      }
    });
  },
  async putClubDescription({ commit, dispatch }, payload) {
    await ApiService.putClubDescription(
      payload.clubResourceId,
      payload.clubDescriptionResourceId,
      payload.params
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.UPDATE_CLUB_DESCRIPTION, res.data.data);
        dispatch("fetchClub");
      }
    });
  },
  async deleteClubDescription({ commit, dispatch }, payload) {
    await ApiService.deleteClubDescription(
      payload.clubResourceId,
      payload.clubDescriptionResourceId
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.POP_CLUB_DESCRIPTION, {
          resourceId: payload.clubDescriptionResourceId,
        });
        dispatch("fetchClub");
      }
    });
  },
  async updateClubDescriptionsOrder({ commit, dispatch }, payload) {
    await ApiService.putClubDescriptionOrder(
      payload.clubResourceId,
      payload.params
    ).then(async () => {
      await commit(CLUBS.SORT_CLUB_DESCRIPTION, {
        resourceIds: payload.params.resourceIds,
      });
      dispatch("fetchClub");
    });
  },
  async deleteClubDescriptionFeaturedImage({ dispatch }, payload) {
    await ApiService.deleteClubDescriptionFeaturedImage(
      payload.clubResourceId,
      payload.clubDescriptionResourceId
    ).then(async (res) => {
      if (res.data.success) {
        dispatch("fetchClub");
      }
    });
  },

  async deleteClubSectionChapter({ commit, dispatch }, payload) {
    await ApiService.deleteClubSectionChapter(
      payload.clubResourceId,
      payload.clubSectionResourceId,
      payload.clubSectionChapterResourceId
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.POP_CLUB_SECTION_CHAPTER, {
          chapterResourceId: payload.clubSectionChapterResourceId,
          sectionResourceId: payload.clubSectionResourceId,
        });
        dispatch("fetchClub");
      }
    });
  },
  async postClubsFaq({ commit, dispatch }, payload) {
    await ApiService.postClubFaq(payload).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.ADD_CLUB_FAQ, res.data.data);
        dispatch("fetchClub");
      }
    });
  },
  async putClubsFaq({ commit, dispatch }, payload) {
    await ApiService.putClubFaq(payload.resourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await commit(CLUBS.UPDATE_CLUB_FAQ, res.data.data);
          dispatch("fetchClub");
        }
      }
    );
  },
  async updateClubFaqsOrder({ commit, dispatch }, payload) {
    await ApiService.putClubFaqOrder(
      payload.clubResourceId,
      payload.params
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.SORT_CLUB_FAQ, {
          resourceIds: payload.params.faqResourceIds,
        });
        dispatch("fetchClub");
      }
    });
  },
  async updateClubSectionChapterOrder({ commit, dispatch }, payload) {
    await ApiService.putClubSectionChapterOrder(
      payload.clubResourceId,
      payload.clubSectionResourceId,
      { chapterResourceIds: payload.chapterResourceIds }
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.SORT_CLUB_SECTION_CHAPTER, {
          sectionResourceId: payload.clubSectionResourceId,
          resourceIds: payload.chapterResourceIds,
        });
        dispatch("fetchClub");
      }
    });
  },
  async deleteClubsFaq({ commit, dispatch }, payload) {
    await ApiService.deleteClubFaq(payload.resourceId).then((res) => {
      if (res.data.success) {
        commit(CLUBS.POP_CLUB_FAQ, payload.resourceId);
        dispatch("fetchClub");
      }
    });
  },

  async getClubMembers({ commit }, payload) {
    await ApiService.getClubMembers(
      payload.clubResourceId,
      payload.params
    ).then((res) => {
      if (res.data.success) {
        commit(CLUBS.SET_CLUBS_MEMBERS, res.data);
      }
    });
  },
  async updateClubMemberRole({ commit, dispatch }, payload) {
    await ApiService.putClubMemberRole(
      payload.clubResourceId,
      payload.userResourceId,
      payload.params
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.CLUB_MEMBER_ROLE_UPDATE, {
          userResourceId: payload.userResourceId,
          data: res.data.data,
        });
        dispatch("getClubMembers", { clubResourceId: payload.clubResourceId });
      }
    });
  },

  async postClubReview({ commit, dispatch }, payload) {
    await ApiService.postClubReview(payload.formData).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.ADD_CLUB_REVIEW, res.data.data);
        dispatch("fetchClub");
      }
    });
  },

  async putClubReview({ commit, dispatch }, payload) {
    await ApiService.putClubReview(
      payload.clubReviewResourceId,
      payload.formData
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.UPDATE_CLUB_REVIEW, res.data.data);
        dispatch("fetchClub");
      }
    });
  },

  async deleteClubReview({ commit, dispatch }, payload) {
    await ApiService.deleteClubReview(payload.clubReviewResourceId).then(
      async (res) => {
        if (res.data.success) {
          await commit(CLUBS.POP_CLUB_REVIEW, {
            clubReviewResourceId: payload.clubReviewResourceId,
          });
          dispatch("fetchClub");
        }
      }
    );
  },
  async updateClubReviewsOrder({ commit, dispatch }, payload) {
    await ApiService.putClubReviewOrder(payload.params).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.SORT_CLUB_REVIEW, {
          resourceIds: payload.params.resourceIds,
        });
        dispatch("fetchClub");
      }
    });
  },
  async registerFundingClub(context, payload) {
    await ApiService.postClubFunding(payload.clubResourceId).then((res) => {
      if (res.data.success) {
        console.log("success");
      }
    });
  },
};

const mutations = {
  [CLUBS.RESET_CLUB](state) {
    Object.assign(state, getDefaultState());
  },
  [CLUBS.SET_CLUBS](state, payload) {
    state.clubs = _.cloneDeep(payload.data);
    state.clubsMeta = _.cloneDeep(payload.meta);
  },
  [CLUBS.SET_CLUBS_DASHBOARD](state, payload) {
    state.clubsDashboard = _.cloneDeep(payload.data);
  },
  [CLUBS.SET_CLUB](state, payload) {
    state.club = _.cloneDeep(payload.data);
    state.clubMeta = _.cloneDeep(payload.meta);
  },
  [CLUBS.UPDATE_CLUB](state, payload) {
    state.club = { ..._.cloneDeep(state.club), ..._.cloneDeep(payload) };
  },
  [CLUBS.POP_CLUB](state, payload) {
    state.clubs = state.clubs.filter((item) => {
      return item.resourceId !== payload.clubResourceId;
    });
  },
  [CLUBS.ADD_CLUB_SECTION_CHAPTER](state, payload) {
    state.club.clubSections[0].clubSectionChapters.push(payload);
  },
  [CLUBS.UPDATE_CLUB_DESCRIPTION](state, payload) {
    const index = state.club.clubDescriptions.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.club.clubDescriptions[index] = payload;
  },
  [CLUBS.POP_CLUB_DESCRIPTION](state, payload) {
    state.club.clubDescriptions = state.club.clubDescriptions.filter((item) => {
      return item.resourceId !== payload.resourceId;
    });
  },
  [CLUBS.SORT_CLUB_DESCRIPTION](state, payload) {
    let descriptions = [];
    payload.resourceIds.forEach((descriptionResourceId) => {
      let description = state.club.clubDescriptions.find((item) => {
        return item.resourceId === descriptionResourceId;
      });
      descriptions.push(description);
    });

    state.club.clubDescriptions = descriptions;
  },
  [CLUBS.POP_CLUB_SECTION_CHAPTER](state, payload) {
    const index = state.club.clubSections.findIndex((item) => {
      return item.resourceId === payload.sectionResourceId;
    });

    state.club.clubSections[index].clubSectionChapters =
      state.club.clubSections[index].clubSectionChapters.filter((item) => {
        return item.resourceId !== payload.chapterResourceId;
      });
  },
  [CLUBS.ADD_CLUB_REVIEW](state, payload) {
    state.club.reviews.push(payload);
  },
  [CLUBS.UPDATE_CLUB_REVIEW](state, payload) {
    const index = state.club.reviews.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.club.reviews[index] = {
      ..._.cloneDeep(state.club.reviews[index]),
      ...payload,
    };
  },
  [CLUBS.POP_CLUB_REVIEW](state, payload) {
    const deletedReviewIndex = state.club.reviews.findIndex((item) => {
      return item.resourceId === payload.clubReviewResourceId;
    });
    state.club.reviews.splice(deletedReviewIndex, 1);
  },
  [CLUBS.SORT_CLUB_REVIEW](state, payload) {
    let reviews = [];
    payload.resourceIds.forEach((reviewResourceId) => {
      let review = state.club.reviews.find((item) => {
        return item.resourceId === reviewResourceId;
      });
      reviews.push(review);
    });
    state.club.reviews = reviews;
  },
  [CLUBS.ADD_CLUB_FAQ](state, payload) {
    state.club.faqs.push(payload);
  },
  [CLUBS.POP_CLUB_FAQ](state, payload) {
    const deleteFaqIndex = state.club.faqs.find((item) => {
      return item.resourceId === payload;
    });
    state.club.faqs.splice(deleteFaqIndex, 1);
  },
  [CLUBS.CLUB_MEMBER_ROLE_UPDATE](state, payload) {
    const index = state.clubMembers.findIndex((item) => {
      // 탈퇴한 회원은 user: null
      if (item.user) {
        return item.user.resourceId === payload.userResourceId;
      }
    });
    state.clubMembers[index] = {
      ..._.cloneDeep(state.clubMembers[index]),
      ...payload.data,
    };
  },
  [CLUBS.UPDATE_CLUB_FAQ](state, payload) {
    const index = state.club.faqs.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.club.faqs[index] = {
      ..._.cloneDeep(state.club.faqs[index]),
      ...payload,
    };
  },
  [CLUBS.SORT_CLUB_SECTION_CHAPTER](state, payload) {
    const sectionIndex = state.club.clubSections.findIndex((item) => {
      return item.resourceId === payload.sectionResourceId;
    });

    let chapters = [];
    payload.resourceIds.forEach((chapterResourceId, index) => {
      let chapter = state.club.clubSections[
        sectionIndex
      ].clubSectionChapters.find((item) => {
        return item.resourceId === chapterResourceId;
      });
      chapter.sortNo = index + 1;
      chapters.push(chapter);
    });

    state.club.clubSections[sectionIndex].clubSectionChapters = chapters;
  },
  [CLUBS.SORT_CLUB_FAQ](state, payload) {
    let faqs = [];
    payload.resourceIds.forEach((faqResourceId) => {
      let faq = state.club.faqs.find((item) => {
        return item.resourceId === faqResourceId;
      });
      faqs.push(faq);
    });
    state.club.faqs = faqs;
  },
  [CLUBS.SET_CLUBS_MEMBERS](state, payload) {
    state.clubMembers = _.cloneDeep(payload.data);
    state.clubMembersMeta = _.cloneDeep(payload.meta);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
