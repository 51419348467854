<template>
  <icon-base
    icon-name="user-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5 5.5C10.5 6.88071 9.38071 8 8 8C6.61929 8 5.5 6.88071 5.5 5.5C5.5 4.11929 6.61929 3 8 3C9.38071 3 10.5 4.11929 10.5 5.5ZM10.0294 8.3519C10.9196 7.71734 11.5 6.67646 11.5 5.5C11.5 3.567 9.933 2 8 2C6.067 2 4.5 3.567 4.5 5.5C4.5 6.67646 5.08044 7.71734 5.97057 8.3519C3.65557 9.18383 2 11.3986 2 14C2 14.2761 2.22386 14.5 2.5 14.5C2.77614 14.5 3 14.2761 3 14C3 11.2386 5.23858 9 8 9C10.7614 9 13 11.2386 13 14C13 14.2761 13.2239 14.5 13.5 14.5C13.7761 14.5 14 14.2761 14 14C14 11.3986 12.3444 9.18383 10.0294 8.3519Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
export default {
  name: "UserIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
