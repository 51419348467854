
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  PropType,
  reactive,
} from "vue";
import InputPrefix from "../../../../components/console/inputs/InputPrefix.vue";
import { ChannelForm } from "@/types/onboarding";

export default {
  name: "ChannelInput",
  components: { InputPrefix },
  props: {
    channel: {
      type: Object as PropType<ChannelForm>,
      required: true,
    },
    prefixChannel: {
      type: String as PropType<string>,
      required: true,
    },
    errorMassage: {
      type: String as PropType<string>,
      default: "",
    },
  },
  emits: ["updateData"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      channelAddress: "",
      icon: computed(() => {
        return `https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_${props.prefixChannel}.svg`;
      }),
    });

    onBeforeMount(() => {
      state.channelAddress = props.channel.url;
    });

    const actions = {
      updateChannelUrl: (value) => {
        state.channelAddress = value;
      },
      submitEvent: () => {
        emit("updateData", state.channelAddress);
      },
    };

    return { state, actions };
  },
};
