import { useI18n } from "vue-i18n";
import moment from "moment-timezone";
import bitly from "@/helper/bitly";
import swal from "@/helper/swal";

class helper {
  nl2br(text) {
    if (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br />");
    }
  }

  br2nl(text) {
    return text.replace(/<\s*\/?br\s*[/]?>/gi, "\n");
  }

  goToExternalUrl(url) {
    window.open(url, "_blank");
  }

  priceFormat(price, isDollar) {
    let result = null;
    result = isDollar
      ? Number(price).toFixed(2)
      : Number(price).toLocaleString();

    if (result === "NaN") {
      result = "-";
    }

    return result;
  }

  validateEmail = (email) => {
    let regEmail =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!regEmail.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  phoneNumberFormat(value) {
    let result = value
      .replace(/[^0-9]/g, "")
      .replace(/^\(?(\d{3})\)?[- ]?(\d{3}|\d{4})[- ]?(\d{4})$/g, "$1-$2-$3")
      .substr(0, 13);

    return result;
  }

  validatePhoneNumber(value) {
    let num = value.trim().toString();
    let rex = /^\(?(\d{3})\)?[- ]?(\d{3}|\d{4})[- ]?(\d{4})$/;
    return rex.test(num);
  }

  corpIdNumberFormat(value) {
    let result = value
      .replace(/[^0-9]/g, "")
      .replace(/^\(?(\d{3})\)?[- ]?(\d{2})[- ]?(\d{5})$/g, "$1-$2-$3")
      .substr(0, 12);

    return result;
  }

  validateCorpIdNumberFormat(value) {
    let num = value.trim().toString();
    let rex = /^\(?(\d{3})\)?[- ]?(\d{2})[- ]?(\d{5})$/;
    return rex.test(num);
  }

  idNumberFormat(value) {
    let result = value
      .replace(/[^0-9]/g, "")
      .replace(/^\(?(\d{6})\)?[- ]?(\d{7})$/g, "$1-$2")
      .substr(0, 14);

    return result;
  }

  validateIdNumber(value) {
    let num = value.trim().toString();
    let rex = /^\(?(\d{6})\)?[- ]?(\d{7})$/;
    return rex.test(num);
  }

  locale = () => {
    return navigator.language;
  };

  getCurrency = () => {
    return navigator.language === "ko" ? "KRW" : "USD";
  };

  getCurrencyUnit = (currency) => {
    return currency === "KRW" ? "₩" : "$";
  };

  imageChange(e, callback) {
    const reader = new FileReader();
    const files = e.target.files || e.dataTransfer.files;
    const file = files[0];

    reader.onload = () => {
      callback(file, reader);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  translations() {
    const { t } = useI18n();
    return t;
  }

  makeFormData(object, file, method = "put") {
    let formData = new FormData();

    formData.append("_method", method);
    if (file) {
      formData.append("file", file);
    }
    for (let key in object) {
      if (object[key] === null) {
        formData.append(key, "");
        continue;
      }
      if (typeof object[key] === "object" && !Array.isArray(object[key])) {
        formData.append(key, JSON.stringify(object[key]));
        continue;
      }
      if (Array.isArray(object[key])) {
        object[key].forEach((item) => {
          formData.append(`${key}[]`, item);
        });
        continue;
      }
      formData.append(key, object[key]);
    }

    return formData;
  }

  parseMd(md) {
    //ul
    md = md.replace(/^\s*\n\*/gm, "<ul>\n*");
    md = md.replace(/^(\*.+)\s*\n([^*])/gm, "$1\n</ul>\n\n$2");
    md = md.replace(/^\*(.+)/gm, "<li>$1</li>");

    //ol
    md = md.replace(/^\s*\n\d\./gm, "<ol>\n1.");
    md = md.replace(/^(\d\..+)\s*\n([^\d.])/gm, "$1\n</ol>\n\n$2");
    md = md.replace(/^\d\.(.+)/gm, "<li>$1</li>");

    //blockquote
    md = md.replace(/^>(.+)/gm, "<blockquote>$1</blockquote>");

    //h
    md = md.replace(/[#]{6}(.+)/g, "<h6>$1</h6>");
    md = md.replace(/[#]{5}(.+)/g, "<h5>$1</h5>");
    md = md.replace(/[#]{4}(.+)/g, "<h4>$1</h4>");
    md = md.replace(/[#]{3}(.+)/g, "<h3>$1</h3>");
    md = md.replace(/[#]{2}(.+)/g, "<h2>$1</h2>");
    md = md.replace(/[#]{1}(.+)/g, "<h1>$1</h1>");

    //alt h
    md = md.replace(/^(.+)\n=+/gm, "<h1>$1</h1>");
    md = md.replace(/^(.+)\n-+/gm, "<h2>$1</h2>");

    //images
    md = md.replace(/!\[([^\]]+)\]\(([^)]+)\)/g, '<img src="$2" alt="$1" />');

    //links
    md = md.replace(
      /[[]{1}([^\]]+)[\]]{1}[(]{1}([^)"]+)("(.+)")?[)]{1}/g,
      '<a href="$2" title="$4">$1</a>'
    );

    //font styles
    md = md.replace(/[*_]{2}([^*_]+)[*_]{2}/g, "<b>$1</b>");
    md = md.replace(/[*_]{1}([^*_]+)[*_]{1}/g, "<i>$1</i>");
    md = md.replace(/[~]{2}([^~]+)[~]{2}/g, "<del>$1</del>");

    //pre
    md = md.replace(/^\s*\n```(([^\s]+))?/gm, '<pre class="$2">');
    md = md.replace(/^```\s*\n/gm, "</pre>\n\n");

    //code
    md = md.replace(/[`]{1}([^`]+)[`]{1}/g, "<code>$1</code>");

    //p
    md = md.replace(/^\s*(\n)?(.+)/gm, function (m) {
      return /<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m)
        ? m
        : "<p>" + m + "</p>";
    });

    //strip p from pre
    md = md.replace(/(<pre.+>)\s*\n<p>(.+)<\/p>/gm, "$1$2");

    return md;
  }

  textByteCalc(text) {
    let totalByte = 0;
    for (let i = 0; i < text.length; i++) {
      const each_char = text.charAt(i);
      const uni_char = escape(each_char); //유니코드 형식으로 변환
      if (uni_char.length > 4) {
        totalByte += 2; // 한글 : 2Byte
      } else {
        totalByte += 1; // 영문,숫자,특수문자 : 1Byte
      }
    }
    return totalByte;
  }

  displayUserName(user) {
    if (user) {
      if (user.userProfile?.displayName) {
        return user.userProfile.displayName;
      } else {
        return user.name;
      }
    } else {
      return "이름 없음";
    }
  }

  findFirstDiffPos(a, b) {
    if (a.length < b.length) [a, b] = [b, a];
    return [...a].findIndex((chr, i) => chr !== b[i]);
  }

  getSpaceWebUrl(user) {
    if (user.aliasName) {
      return `${process.env.VUE_APP_WEB_URL}/${user.aliasName}`;
    } else {
      return `${process.env.VUE_APP_WEB_URL}/${user.resourceId}`;
    }
  }

  getClubWebUrl(user, club) {
    if (user.aliasName) {
      return `${process.env.VUE_APP_MOMENT_URL}/${user.aliasName}/clubs/${club.resourceId}`;
    } else {
      return `${process.env.VUE_APP_MOMENT_URL}/${user.resourceId}/clubs/${club.resourceId}`;
    }
  }

  getEnterClubWebUrl(user, club) {
    if (user.aliasName) {
      return `${process.env.VUE_APP_ENTER_URL}/${user.aliasName}/clubs/${club.resourceId}`;
    } else {
      return `${process.env.VUE_APP_ENTER_URL}/${user.resourceId}/clubs/${club.resourceId}`;
    }
  }

  async clubShareUrl(user, club) {
    let userAlias = user.aliasName ? user.aliasName : user.resourceId;
    let targetUrl = encodeURIComponent(
      `${process.env.VUE_APP_WEB_URL}/${userAlias}/club/${club.resourceId}/`
    );
    let targetUrlPath = encodeURIComponent(
      `/${userAlias}/club/${club.resourceId}/`
    );
    let appSchemeProtocol = encodeURIComponent("bigc://");

    // TODO ANDREW utm_source, pid 는 임시!! 추후 정해지면 작업 예정.
    let uri = `https://link.bigc.im/TCHy?utm_source=studiotest&af_web_dp=${targetUrl}&af_dp=${appSchemeProtocol}&pid=bigc_studio_source&deep_link_value=${targetUrlPath}`;

    let shortenUrl = await bitly.shortenUrl(uri);

    return shortenUrl;
  }

  getLinkInBioUrl(user) {
    if (user.aliasName) {
      return `${process.env.VUE_APP_WEB_URL}/${user.aliasName}`;
    } else {
      return `${process.env.VUE_APP_WEB_URL}/${user.resourceId}`;
    }
  }

  copyText(text) {
    let dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  async copyToClipboard(text) {
    try {
      if (!navigator.clipboard) {
        throw new Error("Clipboard API is not available");
      }

      await navigator.clipboard.writeText(text);
      swal.successToast("클립보드에 복사되었습니다.");
    } catch (err) {
      swal.errorToast("복사에 실패했습니다.");
      console.error("Sorry, Fail to copy to clipboard", err);
    }
  }

  isImageFile(extension) {
    return (
      extension === "webp" ||
      extension === "png" ||
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "gif"
    );
  }

  //  user의 timezone에 따라 값 설정됨.
  dateTimeFormat(date, line = 1) {
    if (line === 1) {
      return moment(date).tz(moment.tz.guess()).format("YYYY.MM.DD HH:mm z");
    } else {
      return moment(date)
        .tz(moment.tz.guess())
        .format("YYYY.MM.DD <br/>HH:mm z");
    }
  }

  deviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (/https:\/\/c6c8j7x5.rocketcdn.me\/Android|iPhone/i.test(ua)) {
      return "mobile";
    }
    return "desktop";
  }

  isMobile() {
    let deviceType = this.deviceType();
    return deviceType === "mobile";
  }

  isMobileSize() {
    return window.innerWidth < 768;
  }

  isTabletSize() {
    return window.innerWidth < 1024;
  }

  isValidURL(url) {
    let regex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return regex.test(url);
  }

  groupByArray(arr, key) {
    return arr.reduce(function (carry, el) {
      let group = el[key];

      if (carry[group] === undefined) {
        carry[group] = [];
      }

      carry[group].push(el);
      return carry;
    }, {});
  }
}

export default new helper();
// export function priceFormat(price) {
//   return Number(price).toLocaleString();
// }
