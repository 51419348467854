import ApiService from "@/api/index.js";
import {
  Lecture,
  LectureCategory,
  LectureForm,
  LectureQuestion,
  LecturesReservation,
  LectureReview,
  LectureSchedule,
  UpdateLectureForm,
  LectureReservationMeta,
  LectureCommunity,
  LectureTag,
  LectureScheduleQna,
  LectureReservationFeedback,
  LectureUpcoming,
  LectureTopics,
  LectureCategories,
} from "@/types/lectures";
import { LECTURES } from "../mutation-types.js";
import { cloneDeep } from "lodash";
import { ArticleItem, ArticleMeta } from "@/types/articles";

const getDefaultState = (): any => {
  return {
    lectures: [] as Lecture[],
    getLecture: {} as Lecture,
    lectureSchedules: [] as LectureSchedule[],
    lectureCategories: {} as LectureCategories,
    lectureTopics: [] as LectureTopics[],
    lectureQuestions: [] as LectureQuestion[],
    lectureReviews: [] as LectureReview[],
    lecturesReservations: [] as LecturesReservation[],
    lecturesReservationsMeta: {} as LectureReservationMeta,
    lecturesCommunities: [] as LectureCommunity[],
    lectureTags: [] as LectureTag[],
    lectureScheduleQna: [] as LectureScheduleQna[],
    lectureReservationsCount: {
      CANCELED: 0,
      COMPLETED: 0,
      FINISHED: 0,
      TOTAL: 0,
    },
    lectureReservationFeedback: [] as LectureReservationFeedback[],
    lectureArticles: [] as ArticleItem[],
    lectureArticlesMeta: {} as ArticleMeta,
    upcomingLecture: {} as LectureUpcoming,
  };
};

const state = getDefaultState();

const getters = {
  getLectures: (state): Lecture[] => {
    return state.lectures;
  },
  getLecture: (state): Lecture => {
    return state.getLecture;
  },
  lectureSchedules: (state): LectureSchedule[] => {
    return state.lectureSchedules;
  },
  lectureCategories: (state): LectureCategories => {
    return state.lectureCategories;
  },
  lectureTopics: (state): LectureTopics[] => {
    return state.lectureTopics;
  },
  lectureQuestions: (state) => {
    return state.lectureQuestions;
  },
  lectureReviews: (state) => {
    return state.lectureReviews;
  },
  lecturesReservations: (state) => {
    return state.lecturesReservations;
  },
  lectureReservationsMeta: (state) => {
    return state.lecturesReservationsMeta;
  },
  lectureCommunities: (state) => {
    return state.lecturesCommunities;
  },
  lectureTags: (state) => {
    return state.lectureTags;
  },
  lectureScheduleQna: (state) => {
    return state.lectureScheduleQna;
  },
  lectureReservationCount: (state) => {
    return state.lectureReservationsCount;
  },
  lectureReservationFeedback: (state) => {
    return state.lectureReservationFeedback;
  },
  lectureArticles: (state) => {
    return state.lectureArticles;
  },
  upcomingLecture: (state) => {
    return state.upcomingLecture;
  },
};

const actions = {
  resetLectures() {
    getDefaultState();
  },
  async getLectures({ commit }): Promise<void> {
    await ApiService.getLectures().then(async (res) => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURES, res.data.data);
      }
    });
  },
  async getLecture({ commit }, payload): Promise<void> {
    await ApiService.getLecture(payload.resourceId).then(async (res) => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURE, res.data.data);
      }
    });
  },
  async postLectures({ dispatch }, payload: LectureForm): Promise<void> {
    await ApiService.postLectures(payload).then(async (res) => {
      if (res.data.success) {
        const payload = res.data.data;
        await dispatch("getLecture", { resourceId: payload.resourceId });
      }
    });
  },
  async putLectures({ dispatch }, payload: UpdateLectureForm): Promise<void> {
    await ApiService.putLectures(payload.resourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getLecture", { resourceId: payload.resourceId });
        }
      }
    );
  },
  async postLecturePrice({ dispatch }, payload): Promise<void> {
    await ApiService.postLecturePrice(
      payload.lectureResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getLecture", { resourceId: payload.lectureResourceId });
      }
    });
  },
  async putLecturePrice({ dispatch }, payload): Promise<void> {
    await ApiService.putLecturePrice(
      payload.lectureResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getLecture", { resourceId: payload.lectureResourceId });
      }
    });
  },
  async getLectureSchedules({ commit }, payload): Promise<void> {
    await ApiService.getLectureSchedules(payload.resourceId, payload.data).then(
      (res) => {
        if (res.data.success) {
          commit(LECTURES.SET_LECTURE_SCHEDULES, res.data.data);
        }
      }
    );
  },
  async postLectureSchedule({ commit }, payload): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiService.postLectureSchedule(payload.resourceId, payload.data).then(
        (res) => {
          resolve(res.data.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async deleteLectureSchedule({ commit, dispatch }, payload): Promise<void> {
    await ApiService.deleteLectureSchedule(
      payload.lectureResourceId,
      payload.lectureScheduleResourceId
    ).then(async (res) => {
      if (res.data.success) {
        console.log(res.data.data);
      }
    });
  },
  async getLectureCategories({ commit }): Promise<void> {
    await ApiService.getLectureCategories().then((res) => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURE_CATEGORIES, res.data.data);
      }
    });
  },
  async getLectureTopics({ commit }): Promise<void> {
    await ApiService.getLectureTopics().then((res): void => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURE_TOPICS, res.data.data);
      }
    });
  },
  async getLectureQuestions({ commit }, payload): Promise<void> {
    await ApiService.getLectureQuestions(payload.lectureResourceId).then(
      (res) => {
        if (res.data.success) {
          commit(LECTURES.SET_LECTURE_QUESTIONS, res.data.data);
        }
      }
    );
  },
  async postLectureQuestion({ commit, dispatch }, payload): Promise<void> {
    await ApiService.postLectureQuestion(
      payload.lectureResourceId,
      payload.data
    ).then((res) => {
      if (res.data.success) {
        commit(LECTURES.SET_UPCOMING_LECTURE, res.data.data);
      }
    });
  },
  async deleteLectureQuestion({ commit, dispatch }, payload): Promise<void> {
    await ApiService.deleteLectureQuestion(
      payload.lectureResourceId,
      payload.questionResourceId
    ).then((res) => {
      if (res.data.success) {
        dispatch("getLectureQuestions", {
          lectureResourceId: payload.lectureResourceId,
        });
      }
    });
  },
  async cancelReservedSchedule({ commit, dispatch }, payload): Promise<void> {
    await ApiService.postCancelReservedSchedule(
      payload.lectureResourceId,
      payload.reservationResourceId,
      payload.data
    ).then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
      }
    });
  },
  async getLectureReviews({ commit, dispatch }, payload): Promise<void> {
    await ApiService.getLectureReviews(payload.lectureResourceId).then(
      (res) => {
        if (res.data.success) {
          commit(LECTURES.SET_LECTURE_REVIEWS, res.data.data);
        }
      }
    );
  },
  async postLectureReview({ commit, dispatch }, payload): Promise<void> {
    await ApiService.postLectureReview(
      payload.lectureResourceId,
      payload.data
    ).then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
      }
    });
  },
  async putLectureReview({ commit, dispatch }, payload): Promise<void> {
    await ApiService.putLectureReview(
      payload.lectureResourceId,
      payload.lectureReviewResourceId,
      payload.data
    ).then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
      }
    });
  },
  async getLectureReservations({ commit, dispatch }, payload): Promise<void> {
    await ApiService.getLectureReservations(
      payload.lectureResourceId,
      payload.data
    ).then((res) => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURES_RESERVATIONS, res.data);
      }
    });
  },
  async getLectureCommunities({ commit, dispatch }, payload): Promise<void> {
    await ApiService.getLectureCommunities(payload.lectureResourceId).then(
      (res) => {
        if (res.data.success) {
          commit(LECTURES.SET_LECTURES_COMMUNITIES, res.data.data);
        }
      }
    );
  },
  async getLectureTags({ commit }): Promise<void> {
    await ApiService.getLectureTags().then((res) => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURES_TAGS, res.data.data);
      }
    });
  },
  async getLectureScheduleQna({ commit }, payload): Promise<void> {
    await ApiService.getLectureScheduleQna(
      payload.lectureResourceId,
      payload.reservationResourceId
    ).then((res) => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURE_SCHEDULE_QNA, res.data.data);
      }
    });
  },
  async postLectureScheduleFeedback({ dispatch }, payload): Promise<void> {
    await ApiService.postLectureScheduleFeedback(
      payload.lectureResourceId,
      payload.reservationResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getLectureScheduleFeedback", {
          lectureResourceId: payload.lectureResourceId,
          reservationResourceId: payload.reservationResourceId,
        });
      }
    });
  },
  async getLectureScheduleFeedback({ commit }, payload): Promise<void> {
    await ApiService.getLectureScheduleFeedback(
      payload.lectureResourceId,
      payload.reservationResourceId
    ).then((res) => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURE_SCHEDULE_FEEDBACK, res.data.data);
      }
    });
  },
  async getReservationCounts({ commit }, payload): Promise<void> {
    await ApiService.getReservationCounts(payload.lectureResourceId).then(
      (res) => {
        if (res.data.success) {
          commit(LECTURES.SET_LECTURES_RESERVATION_COUNTS, res.data.data);
        }
      }
    );
  },
  async getLectureArticles({ commit }, payload): Promise<void> {
    await ApiService.getArticles(payload).then((res) => {
      if (res.data.success) {
        commit(LECTURES.SET_LECTURE_ARTICLES, res.data);
      }
    });
  },
  async deleteLectureArticle({ commit }, payload): Promise<void> {
    await ApiService.deleteArticles(payload.articleResourceId).then((res) => {
      if (res.data.success) {
        console.log(res.data);
      }
    });
  },
  getLectureArticleCommunityComment({ commit }, payload) {
    ApiService.getComments(payload).then((res) => {
      if (res.data.success) {
        console.log(res);
      }
    });
  },
  async postLectureArticleCommunityComment({ commit }, payload): Promise<void> {
    await ApiService.postComments(payload);
    // todo 댓글 로직 개선 - 데이터 교체가 한번에 안되는 원인 찾기
    // await ApiService.postComments(payload).then((res) => {
    //   const payLoad = {
    //     data: res.data.data,
    //     articleResourceId: payload.resourceId,
    //   };
    //   commit(LECTURES.ADD_LECTURE_ARTICLE_COMMUNITY_COMMENTS, payLoad);
    // });
  },
  async deleteLectureArticleCommunityComment(
    { commit },
    payload
  ): Promise<void> {
    await ApiService.deleteComments(payload.commentResourceId);
    // todo 댓글 crud 로직 개선하기
    // ApiService.deleteComments(payload.commentResourceId).then((res) => {
    //   if (res.data.success) {
    //     console.log(res.data);
    //   }
    // });
  },
  async putLectureArticleCommunityComment({ commit }, payload): Promise<void> {
    await ApiService.putComment(payload.commentResourceId, {
      content: payload.content,
    }).then((res) => {
      if (res.data.success) {
        commit(
          LECTURES.UPDATE_LECTURE_ARTICLE_COMMUNITY_COMMENTS,
          res.data.data
        );
      }
    });
  },
  async getUpcomingLecture({ commit }, payload): Promise<void> {
    await ApiService.getUpcomingLecture(payload.lectureResourceId).then(
      (res) => {
        if (res.data.success) {
          commit(LECTURES.SET_UPCOMING_LECTURE, res.data.data);
        }
      }
    );
  },
  //todo 수정
  resetUpcomingLecture() {
    state.upcomingLecture = {};
  },
};

const mutations = {
  [LECTURES.SET_LECTURES](state, payload: Lecture[]) {
    state.lectures = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURE](state, payload: Lecture) {
    state.getLecture = { ...state.getLecture, ...payload };
  },
  [LECTURES.UPDATE_LECTURE](state, payload: Lecture) {
    if (state.getLecture.resourceId) {
      state.getLecture = { ...state.getLecture, ...payload };
    }

    const lecturesIndex: number = state.lectures.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });

    state.lectures[lecturesIndex] = {
      ...state.lectures[lecturesIndex],
      ...payload,
    };
  },
  [LECTURES.SET_LECTURE_SCHEDULES](state, payload: LectureSchedule[]) {
    state.lectureSchedules = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURE_CATEGORIES](state, payload: LectureCategories) {
    state.lectureCategories = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURE_TOPICS](state, payLoad: LectureTopics[]): void {
    state.lectureTopics = cloneDeep(payLoad);
  },
  [LECTURES.SET_LECTURE_QUESTIONS](state, payload) {
    state.lectureQuestions = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURE_REVIEWS](state, payload) {
    state.lectureReviews = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURES_RESERVATIONS](state, payload) {
    state.lecturesReservations = cloneDeep(payload.data);
    state.lecturesReservationsMeta = cloneDeep(payload.meta);
  },
  [LECTURES.SET_LECTURES_COMMUNITIES](state, payload) {
    state.lecturesCommunities = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURES_TAGS](state, payload) {
    state.lectureTags = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURE_SCHEDULE_QNA](state, payload) {
    state.lectureScheduleQna = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURES_RESERVATION_COUNTS](state, payload) {
    state.lectureReservationsCount = {
      ...state.lectureReservationsCount,
      ...payload,
    };
  },
  [LECTURES.SET_LECTURE_SCHEDULE_FEEDBACK](state, payload) {
    state.lectureReservationFeedback = cloneDeep(payload);
  },
  [LECTURES.SET_LECTURE_ARTICLES](state, payload) {
    state.lectureArticles = cloneDeep(payload.data);
    state.lectureArticlesMeta = cloneDeep(payload.meta);
  },
  [LECTURES.SET_UPCOMING_LECTURE](state, payload) {
    state.upcomingLecture = cloneDeep(payload[0]);
  },
  [LECTURES.ADD_LECTURE_ARTICLE_COMMUNITY_COMMENTS](state, payload) {
    const index = state.lectureArticles.findIndex((item) => {
      return item.resourceId === payload.articleResourceId;
    });
    state.lectureArticles[index].comments.unshift(payload.data);
  },
  [LECTURES.UPDATE_LECTURE_ARTICLE_COMMUNITY_COMMENTS](state, payload) {
    const index = state.lectureArticles[0].comments.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.lectureArticles[0].comments[index] = {
      ...state.lectureArticles[0].comments[index],
      ...payload,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
