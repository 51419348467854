import Cookies from "js-cookie";
import ApiService from "../../api";
import { AUTH } from "../mutation-types";
import _ from "lodash";

const tokenCookieDomain = process.env.VUE_APP_TOKEN_COOKIE_DOMAIN;
const getDefaultState = () => {
  return {
    user: {
      aliasName: null,
      avatar: "",
      channels: [],
      children: [],
      createdAt: "",
      email: "",
      id: 0,
      initial: "",
      isAdmin: false,
      isCeleb: false,
      isCreator: false,
      isEmail: false,
      isSms: false,
      isStaff: false,
      lang: "",
      lecture: null,
      name: "",
      phone: "",
      resourceId: "",
      type: 11,
      updatedAt: "",
      userProfile: {
        coverImage: "",
        coverImageUrl: "",
        defaultCoin: "",
        description: "",
        descriptionHtml: "",
        displayName: "",
        headline: "",
        mCoverImage: "",
        mCoverImageUrl: "",
        logoImage: "",
        logoImageUrl: "",
        mLogoImage: "",
        mLogoImageUrl: "",
        onBoardingStep: "",
      },
    },
    token: Cookies.get("_bigc_token"),
    isRemember: false,
    openWelcomeModal: sessionStorage.getItem("openWelcomeModal"),
  };
};

const state = getDefaultState();

const getters = {
  user: (state) => {
    return state.user;
  },
  aliasName: (state) => {
    return state.user.aliasName ? state.user.aliasName : state.user.resourceId;
  },
  token: (state) => {
    return state.token;
  },
  check: (state) => {
    return state.user.resourceId !== "";
  },
  userName: (state) => {
    if (state.user.userProfile.displayName) {
      return state.user.userProfile.displayName;
    } else {
      return state.user.name ? state.user.name : "나";
    }
  },
  isRemember: (state) => {
    return state.isRemember;
  },
  openWelcomeModal: (state) => {
    return state.openWelcomeModal;
  },
  isStarter: (state) => {
    return state.user.type === 11;
  },
  isPrePro: (state) => {
    return state.user.type === 21;
  },
  isPro: (state) => {
    return state.user.type === 22;
  },
  isEnterprise: (state) => {
    return state.user.type === 31;
  },
  isStaff: (state) => {
    return state.user.isStaff;
  },
};

const actions = {
  async login(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postAuthLogin(payload).then(
        (res) => {
          if (res.data.success) {
            const accessToken = res.data?.data?.oauth.accessToken;
            context.commit(AUTH.SAVE_TOKEN, {
              token: accessToken,
              remember: payload.isRemember,
            });
          } else {
            context.commit(AUTH.FETCH_USER_FAILURE);
          }
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async saveToken({ commit }, payload) {
    await commit(AUTH.SAVE_TOKEN, payload);
  },
  async logout({ commit, dispatch }) {
    await commit(AUTH.LOGOUT);
    await dispatch("articles/resetArticles", {}, { root: true });
    await dispatch("channels/resetChannels", {}, { root: true });
    await dispatch("clubs/resetClub", {}, { root: true });
    await dispatch("newClubs/resetClub", {}, { root: true });
    await dispatch("comments/resetComments", {}, { root: true });
    await dispatch("communities/resetCommunities", {}, { root: true });
    await dispatch("connects/resetConnects", {}, { root: true });
    await dispatch("contents/resetContents", {}, { root: true });
    await dispatch("dashboard/resetDashboard", {}, { root: true });
    await dispatch("locale/resetLocale", {}, { root: true });
    await dispatch("meetings/resetMeetings", {}, { root: true });
    await dispatch("notifications/resetNotifications", {}, { root: true });
    await dispatch("plans/resetPlans", {}, { root: true });
    await dispatch("settlements/resetSettlements", {}, { root: true });
    await dispatch("lectures/resetLectures", {}, { root: true });
    await dispatch("chats/resetChats", {}, { root: true });
    await dispatch("polls/resetPolls", {}, { root: true });
  },
  async changeUser({ dispatch }, payload) {
    await ApiService.postAuthBridge(payload).then(async (res) => {
      if (res.data.success) {
        await dispatch("saveToken", {
          token: res.data.data.token,
          remember: true,
        });
        await dispatch("fetchUser");
      }
    });
  },
  async fetchUser({ commit }) {
    try {
      const { data } = await ApiService.getUser();
      const user = data.data;

      commit(AUTH.FETCH_USER_SUCCESS, { user: user });
    } catch (e) {
      commit(AUTH.FETCH_USER_FAILURE);
    }
  },
  async putUser({ commit }, payload) {
    await ApiService.putUser(payload.data).then(async (res) => {
      if (res.data.success) {
        await commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async postUserChangePassword(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postUserChangePassword(payload).then(
        (res) => {
          if (res.data.success) {
            resolve(res);
          } else {
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async putUserProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.putUserProfile(payload).then(
        async (res) => {
          if (res.data.success) {
            await commit(AUTH.UPDATE_USER, res.data.data);
            resolve(res);
          } else {
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async postAuthPhoneVerificationCode({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postAuthPhoneVerificationCode(
        payload.phoneResourceId,
        payload.data
      )
        .then(async (res) => {
          if (res.data.success) {
            resolve(res);
          } else {
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  async uploadUserAvatar({ commit }, payload) {
    await ApiService.uploadUserProfileAvatar(payload.data).then((res) => {
      if (res.data.success) {
        commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async uploadUserProfileCoverImage({ commit }, payload) {
    await ApiService.uploadUserProfileCover(payload.data).then((res) => {
      if (res.data.success) {
        commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async uploadUserProfileMobileCoverImage({ commit }, payload) {
    await ApiService.uploadUserMobileProfileCover(payload.data).then((res) => {
      if (res.data.success) {
        commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async updateUserProfileLogoImage({ commit }, payload) {
    await ApiService.uploadUserProfileLogo(payload.data).then((res) => {
      if (res.data.success) {
        commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async updateUserProfileMobileLogoImage({ commit }, payload) {
    await ApiService.uploadUserMobileProfileLogo(payload.data).then((res) => {
      if (res.data.success) {
        commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },

  async deleteUserProfileCoverImage({ commit }) {
    await ApiService.deleteUserProfileCover().then(async (res) => {
      if (res.data.success) {
        await commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async deleteUserProfileMobileCoverImage({ commit }) {
    await ApiService.deleteUserMobileProfileCover().then(async (res) => {
      if (res.data.success) {
        await commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async deleteUserProfileLogoImage({ commit }) {
    await ApiService.deleteUserProfileLogo().then(async (res) => {
      if (res.data.success) {
        await commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async deleteUserProfileMobileLogoImage({ commit }) {
    await ApiService.deleteUserMobileProfileLogo().then(async (res) => {
      if (res.data.success) {
        await commit(AUTH.UPDATE_USER, res.data.data);
      }
    });
  },
  async setWelcomeModalSession({ commit }) {
    sessionStorage.setItem("openWelcomeModal", true);
    commit(AUTH.SET_OPEN_WELCOME_MODAL_SESSION);
  },
  async deleteWelcomeModalSession({ commit }) {
    sessionStorage.removeItem("openWelcomeModal");
    commit(AUTH.SET_OPEN_WELCOME_MODAL_SESSION);
  },
};

const mutations = {
  async [AUTH.SAVE_TOKEN](state, { token, remember }) {
    state.token = token;
    state.isRemember = remember;
    await Cookies.set("_bigc_token", state.token, {
      expires: state.isRemember ? 14 : 1,
      domain: tokenCookieDomain,
    });
    await Cookies.set("token", state.token, {
      expires: state.isRemember ? 14 : 1,
    });
  },
  [AUTH.LOGOUT](state) {
    // todo 통합로그인 교체로 기존 로그인 코드 보류
    // Cookies.remove("_bigc_token", { domain: tokenCookieDomain });
    // Cookies.remove("token");
    Object.assign(state, getDefaultState());
  },
  [AUTH.FETCH_USER_SUCCESS](state, { user }) {
    state.user = _.cloneDeep(user);
  },
  [AUTH.FETCH_USER_FAILURE](state) {
    state.token = null;
    Cookies.remove("_bigc_token", { domain: tokenCookieDomain });
    Cookies.remove("token");
  },
  [AUTH.UPDATE_USER](state, payload) {
    state.user = { ..._.cloneDeep(state.user), ..._.cloneDeep(payload) };
  },
  [AUTH.SET_OPEN_WELCOME_MODAL_SESSION](state) {
    state.openWelcomeModal = sessionStorage.getItem("openWelcomeModal");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
