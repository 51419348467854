import ApiService from "@/api/index.js";
import { Poll, PollOption } from "@/types/polls";
import { POLLS } from "@/store/mutation-types";
import { Pagination } from "@/types/common";
import { cloneDeep } from "lodash";

const getDefaultState = (): any => {
  return {
    polls: [] as Poll[],
    poll: {} as Poll,
    pollOptions: [] as PollOption[],
    pollsMeta: {} as Pagination,
  };
};

const state = getDefaultState();

const getters = {
  getPolls: (state): Poll[] => {
    return state.polls;
  },
  getPoll: (state): Poll => {
    return state.poll;
  },
  getPollOptions(state) {
    return state.pollOptions;
  },
  getPollsMeta(state) {
    return state.pollsMeta;
  },
};

const actions = {
  resetPolls(): void {
    getDefaultState();
  },
  resetPollsState({ commit }): void {
    commit("resetState");
  },
  async getPolls({ commit }, payload): Promise<void> {
    await ApiService.getPolls(payload).then(async (response) => {
      const result = response.data;
      if (result.success) {
        await commit(POLLS.SET_POLLS, result.data);
        await commit(POLLS.SET_POLLS_META, result.meta);
      }
    });
  },
  async getPoll({ commit }, payload): Promise<void> {
    await ApiService.getPoll(payload.resourceId).then(async (response) => {
      const result = response.data;
      if (result.success) {
        await commit(POLLS.SET_POLL, result.data);
      }
    });
  },
  async postPoll({ dispatch }, payload): Promise<void> {
    await ApiService.postPoll(payload.data).then(async (response) => {
      const result = response.data;
      if (result.success) {
        await dispatch("getPolls", payload.filters);
      }
    });
  },
  async putPoll({ commit }, payload): Promise<void> {
    await ApiService.putPoll(payload.pollResourceId, payload.data).then(
      async (response) => {
        const result = response.data;
        if (result.success) {
          await commit(POLLS.SET_POLL, result.data);
        }
      }
    );
  },
  async deletePoll({ dispatch }, payload): Promise<{ error: any }> {
    try {
      const res = await ApiService.deletePoll(payload.pollResourceId);
      return res;
    } catch (e: any) {
      return { error: e };
    }
  },
  async postPollFeaturedImage({ dispatch }, payload) {
    return await ApiService.postPollFeaturedImage(
      payload.pollResourceId,
      payload.data
    );
  },
  async getPollOptions({ commit }, payload): Promise<void> {
    await ApiService.getPollOptions(payload.pollResourceId).then(
      async (response) => {
        const result = response.data;
        if (result.success) {
          await commit("SET_POLL_OPTIONS", result.data);
        }
      }
    );
  },
  async getPollOption({ commit }, payload): Promise<void> {
    await ApiService.getPollOption(
      payload.pollResourceId,
      payload.pollOptionResourceId
    ).then(async (response) => {
      const result = response.data;
      if (result.success) {
        await commit("SET_POLL_OPTION", result.data);
      }
    });
  },
  async postPollOptions({ dispatch, commit }, payload): Promise<void> {
    await ApiService.postPollOptions(payload.pollResourceId, payload.data).then(
      async (response) => {
        const result = response.data;
        if (result.success) {
          await dispatch("getPollOptions", {
            pollResourceId: payload.pollResourceId,
          });
        }
      }
    );
  },
  async putPollOptions({ dispatch }, payload) {
    await ApiService.putPollOptions(
      payload.pollResourceId,
      payload.pollOptionResourceId,
      payload.data
    ).then(async (response) => {
      // {{api-console}}/polls/:pollId/polloptions/:polloptionId
      await dispatch("getPollOption", payload);
      // await dispatch("getPollOptions", payload.pollResourceId);
    });
  },
  async deletePollOptions({ commit }, payload) {
    await ApiService.deletePollOptions(
      payload.pollResourceId,
      payload.pollOptionResourceId
    ).then(async (response) => {
      const status = response.status;
      if (status === 200) {
        await commit("DELETE_POLL_OPTIONS", payload);
      }
    });
  },
  async postPollOptionsResort({ dispatch }, payload) {
    await ApiService.postPollOptionsResort(
      payload.pollResourceId,
      payload.data
    ).then(async (response) => {
      // todo 리스폰스가 아예 안옴? 의도된건가? response.data가 null임
      if (response.status === 200) {
        await dispatch("getPollOptions", {
          pollResourceId: payload.pollResourceId,
        });
      }
    });
  },

  async testFeatureImage({ commit }, payload) {
    return await ApiService.testFeatureImage(payload.resourceId, payload.data);
  },

  async postPollOptionsFeatureImage({ commit }, payload) {
    return await ApiService.postPollOptionsFeatureImage(
      payload.pollResourceId,
      payload.pollOptionResourceId,
      payload.data
    );
  },
};

const mutations = {
  resetState(state: any) {
    Object.assign(state, getDefaultState());
  },
  resetPoll(state: any) {
    state.poll = {} as Poll;
    state.pollOptions = [] as PollOption[];
  },
  [POLLS.SET_POLLS](state: any, data: any) {
    state.polls = cloneDeep(data);
  },
  [POLLS.ADD_POLL](state: any, data: any) {
    state.polls = cloneDeep(data);
  },
  [POLLS.SET_POLL](state: any, data: any) {
    const poll = cloneDeep(data);

    const localizations = {
      ko: { title: "", description: "", text: "", featuredImage: "" },
      en: { title: "", description: "", text: "", featuredImage: "" },
      ja: { title: "", description: "", text: "", featuredImage: "" },
      zh_cn: { title: "", description: "", text: "", featuredImage: "" },
      zh_tw: { title: "", description: "", text: "", featuredImage: "" },
    };

    const keysArr = Object.keys(poll.localizations);
    if (keysArr.length < 5) {
      const keysArrLocals = Object.keys(localizations);
      keysArrLocals.forEach((item) => {
        !keysArr.includes(item) &&
          (poll.localizations[item] = localizations[item]);
      });
    }

    state.poll = poll;
    state.pollOptions = poll.pollOptions;
  },
  [POLLS.SET_POLLS_META](state: any, data: any) {
    state.pollsMeta = cloneDeep(data);
  },
  [POLLS.SET_POLL_OPTIONS](state: any, data: any) {
    state.pollOptions = data;
  },
  [POLLS.SET_POLL_OPTION](state: any, data: any) {
    const pollOptionIndex = state.pollOptions.findIndex((item) => {
      return item.resourceId === data.resourceId;
    });
    state.pollOptions[pollOptionIndex] = data;
  },
  [POLLS.DELETE_POLL_OPTIONS](state: any, data: any) {
    const pollOptionIndex = state.pollOptions.findIndex((item) => {
      return item.resourceId === data.pollOptionResourceId;
    });
    state.pollOptions.splice(pollOptionIndex, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
