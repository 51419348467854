import axios from "axios";
import store from "../store";

const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}/api/v1/app`;

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const ApiApp = axios.create({
  baseURL,
});

// Request interceptor
ApiApp.interceptors.request.use((request) => {
  const token = store.getters["auth/token"];
  if (token) {
    request.headers.Authorization =  `Bearer ${token}`;
  }
  return request;
});

export default ApiApp;
