import ApiService from "@/api";
import { COMMUNITIES } from "../mutation-types";

const getDefaultState = () => {
  return {
    communities: [],
    community: {
      articleCount: 0,
      clubId: null,
      isConnected: false,
      isNotice: false,
      isShow: false,
      isDelete: false,
      resourceId: "",
      sortNo: 0,
      title: "",
      type: 0,
    },
  };
};

const state = getDefaultState();

const getters = {
  communities: (state) => {
    return state.communities;
  },
  community: (state) => {
    return state.community;
  },
};

const actions = {
  resetCommunities({ commit }) {
    commit(COMMUNITIES.RESET_COMMUNITIES);
  },
  async getCommunities({ commit }, payload) {
    await ApiService.getCommunities(payload).then((res) => {
      if (res.data.success) {
        commit(COMMUNITIES.SET_COMMUNITIES, res.data.data);
      }
    });
  },
  async setCommunity({ commit }, payload) {
    if (payload) {
      await commit(COMMUNITIES.SET_COMMUNITY, payload);
    } else {
      await commit(COMMUNITIES.RESET_COMMUNITY);
    }
  },
  async updateCommunitiesOrder({ dispatch }, payload) {
    await ApiService.sortCommunities(payload).then(async (res) => {
      if (res.data.success) {
        await dispatch("getCommunities");
      }
    });
  },
  async postCommunity({ dispatch }, payload) {
    await ApiService.postCommunity(payload).then(async (res) => {
      if (res.data.success) {
        await dispatch("getCommunities");
      }
    });
  },
  async putCommunity({ dispatch }, payload) {
    await ApiService.putCommunity(
      payload.communityResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getCommunities");
      }
    });
  },
  async deleteCommunity({ dispatch }, payload) {
    await ApiService.deleteCommunity(payload.communityResourceId).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getCommunities");
        }
      }
    );
  },
};

const mutations = {
  [COMMUNITIES.SET_COMMUNITIES](state, payload) {
    state.communities = payload;
  },
  [COMMUNITIES.SORT_COMMUNITIES](state, payload) {
    state.communities = payload;
  },
  [COMMUNITIES.SET_COMMUNITY](state, payload) {
    state.community = payload;
  },
  [COMMUNITIES.RESET_COMMUNITY](state) {
    const defaultState = getDefaultState();
    state.community = defaultState.community;
  },
  [COMMUNITIES.RESET_COMMUNITIES](state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
