<template>
  <div class="bar-animation__wrapper">
    <div class="d-inline-block bar-animation"></div>
    <div class="d-inline-block bar-animation"></div>
    <div class="d-inline-block bar-animation"></div>
  </div>
</template>

<script>
export default {
  name: "LiveIconAnimation",
};
</script>

<style scoped>
.bar-animation__wrapper {
  width: 13px;
  height: 13px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.bar-animation__wrapper div {
  display: inline-block;
}

.bar-animation__wrapper div:nth-child(1) {
  animation-delay: 0s;
}

.bar-animation__wrapper div:nth-child(2) {
  animation-delay: 0.5s;
}
.bar-animation__wrapper div:nth-child(3) {
  animation-delay: 1s;
}

.bar-animation {
  animation: stretchBar 0.3s linear infinite alternate;
  width: 3px;
  height: 100%;
  background-color: #24ff00;
}

@keyframes stretchBar {
  0% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}
</style>
