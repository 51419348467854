<template>
  <icon-base
    icon-name="ticket-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1056 7.55279L11.882 8L12.1056 8.44721L13 10.2361V11.5H6L6 4.5H13V5.76393L12.1056 7.55279ZM5 4.5H3L3 11.5H5L5 4.5ZM14 11.5V10L13 8L14 6V4.5C14 3.94772 13.5523 3.5 13 3.5H3C2.44772 3.5 2 3.94772 2 4.5V11.5C2 12.0523 2.44772 12.5 3 12.5H13C13.5523 12.5 14 12.0523 14 11.5Z"
        :fill="fillColor"
      />
    </template>

    <template v-if="fill">
      <g clip-path="url(#clip0_51_2628)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 3.5C2.44772 3.5 2 3.94772 2 4.5V11.5C2 12.0523 2.44772 12.5 3 12.5H13C13.5523 12.5 14 12.0523 14 11.5V10L13 8L14 6V4.5C14 3.94772 13.5523 3.5 13 3.5H3ZM6 5C6 4.72386 5.77614 4.5 5.5 4.5C5.22386 4.5 5 4.72386 5 5L5 11C5 11.2761 5.22386 11.5 5.5 11.5C5.77614 11.5 6 11.2761 6 11L6 5Z"
          :fill="fillColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_51_2628">
          <rect
            width="12"
            height="9"
            :fill="fillColor"
            transform="translate(2 3.5)"
          />
        </clipPath>
      </defs>
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";

export default {
  name: "TicketIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
