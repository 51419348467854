
import { computed, reactive, watch, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import helper from "@/helper/index.js";

import DashboardIcon from "../../../components/console/icons/DashboardIcon.vue";
import SpeakerIcon from "../../../components/console/icons/SpeakerIcon.vue";
import InfoIcon from "../../../components/console/icons/InfoIcon.vue";
import BrandHomeIcon from "../../../components/console/icons/BrandHomeIcon.vue";
import PlayIcon from "../../../components/console/icons/PlayIcon.vue";
import ListIcon from "../../../components/console/icons/ListIcon.vue";
import TicketIcon from "../../../components/console/icons/TicketIcon.vue";
import CommunityIcon from "../../../components/console/icons/CommunityIcon.vue";
import CommentIcon from "../../../components/console/icons/CommentIcon.vue";
import WalletIcon from "../../../components/console/icons/WalletIcon.vue";
import UserIcon from "../../../components/console/icons/UserIcon.vue";
import { useStore } from "vuex";
import Avatar from "../../../components/console/avatars/Avatar.vue";
import ExitIcon from "../../../components/console/icons/ExitIcon.vue";
import UserChildrenModal from "../../../components/common/UserChilrenModal.vue";
import LockedIcon from "../../../components/console/icons/LockedIcon.vue";
import EmojiTwoIcon from "@/components/console/icons/EmojiTwoIcon.vue";
import Tooltip from "@/components/console/tooltip/Tooltip.vue";
import LiveIcon from "@/components/console/icons/LiveIcon.vue";
import ViewFeedIcon from "@/components/console/icons/ViewFeedIcon.vue";

export default defineComponent({
  name: "SideNavNew",
  components: {
    ViewFeedIcon,
    EmojiTwoIcon,
    UserChildrenModal,
    ExitIcon,
    Avatar,
    UserIcon,
    WalletIcon,
    CommentIcon,
    CommunityIcon,
    TicketIcon,
    ListIcon,
    PlayIcon,
    BrandHomeIcon,
    InfoIcon,
    SpeakerIcon,
    DashboardIcon,
    LockedIcon,
    Tooltip,
    LiveIcon,
  },
  props: {},
  emits: ["changedRoute"],
  setup: function (props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const toolTipDistance = helper.isTabletSize() ? -50 : 30;

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      userName: computed(() => {
        return helper.displayUserName(state.user);
      }),
      currentRoute: computed(() => {
        return <string>route.name;
      }),
      isOnBoardingPage: computed(() => {
        const routeName = <string>route.name;
        return routeName.includes("console.onboarding");
      }),
      showUserChildrenModal: false,
      isOpenHourUser: computed(() => {
        return !!state.user.userProfile.defaultCoin;
      }),
      isLecture: computed(() => {
        return !!state.user.lecture;
      }),
      selectedOpenHourTooltip: "",
      isStarter: computed(() => {
        return store.getters["auth/isStarter"];
      }),
      isPrePro: computed(() => {
        return store.getters["auth/isPrePro"];
      }),
      isPro: computed(() => {
        return store.getters["auth/isPro"];
      }),
      isEnterprise: computed(() => {
        return store.getters["auth/isEnterprise"];
      }),
      isStaff: computed(() => {
        return store.getters["auth/isStaff"];
      }),
      gradeLabel: computed(() => {
        if (state.isStarter) {
          return { text: "STARTER", color: "#0d6efd" };
        } else if (state.isEnterprise) {
          return { text: "ENTERPRISE", color: "#FF1B94" };
        } else {
          return { text: "PRO", color: "#8AC707" };
        }
      }),
    });

    watch(
      () => route.path,
      () => {
        // note 라우트 변경시 모바일에서 sideNav 숨김처리
        emit("changedRoute");
      }
    );

    const moveRoute = (params) => {
      if (state.isOnBoardingPage) {
        return;
      }

      router.push({ name: params.routeName });
    };

    const moveRouteQueryParameter = (params) => {
      router.push({ name: params.routeName, query: params.query });
    };

    const openWebView = (url) => {
      if (state.isOnBoardingPage) {
        return;
      }
      helper.goToExternalUrl(url);
    };

    const closeOpenHourTooltip = () => {
      state.selectedOpenHourTooltip = "";
    };

    const actions = {
      goToDashboard: () => {
        moveRoute({ routeName: "console.home" });
      },
      goToNotice: () => {
        moveRoute({ routeName: "console.notices" });
      },
      goToCreatorGuide: () => {
        let url =
          "https://bigc-im.notion.site/ef997064b0d54dea996afe4141ccb3c8";
        openWebView(url);
      },
      goToBrandHome: () => {
        moveRoute({ routeName: "console.brand.widgets" });
      },
      goToSnsFeed: () => {
        moveRoute({ routeName: "console.channels" });
      },
      goToThemes: () => {
        moveRoute({ routeName: "console.themes" });
      },
      goToClubs: () => {
        let query = {
          q: "",
          status: 0,
          perPage: 15,
          isTemporary: 0,
          type: "",
          order: "desc",
          page: 1,
        };
        moveRouteQueryParameter({ routeName: "console.clubs", query });
      },
      goToMembers: () => {
        moveRoute({ routeName: "console.members" });
      },
      goToArticles: () => {
        moveRoute({ routeName: "console.articles" });
      },
      goToAccount: () => {
        moveRoute({ routeName: "console.account" });
      },
      goToContents: () => {
        moveRoute({ routeName: "console.contents" });
      },
      openUserChildrenModal: () => {
        state.showUserChildrenModal = true;
      },
      closeUserChildrenModal: () => {
        state.showUserChildrenModal = false;
      },
      goToSettlement: () => {
        router.push({ name: "console.settlement" });
      },
      logout: () => {
        store.dispatch("auth/logout").then(() => {
          router.push({ name: "web.home" });
        });
      },
      logoutByAuthService: () => {
        store.dispatch("auth/logout").then(() => {
          const authUrl = process.env.VUE_APP_AUTH_DOMAIN;
          const redirectUri = `${process.env.VUE_APP_URL}`;

          window.location.href =
            authUrl +
            "/auth/logout?" +
            "redirect=" +
            encodeURIComponent(redirectUri);
        });
      },
      goToOpenHour: () => {
        closeOpenHourTooltip();
        moveRoute({ routeName: "console.openHourInfo" });
      },
      goToOpenHourSchedule: () => {
        moveRoute({ routeName: "console.openHourSchedule" });
      },
      goToOpenHourReservation: () => {
        moveRoute({ routeName: "console.openHourReservation" });
      },
      goToOpenHourReview: () => {
        moveRoute({ routeName: "console.openHourReview" });
      },
      toggleOpenHourTooltip: (val) => {
        state.selectedOpenHourTooltip = val;
      },
      goToOpenHourChats: () => {
        moveRoute({ routeName: "console.openHourChats" });
      },
      goToEntClubsCrete: () => {
        moveRoute({ routeName: "console.ent.themes" });
      },
      goToEntClubs: () => {
        moveRoute({ routeName: "console.ent.clubs" });
      },
      goToEntMeetings: () => {
        moveRoute({ routeName: "console.ent.meetings" });
      },
      goToEntContents: () => {
        moveRoute({ routeName: "console.ent.contents" });
      },
      goToEntBrand: () => {
        moveRoute({ routeName: "console.ent.brand.widgets" });
      },
      goToPolls: () => {
        let query = {
          page: 1,
          perPage: 15,
        };
        moveRouteQueryParameter({ routeName: "console.polls", query });
      },
    };

    return {
      state,
      actions,
      toolTipDistance,
    };
  },
});
