import { createApp } from "vue";
import App from "./App.vue";
import { createHead } from "@vueuse/head";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { createGtm } from "@gtm-support/vue-gtm";
import C from "./consts";
import VueApexCharts from "vue3-apexcharts";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import "vue-loading-overlay/dist/css/index.css";
import vue3GoogleLogin from "vue3-google-login";

import router from "./router/index";

import { i18nSetup } from "@/i18n";
const i18n = i18nSetup();

const head = createHead();

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(head);

app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});

app.use(Toast, {
  timeout: 2000,
});
app.use(
  createGtm({
    id: "GTM-5DXBWSQ",
    defer: false,
    compatibility: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  })
);
app.use(FloatingVue, {
  themes: {
    "select-dropdown": {
      $extend: "dropdown",
    },
  },
});
app.use(VueApexCharts);

app.mount("#app");

app.config.globalProperties.$const = C;
