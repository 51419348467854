
import { computed, reactive, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import DatalayerEvents from "@/helper/datalayerEvents.js";

export default defineComponent({
  name: "HeaderComponent",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const dataLayer = new DatalayerEvents();

    const state = reactive({
      isAuth: computed(() => {
        return store.getters["auth/token"];
      }),
      activeNav: false,
    });

    const actions = {
      goToAuth: (data) => {
        if (data) {
          let eventObj = dataLayer[data];
          dataLayer.push(eventObj);
        }
        router.push({ name: "auth.login" });
      },
      loginByAuthService: (data) => {
        if (data) {
          let eventObj = dataLayer[data];
          dataLayer.push(eventObj);
        }
        const authUrl = process.env.VUE_APP_AUTH_DOMAIN;
        const redirectUri = `${process.env.VUE_APP_URL}/console`;
        window.location.href =
          authUrl +
          "/auth/login?" +
          "redirect=" +
          encodeURIComponent(redirectUri) +
          "&brand=bigcstudio";
      },
      goToStudio: () => {
        router.push({ name: "console.home" });
      },
      setDataLayer: (data) => {
        let eventObj = dataLayer[data];
        dataLayer.push(eventObj);
      },
      logout: async () => {
        await store.dispatch("auth/logout");
        await router.push({ name: "web.home" });
      },
      closeNav: () => {
        state.activeNav = false;
        document.getElementById("app").style.overflow = "visible";
      },
    };

    return {
      state,
      actions,
    };
  },
});
