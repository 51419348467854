<template>
  <div
    ref="avatarElement"
    class="avatar"
    :style="{
      backgroundImage: `url('${
        avatarImg ? avatarImg : proxy.$const.blankImage
      }')`,
      width: `${size}px`,
      height: `${size}px`,
    }"
  ></div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from "vue";

export default {
  name: "Avatar",
  props: {
    avatarImg: {
      type: String,
      default: "https://cdn.bigc.im/statics/commons/images/empty.png",
    },
    size: {
      type: [Number, String],
      default: 48,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const avatarElement = ref();

    onMounted(() => {
      avatarElement.value.style.width = `${props.size}px`;
      avatarElement.value.style.height = `${props.size}px`;
    });

    return { proxy, avatarElement };
  },
};
</script>

<style scoped>
.avatar {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}
</style>
