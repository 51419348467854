import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_video_uploader = _resolveComponent("video-uploader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_openBlock(), _createBlock(_Teleport, { disabled: true }, [
      (_ctx.state.existUploadingFile)
        ? (_openBlock(), _createBlock(_component_video_uploader, { key: 0 }))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}