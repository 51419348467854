import { COMMENTS } from "../mutation-types";
import ApiService from "@/api";
import _ from "lodash";

const getDefaultState = () => {
  return {
    articleComments: [],
    articleCommentsMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
    contentComments: [],
    contentCommentsMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
  };
};

const state = getDefaultState();

const getters = {
  articleComments: (state) => {
    return state.articleComments;
  },
};

const actions = {
  resetComments({ commit }) {
    commit(COMMENTS.RESET_COMMENTS);
  },
  /**
   * Article Comment actions
   * @param commit
   * @param dispatch
   * @param payload
   */
  postArticleComment({ commit, dispatch }, payload) {
    ApiService.postComments(payload).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.ADD_ARTICLE_COMMENT, res.data.data);
        dispatch("articles/fetchArticles", {}, { root: true });
      }
    });
  },
  getArticleComments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getComments(payload).then(
        (res) => {
          if (res.data.success) {
            commit(COMMENTS.SET_ARTICLE_COMMENTS, res.data);
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async putArticleComment({ dispatch }, payload) {
    await ApiService.putComment(payload.commentResourceId, {
      content: payload.content,
    }).then(async (res) => {
      if (res.data.success) {
        await dispatch("articles/fetchArticles", {}, { root: true });
      }
    });
  },
  deleteArticleComment({ commit, dispatch }, payload) {
    ApiService.deleteComments(payload.commentResourceId).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.POP_ARTICLE_COMMENT, {
          commentResourceId: payload.commentResourceId,
        });
        dispatch("articles/fetchArticles", {}, { root: true });
      }
    });
  },
  postArticleCommentLike({ dispatch }, payload) {
    ApiService.postLike(payload).then((res) => {
      if (res.data.success) {
        dispatch("articles/fetchArticles", {}, { root: true });
      }
    });
  },
  deleteArticleCommentComment({ commit, dispatch }, payload) {
    ApiService.deleteComments(payload.commentResourceId).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.POP_ARTICLE_COMMENT_COMMENT, {
          parentCommentResourceId: payload.parentCommentResourceId,
          commentResourceId: payload.commentResourceId,
        });
        dispatch("articles/fetchArticles", {}, { root: true });
      }
    });
  },
  postArticleCommentComment({ dispatch }, payload) {
    ApiService.postComments(payload).then((res) => {
      if (res.data.success) {
        dispatch("articles/fetchArticles", {}, { root: true });
      }
    });
  },
  /**
   * Content Comment actions
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async getContentComments(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getComments(payload).then(
        (res) => {
          if (res.data.success) {
            context.commit(COMMENTS.SET_CONTENT_COMMENTS, res.data);
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  postContentComment({ commit, dispatch }, payload) {
    ApiService.postComments(payload).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.ADD_CONTENT_COMMENTS, res.data.data);
        dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  async putContentComment({ commit, dispatch }, payload) {
    await ApiService.putComment(payload.commentResourceId, {
      content: payload.content,
    }).then(async (res) => {
      if (res.data.success) {
        await commit(COMMENTS.UPDATE_CONTENT_COMMENT, {
          commentResourceId: payload.commentResourceId,
          data: res.data.data,
        });
        dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  deleteContentComment({ commit, dispatch }, payload) {
    ApiService.deleteComments(payload.commentResourceId).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.POP_CONTENT_COMMENT, {
          commentResourceId: payload.commentResourceId,
        });
        dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  postContentCommentLike({ commit, dispatch }, payload) {
    ApiService.postLike(payload).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.UPDATE_CONTENT_COMMENT_LIKE, res.data.data);
        dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  postContentCommentComment({ commit, dispatch }, payload) {
    ApiService.postComments(payload).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.ADD_CONTENT_COMMENT_COMMENT, {
          commentResourceId: payload.resourceId,
          data: res.data.data,
        });
        dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  async putContentCommentComment({ commit, dispatch }, payload) {
    await ApiService.putComment(payload.commentResourceId, {
      content: payload.content,
    }).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.UPDATE_CONTENT_COMMENT_COMMENT, {
          parentCommentResourceId: payload.parentCommentResourceId,
          commentResourceId: payload.commentResourceId,
          data: res.data.data,
        });
        // dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  deleteContentCommentComment({ commit, dispatch }, payload) {
    ApiService.deleteComments(payload.commentResourceId).then((res) => {
      if (res.data.success) {
        commit(COMMENTS.POP_CONTENT_COMMENT_COMMENT, {
          parentCommentResourceId: payload.parentCommentResourceId,
          commentResourceId: payload.commentResourceId,
        });
        dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  postCommentCommentLike({ commit, dispatch }, payload) {
    let params = {
      model: payload.model,
      resourceId: payload.resourceId,
      mode: "toggle",
    };
    ApiService.postLike(params).then((res) => {
      if (res.data.success) {
        let data = res.data.data;
        data.parentResourceId = payload.parentResourceId;
        commit(COMMENTS.UPDATE_COMMENT_COMMENT_LIKE, data);
        dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
};

const mutations = {
  [COMMENTS.RESET_COMMENTS](state) {
    Object.assign(state, getDefaultState());
  },
  /**
   * Article Comment mutations
   * @param state
   * @param payload
   */
  [COMMENTS.SET_ARTICLE_COMMENTS](state, payload) {
    // TODO 배열 안에 값들이 중복되어 있음 수정 필요
    // state.articleComments.push(..._.cloneDeep(payload.data));
    state.articleComments = payload.data;
    state.articleCommentsMeta.pagination = payload.meta.pagination;
  },
  [COMMENTS.UPDATE_ARTICLE_COMMENT_PREV_LIKE](state, payload) {
    const index = state.articleComments.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.articleComments[index].isLiked =
      !state.articleComments[index].isLiked;
    state.articleComments[index].isLiked
      ? state.articleComments[index].likersCount++
      : state.articleComments[index].likersCount--;
  },
  [COMMENTS.POP_ARTICLE_COMMENT](state, payload) {
    state.articleComments = state.articleComments.filter((item) => {
      return item.resourceId !== payload.commentResourceId;
    });
  },
  [COMMENTS.POP_ARTICLE_COMMENT_COMMENT](state, payload) {
    const index = state.articleComments.findIndex((item) => {
      return item.resourceId === payload.parentCommentResourceId;
    });
    state.articleComments[index].comments = state.articleComments[
      index
    ].comments.filter((item) => {
      return item.resourceId !== payload.commentResourceId;
    });
  },
  [COMMENTS.ADD_ARTICLE_COMMENT](state, payload) {
    state.articleComments.unshift(payload);
  },
  [COMMENTS.RESET_ARTICLE_COMMENTS](state) {
    state.articleComments = [];
  },
  /**
   * Content Comment mutations
   * @param state
   */
  [COMMENTS.SET_CONTENT_COMMENTS](state, payload) {
    state.contentComments.push(..._.cloneDeep(payload.data));
    state.contentCommentsMeta.pagination = payload.meta.pagination;
  },
  [COMMENTS.ADD_CONTENT_COMMENTS](state, payload) {
    state.contentComments.unshift(payload);
  },
  [COMMENTS.UPDATE_CONTENT_COMMENT](state, payload) {
    const index = state.contentComments.findIndex((item) => {
      return item.resourceId === payload.commentResourceId;
    });
    state.contentComments[index] = {
      ..._.cloneDeep(state.contentComments[index]),
      ..._.cloneDeep(payload.data),
      ...{ comments: state.contentComments[index].comments },
    };
  },
  [COMMENTS.POP_CONTENT_COMMENT](state, payload) {
    state.contentComments = state.contentComments.filter((item) => {
      return item.resourceId !== payload.commentResourceId;
    });
  },
  [COMMENTS.UPDATE_CONTENT_COMMENT_PREV_LIKE](state, payload) {
    const index = state.contentComments.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.contentComments[index].isLiked =
      !state.contentComments[index].isLiked;
    state.contentComments[index].isLiked
      ? state.contentComments[index].likersCount++
      : state.contentComments[index].likersCount--;
  },
  [COMMENTS.UPDATE_CONTENT_COMMENT_LIKE](state, payload) {
    const index = state.contentComments.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.contentComments[index].isLiked = payload.isLiked;
    state.contentComments[index].likersCount = payload.likersCount;
  },
  [COMMENTS.ADD_CONTENT_COMMENT_COMMENT](state, payload) {
    const index = state.contentComments.findIndex((item) => {
      return item.resourceId === payload.commentResourceId;
    });
    if (state.contentComments[index].comments) {
      state.contentComments[index].comments.unshift(_.cloneDeep(payload.data));
    } else {
      state.contentComments[index]["comments"] = [_.cloneDeep(payload.data)];
    }
  },
  [COMMENTS.UPDATE_CONTENT_COMMENT_COMMENT](state, payload) {
    const index = state.contentComments.findIndex((item) => {
      return item.resourceId === payload.parentCommentResourceId;
    });

    let commentsComments = state.contentComments[index].comments;
    let commentsComment = commentsComments.find(
      (item) => item.resourceId === payload.commentResourceId
    );
    Object.assign(commentsComment, payload.data);
  },
  [COMMENTS.POP_CONTENT_COMMENT_COMMENT](state, payload) {
    const index = state.contentComments.findIndex((item) => {
      return item.resourceId === payload.parentCommentResourceId;
    });
    state.contentComments[index].comments = state.contentComments[
      index
    ].comments.filter((item) => {
      return item.resourceId !== payload.commentResourceId;
    });
  },
  [COMMENTS.UPDATE_COMMENT_COMMENT_PREV_LIKE](state, payload) {
    const index = state.contentComments.findIndex((item) => {
      return item.resourceId === payload.parentResourceId;
    });
    let commentsComments = state.contentComments[index].comments;
    const commentsComment = commentsComments.find(
      (item) => item.resourceId === payload.resourceId
    );
    commentsComment.isLiked = !commentsComment.isLiked;
    commentsComment.isLiked
      ? commentsComment.likersCount++
      : commentsComment.likersCount--;
  },
  [COMMENTS.UPDATE_COMMENT_COMMENT_LIKE](state, payload) {
    const index = state.contentComments.findIndex((item) => {
      return item.resourceId === payload.parentResourceId;
    });
    let commentsComments = state.contentComments[index].comments;
    const commentsComment = commentsComments.find(
      (item) => item.resourceId === payload.resourceId
    );
    commentsComment.isLiked = payload.isLiked;
    commentsComment.likersCount = payload.likersCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
