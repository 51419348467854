import ApiService from "@/api";
import { CONTENTS } from "../mutation-types";
import _ from "lodash";
import { dateFormat } from "../../helper/date";
import swal from "@/helper/swal";
import axios from "axios";

const getDefaultState = () => {
  return {
    contents: [],
    contentsMeta: {
      types: [
        { type: null, label: "전체", count: 0 },
        { type: 1, label: "Article", count: 0 },
        { type: 3, label: "Video", count: 0 },
        { type: 5, label: "Link", count: 0 },
      ],
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
    contentType: 0,
    content: {
      attachments: [],
      captions: [],
      channelCode: "",
      comments: [],
      createdAt: "",
      description: "",
      duration: null,
      etag: "",
      favoritersCount: 0,
      featuredImage: "",
      identifier: "",
      isAccess: true,
      isFavorited: false,
      isGeoFence: false,
      isLiked: false,
      isPaid: false,
      isUserOnly: false,
      label: "",
      likersCount: 0,
      meeting: "",
      publishedAt: "",
      resourceId: "",
      startedAt: "",
      status: 0,
      text: "",
      textHtml: "",
      title: "",
      type: "0",
      updatedAt: "",
      url: "",
      urlDash: "",
      urlDashSubtitles: "",
      urlHls: "",
      urlHlsSubtitles: "",
    },
    multipartFiles: [],
    /* {
    id: // 콘테츠 리소스 아이디
      fileName: "",
        size: "",
        fileFormat: "",
      errors:{
      fileType:
      networkError:
      },
      isNetworkError: false,
      isUploading: false,
      isUploadSuccess: false,
      triggerDeleteUploadingMultipart:false,
      numberOfFiles: 0,
      progress: 0,
     }
    */
  };
};

const state = getDefaultState();

const getters = {
  contents: (state) => {
    return state.contents;
  },
  contentsMeta: (state) => {
    return state.contentsMeta;
  },
  content: (state) => {
    return state.content;
  },
  // todo 추가된 콘텐츠 타입 확인
  types: (state) => {
    const types = state.contentsMeta.types.map((item) => {
      if (item.label === "전체") {
        item.label = "전체";
      }
      if (item.label === "Article") {
        item.label = "아티클";
      }
      if (item.label === "Video") {
        item.label = "영상";
      }
      if (item.label === "Link") {
        item.label = "링크";
      }
      return item;
    });
    return types;
  },
  multipartFiles: (state) => {
    return state.multipartFiles;
  },
};

const actions = {
  resetContents({ commit }) {
    commit(CONTENTS.RESET_CONTENTS);
  },
  async fetchContents({ commit }) {
    await ApiService.getContents().then(async (res) => {
      if (res.data.success) {
        await commit(CONTENTS.FETCH_CONTENTS, res.data);
      }
    });
  },
  async postContent({ commit, dispatch }, payload) {
    try {
      const res = await ApiService.postContent(payload);
      if (res.data.success) {
        await commit(CONTENTS.UPDATE_CONTENT, res.data.data);
        dispatch("getContents");
      }
    } catch (error) {
      console.log("An error occured:", error);
    }
  },
  async getContents({ commit }, payload) {
    await ApiService.getContents(payload).then((res) => {
      if (res.data.success) {
        commit(CONTENTS.SET_CONTENTS, res.data);
      }
    });
  },
  async getContent({ commit }, payload) {
    await ApiService.getContent(payload.contentResourceId).then(async (res) => {
      if (res.data.success) {
        await commit(CONTENTS.UPDATE_CONTENT, res.data.data);
      }
    });
  },
  async putContent({ commit, dispatch }, payload) {
    await ApiService.putContent(payload.resourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getContent", {
            contentResourceId: res.data.data.resourceId,
          });
        }
      }
    );
  },
  async deleteContent({ dispatch }, payload) {
    try {
      let res = await ApiService.deleteContent(payload);
      if (res.data.success) {
        dispatch("fetchContents");
      } else {
        return res;
      }
    } catch (e) {
      return { error: e };
    }
  },
  async getContentMetaFromUrl({ commit }, payload) {
    await ApiService.getContentMetaFromUrl({ url: payload.url }).then(
      async (res) => {
        if (res.data.success) {
          const metas = res.data.data;
          let metaDate = metas.publishedAt;
          const metaData = {
            ...metas,
            ...{
              publishedAt: metaDate
                ? dateFormat(metaDate, "YYYY-MM-DD hh:mm")
                : "",
            },
          };
          commit(CONTENTS.UPDATE_CONTENT, metaData);
        } else {
          // todo contentForm.vue에서 처리하도록 수정
          swal.messageErrorAlert("수집할수 없는 url 입니다.");
        }
      }
    );
  },
  async uploadContentAttachment({ commit, dispatch }, payload) {
    await ApiService.postContentsAttachment(
      payload.resourceId,
      payload.file
    ).then(async (res) => {
      if (res.data.success) {
        await commit(CONTENTS.CONTENT_ADD_ATTACHMENT, res.data.data);
        // dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  async deleteContentAttachment({ commit, dispatch }, payload) {
    await ApiService.deleteAttachment(payload.resourceId).then(async (res) => {
      if (res.data.success) {
        await commit(CONTENTS.CONTENT_POP_ATTACHMENT, payload.resourceId);
        // dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  async deleteContentFeaturedImage({ dispatch }, payload) {
    await ApiService.deleteContentFeaturedImage(payload.contentResourceId).then(
      async (res) => {
        if (res.data.success) {
          dispatch("fetchContents");
        }
      }
    );
  },
  async updateContentShow({ dispatch }, payload) {
    await ApiService.updateContentShow(payload.resourceId, payload.data).then(
      (res) => {
        if (res.data.success) {
          dispatch("fetchContents");
        }
      }
    );
  },
  async uploadContentCaption({ commit, dispatch }, payload) {
    await ApiService.postContentsCaption(payload.resourceId, payload.file).then(
      async (res) => {
        if (res.data.success) {
          // todo 로직 개선;
          let { content, ...rest } = res.data.data;
          console.log(content);
          console.log(rest);
          await commit(CONTENTS.CONTENT_ADD_CAPTION, rest);
          await commit(CONTENTS.UPDATE_CONTENT_SUBTITLES, content);
        }
      }
    );
  },
  async deleteContentCaption({ commit, dispatch }, payload) {
    await ApiService.deleteCaption(payload.resourceId).then(async (res) => {
      if (res.data.success) {
        await commit(CONTENTS.CONTENT_POP_CAPTION, payload.resourceId);
        await commit(CONTENTS.UPDATE_CONTENT_SUBTITLES, res.data.data);
        // dispatch("contents/fetchContents", {}, { root: true });
      }
    });
  },
  addMultipartFile({ commit }, data) {
    commit(CONTENTS.CONTENT_ADD_MULTIPART_FILES, data);
  },
  async postMultipartFile(context, resourceId) {
    const index = state.multipartFiles.findIndex((item) => {
      return item.resourceId === resourceId;
    });

    const uploadingData = state.multipartFiles[index];

    try {
      const res = await ApiService.postMultipart(
        {
          onlyVideoEncoding: uploadingData.isOnlyVideoEncoding,
        },
        { fileName: uploadingData.payload.fileName }
      );
      const data = res.data.data;
      uploadingData.payload.uploadId = data.uploadId;
      uploadingData.payload.uploadKey = data.key;
      uploadingData.isUploading = true;
      uploadingData.isUploadSuccess = false;

      context.commit(CONTENTS.CONTENT_UPDATE_MULTIPART_FILE, {
        index,
        data: {
          uploadId: uploadingData.payload.uploadId,
          uploadKey: uploadingData.payload.uploadKey,
          isUploading: uploadingData.isUploading,
          isUploadSuccess: uploadingData.isUploadSuccess,
        },
      });

      for (let i = 0; i < uploadingData.numberOfFiles; i++) {
        uploadingData.triggerDeleteUploadingMultipart =
          state.multipartFiles[index].triggerDeleteUploadingMultipart;
        if (uploadingData.triggerDeleteUploadingMultipart) {
          await context.dispatch("deleteMultipart", {
            uploadId: uploadingData.payload.uploadId,
            data: {
              key: uploadingData.payload.uploadKey,
            },
          });
          // deleteMultipart({
          //   uploadId: uploadingData.payload.uploadId,
          //   data: {
          //     key: uploadingData.payload.uploadKey,
          //   },
          // });
          uploadingData.triggerDeleteUploadingMultipart = false;
          uploadingData.isUploading = false;
          uploadingData.isUploadSuccess = false;

          context.commit(CONTENTS.CONTENT_UPDATE_MULTIPART_FILE, {
            index,
            data: {
              triggerDeleteUploadingMultipart:
                uploadingData.triggerDeleteUploadingMultipart,
              isUploading: uploadingData.isUploading,
              isUploadSuccess: uploadingData.isUploadSuccess,
            },
          });
          await context.commit(
            "CONTENT_POP_MULTIPART_FILE",
            uploadingData.resourceId
          );

          swal.createCompleteToast("취소 되었습니다.");
          return;
        }
        try {
          const res = await ApiService.getMultipartPresignedUrl(
            uploadingData.payload.uploadId,
            i + 1,
            {
              key: uploadingData.payload.uploadKey,
            }
          );
          const data = res.data.data;
          const { signedUrl: preSignedUrl } = data;
          const respos = await retryAxiosPut(
            i,
            preSignedUrl,
            uploadingData.chunks[i],
            {
              headers: {
                "Content-Type": "video/mp4",
              },
            }
          );

          let resEtag = respos.headers.etag;
          let eTag = extractEtag(resEtag);
          uploadingData.payloadParts[i] = { ETag: eTag, PartNumber: i + 1 };
          uploadingData.progress = i + 1;
          context.commit(CONTENTS.CONTENT_UPDATE_MULTIPART_FILE, {
            index,
            data: {
              progress: uploadingData.progress,
              payloadParts: uploadingData.payloadParts,
            },
          });
        } catch (error) {
          console.error(error);
        }
      }

      if (uploadingData.payloadParts.length == uploadingData.numberOfFiles) {
        try {
          const res = await ApiService.completeMultipart(
            uploadingData.payload.uploadId,
            { key: uploadingData.payload.uploadKey },
            { parts: uploadingData.payloadParts }
          );
          const data = res.data.data;
          uploadingData.isUploading = false;
          uploadingData.isUploadSuccess = true;
          context.commit(CONTENTS.CONTENT_UPDATE_MULTIPART_FILE, {
            index,
            data: {
              isUploading: uploadingData.isUploading,
              isUploadSuccess: uploadingData.isUploadSuccess,
              numberOfFiles: 0,
            },
          });
          context.commit(
            CONTENTS.CONTENT_POP_MULTIPART_FILE,
            uploadingData.resourceId
          );
          swal.createCompleteToast("업로드 되었습니다.");
        } catch (error) {
          console.error(error);
        }
      }
    } catch (e) {
      console.error(e);
    }
  },
  async deleteMultipart({ commit }, payload) {
    await ApiService.deleteMultipart(payload.uploadId, payload.data);
  },
  async cancelUploadingMultipart({ commit }, payload) {
    await commit("CONTENT_CANCEL_UPLOADING_MULTIPART", payload);
  },
};

const extractEtag = (resEtag) => {
  return resEtag.replace(/"/g, "");
};

const retryAxiosPut = async (
  i,
  url,
  data,
  options,
  retries = 3,
  delay = 1000
) => {
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await axios.put(url, data, options);
      return response;
    } catch (error) {
      // state.isNetworkError = true;
      if (attempt < retries - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        // if (state.isNetworkError) {
        //   state.errorMessageByNetworkError = "파일을 다시 업로드 해주세요.";
        // }
        throw error;
      }
    }
  }
};

const mutations = {
  [CONTENTS.RESET_CONTENTS](state) {
    Object.assign(state, getDefaultState());
  },
  [CONTENTS.FETCH_CONTENTS](state, payload) {
    state.contents = _.cloneDeep(payload.data);
    state.contentsMeta = {
      ..._.cloneDeep(state.contentsMeta),
      ..._.cloneDeep(payload.meta),
    };
  },
  [CONTENTS.SET_CONTENTS](state, payload) {
    state.contents = _.cloneDeep(payload.data);
    state.contentsMeta.pagination = {
      ...state.contentsMeta.pagination,
      ...payload.meta.pagination,
    };
    state.contentsMeta = _.cloneDeep(payload.meta);
  },
  [CONTENTS.FIND_SET_CONTENT](state, payload) {
    const content = state.contents.find((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.content = { ..._.cloneDeep(state.content), ..._.cloneDeep(content) };
  },
  [CONTENTS.UPDATE_CONTENT](state, payload) {
    state.content = { ..._.cloneDeep(state.content), ..._.cloneDeep(payload) };
  },
  [CONTENTS.CONTENT_ADD_ATTACHMENT](state, payload) {
    state.content.attachments.push(payload);
  },
  [CONTENTS.CONTENT_POP_ATTACHMENT](state, captionResourceId) {
    const attachmentIndex = state.content.attachments.findIndex((item) => {
      return item.resourceId === captionResourceId;
    });
    console.log(attachmentIndex);
    state.content.attachments.splice(attachmentIndex, 1);
  },
  [CONTENTS.CONTENT_ADD_CAPTION](state, payload) {
    state.content.captions.push(payload);
  },
  [CONTENTS.CONTENT_POP_CAPTION](state, captionResourceId) {
    const captionIndex = state.content.captions.findIndex((item) => {
      return item.resourceId === captionResourceId;
    });
    state.content.captions.splice(captionIndex, 1);
  },
  [CONTENTS.UPDATE_CONTENT_SUBTITLES](state, payload) {
    state.content.urlDashSubtitles = payload.urlDashSubtitles;
    state.content.urlHlsSubtitles = payload.urlHlsSubtitles;
  },
  [CONTENTS.CONTENT_ADD_MULTIPART_FILES](state, fileData) {
    const uploadingData = fileData;
    state.multipartFiles.push(uploadingData);
  },
  [CONTENTS.CONTENT_UPDATE_MULTIPART_FILE](state, payload) {
    state.multipartFiles[payload.index] = {
      ...state.multipartFiles[payload.index],
      ...payload.data,
    };
  },
  [CONTENTS.CONTENT_POP_MULTIPART_FILE](state, resourceId) {
    const index = state.multipartFiles.findIndex((item) => {
      return item.resourceId === resourceId;
    });
    state.multipartFiles.splice(index, 1);
  },
  [CONTENTS.CONTENT_CANCEL_UPLOADING_MULTIPART](state, resourceId) {
    const index = state.multipartFiles.findIndex((item) => {
      return item.resourceId === resourceId;
    });
    state.multipartFiles[index].triggerDeleteUploadingMultipart = true;
  },
  [CONTENTS.CONTENT_RESET](state) {
    Object.assign(state.content, getDefaultState().content);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
