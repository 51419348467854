import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import webRoutes from "./webRoutes";
import newRoutes from "./newRoutes.js";
import store from "../store";

const routes = [
  {
    path: "/auth/login",
    name: "auth.login",
    props: true,
    meta: { requiresAuth: false, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "auth.login" */ "../pages/common/Login/Login.vue"
      ),
  },
  {
    path: "/auth/join",
    name: "auth.join",
    meta: { requiresAuth: false, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "auth.login" */ "../pages/common/Join/Join.vue"
      ),
  },
  {
    path: "/auth/findpwd",
    name: "auth.findpwd",
    meta: { requiresAuth: false, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "auth.findpwd" */ "../pages/console/FindPassword/FindPassword.vue"
      ),
  },
  {
    path: "/auth/resetpwd",
    name: "auth.resetpwd",
    meta: { requiresAuth: false, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "auth.resetpwd" */ "../pages/console/ResetPassword/ResetPassword.vue"
      ),
  },
  {
    path: "/auth/bridge/:userResourceId",
    name: "auth.bridge",
    props: true,
    meta: { requiresAuth: false, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "auth.bridge" */ "../pages/common/Bridge.vue"
      ),
  },
  {
    path: "/auth/handle/:provider",
    name: "auth.handle.social",
    props: true,
    meta: { requiresAuth: false, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "auth.sso" */ "../pages/common/SocialCallBack.vue"
      ),
  },
  {
    path: "/auth/sso",
    name: "auth.sso",
    meta: { requiresAuth: false, layout: "ConsoleBasicLayout" },
    component: () =>
      import(/* webpackChunkName: "auth.sso" */ "../pages/common/Sso.vue"),
  },
  ...webRoutes,
  ...newRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const routerToName = <string>to.name;
    const getAppName = (): string => {
      const arrRouteNames = routerToName.split(".");
      return arrRouteNames[0];
    };

    if (!store.getters["auth/check"] && store.getters["auth/token"]) {
      try {
        await store.dispatch("auth/fetchUser");
      } catch (e) {
        console.log(e);
      }
    }
    const isAuth = await store.getters["auth/check"];

    // note 로그인이 필요한 화면에 로그인 상태가 아니면 통합 로그인 화면으로 리다이렉트
    if (to.meta.requiresAuth && !isAuth) {
      const authUrl = process.env.VUE_APP_AUTH_DOMAIN;
      const redirectUri = `${process.env.VUE_APP_URL + to.fullPath}`;
      window.location.href =
        authUrl +
        "/auth/login?" +
        "redirect=" +
        encodeURIComponent(redirectUri) +
        "&brand=bigcstudio";
      return;
    }

    return next();
  }
);

export default router;
