<template>
  <div class="video-uploader-progress">
    <h1>Progress</h1>
    <div>
      <h2>{{ state.uploadingFile.name }}</h2>
      <progress
        class="progress-bar"
        :max="100"
        :value="state.progressValue"
      ></progress>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import swal from "@/helper/swal";

export default {
  name: "VideoUploader",
  setup() {
    const store = useStore();

    const state = reactive({
      uploadingFile: computed(() => {
        return store.getters["file/uploadingFile"];
      }),
      uploadingFilesLoaded: computed(() => {
        return store.getters["file/uploadingFileLoaded"];
      }),
      progressValue: computed(() => {
        return (
          (state.uploadingFilesLoaded.loaded /
            state.uploadingFilesLoaded.total) *
          100
        );
      }),
      existUploadingFile: computed(() => {
        return store.getters["file/existUploadingFile"];
      }),
    });

    watch(
      () => state.existUploadingFile,
      (existUploadingFile) => {
        if (!existUploadingFile) {
          store.dispatch("file/completeUploading").then(() => {
            swal.createCompleteToast();
          });
        }
      }
    );

    return { state };
  },
};
</script>

<style scoped>
.video-uploader-progress {
  position: fixed;
  top: 0;
  left: 0;
  background: red;
  z-index: 10;
  padding: 20px;
}

.progress-bar {
  width: 100%;
}
</style>
