import { FILES } from "../mutation-types";

const getDefaultState = () => {
  return {
    uploadingFile: null,
    uploadingFileLoaded: {
      fileName: "",
      loaded: 0,
      total: 0,
    },
  };
};

const state = getDefaultState();

const getters = {
  uploadingFile: (state) => {
    return state.uploadingFile;
  },
  uploadingFileLoaded: (state) => {
    return state.uploadingFileLoaded;
  },
  existUploadingFile: (state) => {
    return !!state.uploadingFile;
  },
};

const actions = {
  setUploadingFile({ commit }, file) {
    commit(FILES.SET_UPLOADING_FILE, file);
  },
  updateUploadingFileLoaded({ commit }, payload) {
    commit(FILES.UPDATE_UPLOADING_FILE_LOADED, payload);
  },
  completeUploading({ commit }) {
    commit(FILES.COMPLETE_UPLOADING);
  },
};

const mutations = {
  [FILES.SET_UPLOADING_FILE](state, file) {
    state.uploadingFile = file;
  },
  [FILES.UPDATE_UPLOADING_FILE_LOADED](state, payload) {
    state.uploadingFileLoaded = {
      ...state.uploadingFileLoaded,
      ...payload,
    };
  },
  [FILES.COMPLETE_UPLOADING](state) {
    state.uploadingFile = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
