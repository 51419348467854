<template>
  <icon-base
    icon-name="speaker-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00013 3.88857L12.3626 2.02009C12.682 1.92629 13 2.1728 13 2.51424V13.4858C13 13.8272 12.682 14.0737 12.3626 13.9799L6 12.1114H4C3.44772 12.1114 3 11.6512 3 11.0835V4.91646C3 4.34879 3.44772 3.88861 4 3.88861H6V3.88857H6.00013ZM6 4.91646H4V11.0835H6V4.91646ZM7 11.3361L12 12.8044V3.19556L7 4.66392L7 11.3361Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";

export default {
  name: "SpeakerIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
