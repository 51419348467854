class DatalayerEvents {
  constructor() {
    if (!DatalayerEvents.instance) {
      DatalayerEvents.instance = this;
    }
    return DatalayerEvents.instance;
  }

  // StudioLanding.vue 플로팅 버튼
  floating = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "floating",
    },
  };

  order1 = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "order-1",
    },
  };

  order2 = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "order-2",
    },
  };

  order3 = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "3",
    },
  };

  promotionNav = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "promotion",
    },
  };

  promotion = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "promotion-1",
    },
  };

  pricingStart = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "plan-1",
    },
  };

  club1 = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "club-1",
    },
  };

  club2 = {
    event: "studio_click",
    studio_click: {
      button_type: "button_start",
      button_title: "club-2",
    },
  };

  introduction = {
    event: "studio_click",
    studio_click: {
      button_type: "button_main",
      button_title: "for_creators",
    },
  };

  bigcService = {
    event: "studio_click",
    studio_click: {
      button_type: "button_main",
      button_title: "bigc_service",
    },
  };

  club = {
    event: "studio_click",
    studio_click: {
      button_type: "button_main",
      button_title: "club",
    },
  };

  moment = {
    event: "studio_click",
    studio_click: {
      button_type: "button_main",
      button_title: "moment",
    },
  };

  guide = {
    event: "studio_click",
    studio_click: {
      button_type: "button_main",
      button_title: "guide",
    },
  };

  start = {
    event: "studio_click",
    studio_click: {
      button_type: "button_main",
      button_title: "start",
    },
  };

  serviceVideo = {
    event: "studio_click", // #이벤트 카테고리
    studio_click: {
      button_type: "button_move",
      button_title: "service_video",
    },
  };

  liveVideo = {
    event: "studio_click", // #이벤트 카테고리
    studio_click: {
      button_type: "button_move",
      button_title: "live_video",
    },
  };

  brandHome = {
    event: "studio_click",
    studio_click: {
      button_type: "button_list",
      button_title: "brandhome",
    },
  };

  themeLive = {
    event: "studio_click",
    studio_click: {
      button_type: "button_list",
      button_title: "theme_live",
    },
  };

  premiumContent = {
    event: "studio_click",
    studio_click: {
      button_type: "button_list",
      button_title: "premium_content",
    },
  };

  report = {
    event: "studio_click",
    studio_click: {
      button_type: "button_list",
      button_title: "report",
    },
  };

  community = {
    event: "studio_click",
    studio_click: {
      button_type: "button_list",
      button_title: "community",
    },
  };

  otherFeatures = {
    event: "studio_click",
    studio_click: {
      button_type: "button_list",
      button_title: "other_features",
    },
  };

  forFans = {
    event: "studio_click",
    studio_click: {
      button_type: "button_main",
      button_title: "for_fans'",
    },
  };

  plan = {
    event: "studio_click",
    studio_click: {
      button_type: "button_main",
      button_title: "plan'",
    },
  };

  previous = {
    event: "studio_click",
    studio_click: {
      button_type: "button_interview",
      button_title: "previous",
    },
  };

  next = {
    event: "studio_click",
    studio_click: {
      button_type: "button_interview",
      button_title: "next",
    },
  };

  creator(url) {
    return {
      event: "studio_click",
      studio_click: {
        button_type: "button_home",
        button_title: url,
      },
    };
  }

  push(eventObj) {
    window.dataLayer.push(eventObj);
  }
}

export default DatalayerEvents;
