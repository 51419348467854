import ApiService from "@/api";
import { CLUBS } from "../mutation-types";
import _ from "lodash";

const getDefaultState = () => {
  return {
    clubs: [],
    clubsMeta: {
      filters: {
        isTemporary: false,
        perPage: 15,
        q: "",
        status: 0,
        type: "",
      },
      planStatus: {}, // 2: "판매중", 4: "판매 종료", 8: "숨김"
      statuses: {}, // 1: "판매 예정", 2: "판매중", 4: "판매 종료", 8: "숨김"
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
      openClubCount: 0,
      saveClubCount: 0,
    },
    club: {
      allPlans: [],
      attachments: [],
      contentType: "",
      createdAt: "",
      description: "",
      faqs: [],
      finishAt: null,
      isTemporary: true,
      keywords: [],
      publishedAt: null,
      resourceId: "",
      salesCount: 0,
      salesPrice: 0,
      sessions: [],
      startAt: null,
      status: 8,
      statusLabel: "",
      tags: [],
      theme: {},
      title: "",
      type: 0,
      typeLabel: "",
      updatedAt: "",
      viewCount: 0,
    },
    clubMeta: {
      interestTags: [],
      displayTags: [],
    },
    clubMembers: [],
    clubMembersMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
    clubResourceId: "",
  };
};

const state = getDefaultState();

const getters = {
  newClubs: (state) => {
    return state.clubs;
  },
  newClubsMeta: (state) => {
    return state.clubsMeta;
  },
  newClub: (state) => {
    return state.club;
  },
  newClubMeta: (state) => {
    return state.clubMeta;
  },
  newClubMembers: (state) => {
    return state.clubMembers;
  },
  newClubMembersMeta: (state) => {
    return state.clubMembersMeta;
  },
  newClubSessions: (state) => {
    return state.club.sessions;
  },
  newClubFaqs: (state) => {
    return state.club.faqs;
  },
};

const actions = {
  resetClub({ commit }) {
    commit(CLUBS.RESET_CLUB);
  },
  async getClubs({ commit }, payload) {
    try {
      const res = await ApiService.getClubs(payload);
      if (res.data.success) {
        await commit(CLUBS.SET_CLUBS, res.data);
      } else {
        console.log("fail", res.data);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getClub({ commit }, payload) {
    await ApiService.getClub(payload.clubResourceId).then(async (res) => {
      if (res.data.success) {
        await commit(CLUBS.SET_CLUB, res.data);
      }
    });
  },
  getClubMembers({ commit }, payload) {
    ApiService.getClubMembers(payload.clubResourceId, payload.params).then(
      (res) => {
        if (res.data.success) {
          commit(CLUBS.SET_CLUBS_MEMBERS, res.data);
        }
      }
    );
  },
  async postClub({ dispatch }, payload) {
    await ApiService.postClubs(payload.data).then(async (res) => {
      if (res.data.success) {
        state.clubResourceId = res.data.data.resourceId;
        await dispatch("getClub", { clubResourceId: res.data.data.resourceId });
      }
    });
  },
  async postThemeClub({ dispatch }, payload) {
    await ApiService.postThemeClub(payload.data).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", { clubResourceId: res.data.data.resourceId });
      }
    });
  },
  async putClub({ dispatch }, payload) {
    await ApiService.putClub(payload.clubResourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getClub", { clubResourceId: payload.clubResourceId });
          // todo 수정시 전체 불러오는 방식 수정 필요. 이 api가 클럽 리스트 페이지에서도, 클럽 수정 페이지에서도 사용되고 있음
          // 클럽 수정 페이지에서 전체 클럽 리스트를 불러오는 건 올바르지 않음. 불 필요한 api 요청임
          dispatch("getClubs", state.clubsMeta.filters);
        }
      }
    );
  },
  deleteClub({ state, dispatch }, payload) {
    ApiService.deleteClub(payload.clubResourceId).then((res) => {
      if (res.data.success) {
        dispatch("getClubs", state.clubsMeta.filters);
      }
    });
  },
  async copyClub({ dispatch }, payload) {
    await ApiService.copyClub(payload.clubResourceId).then((res) => {
      if (res.data.success) {
        dispatch("getClubs", state.clubsMeta.filters);
      }
    });
  },
  async deleteClubFeaturedImage({ dispatch }, payload) {
    await ApiService.deleteClubFeaturedImage(payload.clubResourceId).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getClub", {
            clubResourceId: res.data.data.resourceId,
          });
        }
      }
    );
  },
  async postPlan({ dispatch }, payload) {
    await ApiService.postClubPlans(payload.clubResourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getClub", { clubResourceId: payload.clubResourceId });
        }
      }
    );
  },
  async putPlan({ dispatch }, payload) {
    await ApiService.putClubPlans(
      payload.clubResourceId,
      payload.planResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        dispatch("getClubs");
        await dispatch("getClub", { clubResourceId: payload.clubResourceId });
      }
    });
  },
  // 데이터 하나만 업데이트 할 때 사용
  async patchPlan({ dispatch }, payload) {
    await ApiService.patchClubPlans(
      payload.clubResourceId,
      payload.planResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        dispatch("getClubs");
        await dispatch("getClub", { clubResourceId: payload.clubResourceId });
      }
    });
  },
  async putSortPlans({ dispatch }, payload) {
    await ApiService.putClubPlansSort(
      payload.clubResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", { clubResourceId: payload.clubResourceId });
      }
    });
  },
  async deleteClubPlan({ dispatch }, payload) {
    await ApiService.deleteClubPlans(
      payload.clubResourceId,
      payload.planResourceId
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", { clubResourceId: payload.clubResourceId });
      }
    });
  },
  async postClubSessionContent({ dispatch }, payload) {
    await ApiService.postClubSessionContent(
      payload.clubResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", {
          clubResourceId: payload.clubResourceId,
        });
      }
    });
  },
  async postClubSessionLive({ dispatch }, payload) {
    await ApiService.postClubSessionLive(
      payload.clubResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", {
          clubResourceId: payload.clubResourceId,
        });
      }
    });
  },
  async putClubSessionContent({ dispatch }, payload) {
    await ApiService.putContent(payload.contentResourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getClub", {
            clubResourceId: payload.clubResourceId,
          });
        }
      }
    );
  },
  async putClubSessionMeeting({ dispatch }, payload) {
    await ApiService.putMeetings(payload.meetingResourceId, payload.data).then(
      async () => {
        await dispatch("getClub", {
          clubResourceId: payload.clubResourceId,
        });
      }
    );
  },
  async postMeetingFeaturedImageUpload({ dispatch }, payload) {
    await ApiService.postMeetingFeaturedImageUpload(
      payload.meetingResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", {
          clubResourceId: payload.clubResourceId,
        });
      }
    });
  },
  async postClubSessionContentConnect({ dispatch }, payload) {
    await ApiService.postClubSessionContentConnect(
      payload.clubResourceId,
      payload.data
    );
  },
  async postClubSessions({ dispatch }, payload) {
    await ApiService.postClubSessions(payload.clubResourceId, payload.data);
  },
  deleteSessionContent({ dispatch }, payload) {
    ApiService.deleteClubContent(
      payload.clubResourceId,
      payload.contentResourceId
    )
      .then((res) => {
        if (res.data.success) {
          dispatch("getClub", {
            clubResourceId: payload.clubResourceId,
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },
  deleteSessionMeeting({ dispatch }, payload) {
    ApiService.deleteClubMeetings(
      payload.clubResourceId,
      payload.meetingResourceId
    ).then((res) => {
      if (res.data.success) {
        dispatch("getClub", {
          clubResourceId: payload.clubResourceId,
        });
      }
    });
  },
  async sortSessions({ dispatch }, payload) {
    await ApiService.putSessionSort(payload.clubResourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getClub", {
            clubResourceId: payload.clubResourceId,
          });
        }
      }
    );
  },
  async postClubFaq({ dispatch }, payload) {
    await ApiService.postClubFaq(payload.data).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", { clubResourceId: payload.clubResourceId });
      }
    });
  },
  async putClubFaq({ dispatch }, payload) {
    await ApiService.putClubFaq(payload.resourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          await dispatch("getClub", { clubResourceId: payload.clubResourceId });
        }
      }
    );
  },
  async putClubFaqSort({ dispatch }, payload) {
    await ApiService.putClubFaqOrder(payload.data).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", { clubResourceId: payload.clubResourceId });
      }
    });
  },
  async deleteClubFaq({ dispatch }, payload) {
    await ApiService.deleteClubFaq(payload.resourceId).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", { clubResourceId: payload.clubResourceId });
      }
    });
  },
  async updateClubMemberRole({ dispatch }, payload) {
    await ApiService.putClubMemberRole(
      payload.clubResourceId,
      payload.userResourceId,
      payload.params
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getClub", { clubResourceId: payload.clubResourceId });
        await dispatch("getClubMembers", {
          clubResourceId: payload.clubResourceId,
        });
      }
    });
  },
};

const mutations = {
  [CLUBS.RESET_CLUB](state) {
    Object.assign(state, getDefaultState());
  },
  [CLUBS.SET_CLUBS](state, payload) {
    // todo 코드 정리
    let meta = payload.meta;
    meta.filters.type === null
      ? (meta.filters.type = "")
      : (meta.filters.type = Number(meta.filters.type));

    if (meta.filters.q === null) {
      meta.filters.q = "";
    }

    state.clubs = _.cloneDeep(payload.data);
    state.clubsMeta = _.cloneDeep(meta);
  },
  [CLUBS.SET_CLUB](state, payload) {
    state.club = _.cloneDeep(payload.data);
    state.clubMeta = _.cloneDeep(payload.meta);
  },
  [CLUBS.SET_CLUBS_MEMBERS](state, payload) {
    state.clubMembers = _.cloneDeep(payload.data);
    state.clubMembersMeta = _.cloneDeep(payload.meta);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
