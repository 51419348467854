import ApiService from "@/api";
import { CHATS } from "../mutation-types";
import _ from "lodash";
import RtmClient from "@/services/AgoraRtmClient";

const getDefaultState = () => {
  return {
    chats: [],
    chat: {
      badgeCount: 0,
      createdAt: "",
      description: null,
      featuredImage: null,
      guest: {},
      host: {},
      latestMessage: {},
      notice: "",
      noticeHtml: "",
      resourceId: "",
      status: 1,
      title: "",
    },
    messages: [],
    rtmAuth: {
      appId: null,
      uid: null,
      token: null,
      channelName: null,
    },
    isRtm: false,
    isRtmDisconnected: false,
  };
};

const state = getDefaultState();
let rtmClient;

const getters = {
  chats: (state) => {
    return state.chats;
  },
  chat: (state) => {
    return state.chat;
  },
  messages: (state) => {
    return state.messages;
  },
  isRtmDisconnected: (state) => {
    return state.isRtmDisconnected;
  },
};

const actions = {
  resetChats({ commit }) {
    commit(CHATS.RESET_CHATS);
  },
  async getChats({ commit }) {
    await ApiService.getChats().then((res) => {
      if (res.data.success) {
        commit(CHATS.SET_CHATS, res.data);
      }
    });
  },
  resetChat({ commit }) {
    commit(CHATS.RESET_CHAT);
  },
  async getChat({ commit }, payload) {
    await ApiService.getChat(`${payload.chatResourceId}`).then((res) => {
      if (res.data.success) {
        commit(CHATS.SET_CHAT, res.data);
        commit(CHATS.UPDATE_CHATS, res.data);
      }
    });
  },
  resetChatView() {
    console.log("reset-chat-view");
  },
  async getChatMessages({ commit }, payload) {
    await ApiService.getChatMessages(`${payload.chatResourceId}`).then(
      (res) => {
        if (res.data.success) {
          commit(CHATS.SET_MESSAGES, res.data);
        }
      }
    );
  },

  async postChatMessages({ commit, dispatch }, payload) {
    await ApiService.postChatMessages(
      `${payload.chatResourceId}`,
      payload.data
    ).then((res) => {
      commit(CHATS.UPDATE_MESSAGES, res.data);
      dispatch("rtmSendMessage", res.data.data);
      dispatch("getChatMessages", payload);
      dispatch("getChat", payload);
    });
  },
  async postChatUpload({ commit, dispatch }, payload) {
    await ApiService.postChatUpload(
      `${payload.chatResourceId}`,
      payload.data
    ).then((res) => {
      commit(CHATS.UPDATE_MESSAGES, res.data);
      dispatch("rtmSendMessage", res.data.data);
      dispatch("getChatMessages", payload);
      dispatch("getChat", payload);
    });
  },
  async postChatsView({ commit }, payload) {
    await ApiService.postChatsView(`${payload.chatResourceId}`);
  },
  async startRtm({ commit, dispatch }, payload) {
    await dispatch("rtmJoin", payload.chatResourceId);
  },
  async rtmJoin({ dispatch, commit }, chatResourceId) {
    const chatResourceIddata = chatResourceId;
    rtmClient = new RtmClient();
    const payload = {
      uidType: "uid",
    };
    await ApiService.postChatRtmAuth(chatResourceIddata, payload).then(
      async (response) => {
        if (response.data.success) {
          state.rtmAuth = response.data.data.result;
        }
        await rtmClient.createClient({ appId: state.rtmAuth.appId });
        await rtmClient.login(state.rtmAuth.uid, state.rtmAuth.token);
        await rtmClient.join(state.rtmAuth.channelName).then(() => {
          state.isRtm = true;
          commit(CHATS.UPDATE_IsRtmDisconnected, { isRtmDisconnected: false });
        });

        rtmClient.onChannel(
          "ChannelMessage",
          (message, memberId, messagePros) => {
            console.log("message, memberId, messagePros");
            console.log(message);
            console.log(message.text);
            console.log(memberId);
            console.log(messagePros);

            const messageTextObject = JSON.parse(message.text);
            const messageData = messageTextObject.messageData;
            const messageType = messageTextObject.messageType;
            console.log("ChannelMessage ==================" + messageType);
            console.log(messageData);

            if (messageType === "chatMessage" || messageType === undefined) {
              if (!messageData.isBlocked && !messageData.isMuted) {
                dispatch("pushChatMessage", messageData);
                dispatch("getChatMessages", {
                  chatResourceId: chatResourceIddata,
                });
                dispatch("getChat", { chatResourceId: chatResourceIddata });
              }
            }
          }
        );
        rtmClient.onClient("ConnectionStateChanged", (newState, reason) => {
          console.log(newState, reason);
          if (newState === "ABORTED" || newState === "DISCONNECTED") {
            console.log(reason);
            commit(CHATS.UPDATE_IsRtmDisconnected, { isRtmDisconnected: true });
          }
        });
      }
    );
  },
  async pushChatMessage({ dispatch }, chatMessage) {
    const needleChatMessageResourceId = chatMessage.resourceId;
    let index = state.messages.findIndex((chatMessage) => {
      return chatMessage.resourceId === needleChatMessageResourceId;
    });

    if (index === -1) {
      // this.chatMessages.push(chatMessage);
      console.log("index === -1", index);
    } else {
      state.messages[index] = chatMessage;
    }
  },
  async rtmSendMessage({ commit }, chatMessage) {
    const payload = {
      messageData: chatMessage,
      messageType: "chatMessage",
    };
    await rtmClient.sendMessage(payload);
  },
  async logoutChat({ commit }, payload) {
    if (rtmClient) {
      await rtmClient.leave();
    }
    await ApiService.postChatsView(`${payload.chatResourceId}`);
  },
};

const mutations = {
  [CHATS.RESET_CHATS](state) {
    Object.assign(state, getDefaultState());
  },
  [CHATS.SET_CHATS](state, payload) {
    state.chats = _.cloneDeep(payload.data);
  },
  [CHATS.UPDATE_CHATS](state, payload) {
    let resourceId = payload.data.resourceId;
    let findInex = state.chats.findIndex((item) => {
      return item.resourceId === resourceId;
    });
    state.chats[findInex] = _.cloneDeep(payload.data);
  },
  [CHATS.SET_CHAT](state, payload) {
    state.chat = _.cloneDeep(payload.data);
  },
  // [CHATS.RESET_CHAT](state) {},
  [CHATS.SET_MESSAGES](state, payload) {
    state.messages = _.cloneDeep(payload.data);
  },
  [CHATS.UPDATE_MESSAGES](state, payload) {
    state.messages[state.messages.length] = payload.data;
    // state.messages = _.cloneDeep(payload.data);
  },
  [CHATS.UPDATE_IsRtmDisconnected](state, payload) {
    state.isRtmDisconnected = payload.isRtmDisconnected;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
