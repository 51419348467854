<template>
  <base-modal-new
    sub-title="곧 프로님의 오픈아워 세션이 시작될 예정입니다. "
    @closeModal="actions.closeModal()"
  >
    <template #modalTitle>
      <h3 class="modal-title">
        프로님의 오픈아워 세션 시작을 위해<br class="tablet" />앱으로
        접속해주세요
      </h3>
    </template>
    <template #modalBody>
      <div class="reservation">
        <div class="detail-info">
          <div class="label">예약 일자</div>
          <div class="vr"></div>
          <div class="time-tile">
            <div class="time">
              <span>TODAY</span>
              <span>{{ state.startMeeting }}</span>
            </div>
            <div class="h5 title">
              {{ title }}
            </div>
          </div>
        </div>
        <div class="user-info">
          <div class="label">예약 멤버</div>
          <div class="vr"></div>
          <div class="name">{{ scheduleUserName }}</div>
        </div>
      </div>
      <button-basic
        v-if="state.isAdmin"
        class="sso-btn"
        text="바로 입장하기"
        @action="actions.onBtnSSO()"
      ></button-basic>
    </template>
  </base-modal-new>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import BaseModalNew from "@/components/common/BaseModalNew";
import moment from "moment-timezone";
import { useStore } from "vuex";
import SsoService from "@/services/SsoService";
import ButtonBasic from "@/components/console/buttons/ButtonBasic";
export default defineComponent({
  name: "LectureAlertModal",
  components: { BaseModalNew, ButtonBasic },
  props: {
    title: {
      type: String,
      default: "",
    },
    scheduledAt: {
      type: String,
      default: "",
    },
    scheduleUserName: {
      type: String,
      default: "",
    },
    upcomingLectureMeetingId: {
      type: String,
      default: "",
    },
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      isChecked: false,
      startMeeting: computed(() => {
        return moment(props.scheduledAt).format("hh:mm");
      }),
      isAdmin: computed(() => {
        return (
          store.getters["auth/user"].isAdmin &&
          store.getters["auth/user"].isStaff
        );
      }),
    });

    const actions = {
      closeModal: () => {
        emit("closeModal", state.isChecked);
      },
      onBtnSSO: () => {
        const liveUrl = process.env.VUE_APP_LIVE_URL;
        const redirect = `${liveUrl}/live/${props.upcomingLectureMeetingId}`;
        const ssoEndPoint = `${liveUrl}/auth/sso`;

        SsoService.redirect(ssoEndPoint, redirect);
      },
    };

    return { state, actions };
  },
});
</script>

<style src="./style.css" scoped></style>
