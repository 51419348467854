<template>
  <div class="avatar-with__name">
    <avatar
      :avatar-img="state.avatarImage"
      :style="{ width: imgWidth }"
    ></avatar>
    <span class="sub-text-s1">{{ user.name }}</span>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive } from "vue";
import Avatar from "./Avatar";
export default {
  name: "AvatarWithName",
  components: { Avatar },
  props: {
    user: {
      type: Object,
      required: true,
    },
    imgWidth: {
      type: String,
      default: "36px",
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      avatarImage: computed(() => {
        return props.user.avatar ?? proxy.$const.blankImage;
      }),
    });

    return { state };
  },
};
</script>

<style scoped>
.avatar-with__name {
  display: flex;
  align-items: center;
  gap: 12px;
}
</style>
