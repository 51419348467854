import axios from "axios";

const bitlyApiDomain = "https://api-ssl.bitly.com/v4";

class bitly {
  async shortenUrl(url) {
    const group_guid = await this.bitlyGroupId(); // or you can run the function and paste in the group_guid here

    const config = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_BITLY_ACCESS_TOKEN}`,
        "Content-Type": "application/json",
      },
    };

    const bodyParameters = {
      long_url: url,
      domain: "go.bigc.im",
      group_guid: group_guid,
    };

    let { status, data } = await axios.post(
      `${bitlyApiDomain}/shorten`,
      bodyParameters,
      config
    );

    if (status === 200 || status === 201) {
      return data.link;
    }

    return null;
  }

  async bitlyGroupId() {
    let accessToken = process.env.VUE_APP_BITLY_ACCESS_TOKEN; // paste in your access token from https://bitly.is/accesstoken
    let config = { headers: { Authorization: "Bearer " + accessToken } };
    let response = await axios.get(`${bitlyApiDomain}/groups`, config);

    if (response.status === 200) {
      return response.data.groups[0].guid;
    } else {
      return null;
    }
  }
}

export default new bitly();
