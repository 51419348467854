
import AppLayout from "@/layouts/AppLayout.vue";
import { useStore } from "vuex";
import { onBeforeUnmount, defineComponent } from "vue";

export default defineComponent({
  components: {
    AppLayout,
  },
  setup() {
    const store = useStore();

    onBeforeUnmount(async () => {
      let isRemember = store.getters["auth/isRemember"];
      if (!isRemember) {
        await store.dispatch("auth/logout");
      }
    });
  },
});
