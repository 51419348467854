export const AUTH = {
  SAVE_TOKEN: "SAVE_TOKEN",
  LOGOUT: "LOGOUT",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_PHONE: "UPDATE_USER_PHONE",
  SET_OPEN_WELCOME_MODAL_SESSION: "SET_OPEN_WELCOME_MODAL_SESSION",
};

export const LOCALE = {
  SAVE_LOCALE: "SAVE_LOCALE",
  CHANGE_LOCALE: "CHANGE_LOCALE",
  FETCH_TRANSLATIONS: "FETCH_TRANSLATIONS",
  RESET_LOCALE: "RESET_LOCALE",
};

export const NOTIFICATIONS = {
  RESET_NOTIFICATIONS: "RESET_NOTIFICATIONS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
  INCREASE_COUNT: "INCREASE_COUNT",
  RESET_COUNT: "RESET_COUNT",
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
  FETCH_NOTIFICATIONS_FAILURE: "FETCH_NOTIFICATIONS_FAILURE",
};

export const DASHBOARD = {
  RESET_DASHBOARD: "RESET_DASHBOARD",
  GET_DASHBOARD: "GET_DASHBOARD",
  GET_DASHBOARD_GRAPH_DATA: "GET_DASHBOARD_GRAPH",
  SET_CONNECTS: "SET_CONNECTS",
  SET_ARTICLES: "SET_ARTICLES",
};

export const CHANNELS = {
  SET_CHANNELS: "SET_CHANNELS",
  UPDATE_CHANNELS: "UPDATE_CHANNELS",
  ADD_CHANNELS: "ADD_CHANNELS",
  POP_CHANNELS: "POP_CHANNELS",
  UPDATE_CHANNEL: "UPDATE_CHANNEL",
  RESET_CHANNELS: "RESET_CHANNELS",
  SET_CONST_CHANNEL: "SET_CONST_CHANNEL",
};

export const CONTENTS = {
  RESET_CONTENTS: "RESET_CONTENTS",
  SET_CONTENTS: "SET_CONTENTS",
  SEARCH_CONTENTS: "SEARCH_CONTENTS",
  FIND_SET_CONTENT: "FIND_SET_CONTENT",
  UPDATE_CONTENT: "UPDATE_CONTENT",
  CONTENT_ADD_ATTACHMENT: "CONTENT_ADD_ATTACHMENT",
  CONTENT_POP_ATTACHMENT: "CONTENT_POP_ATTACHMENT",
  CONTENT_ADD_CAPTION: "CONTENT_ADD_CAPTION",
  CONTENT_POP_CAPTION: "CONTENT_POP_CAPTION",
  UPDATE_CONTENT_SUBTITLES: "UPDATE_CONTENT_SUBTITLES",
  CONTENT_ADD_MULTIPART_FILES: "CONTENT_ADD_MULTIPART_FILES",
  CONTENT_UPDATE_MULTIPART_FILE: "CONTENT_UPDATE_MULTIPART_FILE",
  CONTENT_POP_MULTIPART_FILE: "CONTENT_POP_MULTIPART_FILE",
  CONTENT_CANCEL_UPLOADING_MULTIPART: "CONTENT_CANCEL_UPLOADING_MULTIPART",
  FETCH_CONTENTS: "FETCH_CONTENTS",
  CONTENT_RESET: "CONTENT_RESET",
};

export const MEETINGS = {
  RESET_MEETINGS: "RESET_MEETINGS",
  GET_MEETINGS: "GET_MEETINGS",
  FETCH_MEETINGS: "FETCH_MEETINGS",
  GET_MEETING: "GET_MEETING",
  UPDATE_MEETING: "UPDATE_MEETING",
  RESET_MEETING: "RESET_MEETING",
  POP_MEETING: "POP_MEETING",
  UPDATE_MEETING_PUBLISH: "UPDATE_MEETING_PUBLISH",
  UPDATE_MEETING_STATUS: "UPDATE_MEETING_STATUS",
  UPDATE_MEETING_FEATURED_IMAGE: "UPDATE_MEETING_FEATURED_IMAGE",
  SET_UPCOMING_MEETING: "SET_UPCOMING_MEETING",
  RESET_UPCOMING_MEETING: "RESET_UPCOMING_MEETING",
  SET_MEETING_USERS: "SET_MEETING_USERS",
  ADD_MEETING_USERS: "ADD_MEETING_USERS",
  POP_MEETING_USER: "POP_MEETING_USER",
  UPDATE_MEETING_USER: "UPDATE_MEETING_USER",
};

export const ARTICLES = {
  RESET_ARTICLES: "RESET_ARTICLES",
  SET_ARTICLES: "SET_ARTICLES",
  SET_ARTICLE: "SET_ARTICLE",
  FIND_SET_ARTICLE: "FIND_SET_ARTICLE",
  FETCH_ARTICLES: "FETCH_ARTICLES",
  POP_ARTICLE_ATTACHMENTS: "POP_ARTICLE_ATTACHMENTS",
  UPDATE_ARTICLE_PREV_LIKE: "UPDATE_ARTICLE_PREV_LIKE",
  SET_CLUB_ARTICLES: "SET_CLUB_ARTICLES",
  ADD_CLUB_ARTICLE: "ADD_CLUB_ARTICLE",
  FETCH_CLUB_ARTICLES: "FETCH_CLUB_ARTICLES",
  FIND_SET_CLUB_ARTICLE: "FIND_SET_CLUB_ARTICLE",
  POP_CLUB_ARTICLE: "POP_CLUB_ARTICLE",
  SET_CLUB_ARTICLE: "SET_CLUB_ARTICLE",
  POP_CLUB_ARTICLE_ATTACHMENTS: "POP_CLUB_ARTICLE_ATTACHMENTS",
  UPDATE_CLUB_ARTICLE_PREV_LIKE: "UPDATE_CLUB_ARTICLE_PREV_LIKE",
  UPDATE_CLUB_ARTICLE_LIKE: "UPDATE_CLUB_ARTICLE_LIKE",
  ADD_ARTICLES: "ADD_ARTICLES",
};

export const COMMUNITIES = {
  SET_COMMUNITIES: "SET_COMMUNITIES",
  SORT_COMMUNITIES: "SORT_COMMUNITIES",
  SET_COMMUNITY: "SET_COMMUNITY",
  RESET_COMMUNITY: "RESET_COMMUNITY",
  RESET_COMMUNITIES: "RESET_COMMUNITIES",
};

export const CLUBS = {
  SET_CLUBS_MEMBERS: "SET_CLUBS_MEMBERS",
  RESET_CLUB: "RESET_CLUB",
  SET_CLUBS: "SET_CLUBS",
  SET_CLUB: "SET_CLUB",
  SET_CLUBS_DASHBOARD: "SET_CLUBS_DASHBOARD",
  UPDATE_CLUB: "UPDATE_CLUB",
  POP_CLUB: "POP_CLUB",
  FIND_SET_CLUB: "FIND_SET_CLUB",
  ADD_CLUB_SECTION_CHAPTER: "ADD_CLUB_SECTION_CHAPTER",
  ADD_CLUB_DESCRIPTION: "ADD_CLUB_DESCRIPTION",
  UPDATE_CLUB_DESCRIPTION: "UPDATE_CLUB_DESCRIPTION",
  POP_CLUB_DESCRIPTION: "POP_CLUB_DESCRIPTION",
  SORT_CLUB_DESCRIPTION: "SORT_CLUB_DESCRIPTION",
  POP_CLUB_SECTION_CHAPTER: "POP_CLUB_SECTION_CHAPTER",
  ADD_CLUB_REVIEW: "ADD_CLUB_REVIEW",
  POP_CLUB_REVIEW: "POP_CLUB_REVIEW",
  UPDATE_CLUB_REVIEW: "UPDATE_CLUB_REVIEW",
  SORT_CLUB_REVIEW: "SORT_CLUB_REVIEW",
  ADD_CLUB_FAQ: "ADD_CLUB_FAQ",
  POP_CLUB_FAQ: "POP_CLUB_FAQ",
  UPDATE_CLUB_FAQ: "UPDATE_CLUB_FAQ",
  SORT_CLUB_FAQ: "SORT_CLUB_FAQ",
  SORT_CLUB_SECTION_CHAPTER: "SORT_CLUB_SECTION_CHAPTER",
  UPDATE_CLUB_FAQS_ORDER: "UPDATE_CLUB_FAQS_ORDER",
  CLUB_MEMBER_ROLE_UPDATE: "CLUB_MEMBER_ROLE_UPDATE",
};

export const CLUB = {
  GET_CLUB: "GET_CLUB",
  RESET_CLUB: "RESET_CLUB",
};

export const COMMENTS = {
  RESET_COMMENTS: "RESET_COMMENTS",
  ADD_ARTICLE_COMMENT: "ADD_ARTICLE_COMMENT",
  SET_ARTICLE_COMMENTS: "SET_ARTICLE_COMMENTS",
  RESET_ARTICLE_COMMENTS: "RESET_ARTICLE_COMMENTS",
  POP_ARTICLE_COMMENT: "POP_ARTICLE_COMMENT",
  POP_ARTICLE_COMMENT_COMMENT: "POP_ARTICLE_COMMENT_COMMENT",
  UPDATE_ARTICLE_COMMENT_PREV_LIKE: "UPDATE_ARTICLE_COMMENT_PREV_LIKE",
  SET_CONTENT_COMMENTS: "SET_CONTENT_COMMENTS",
  ADD_CONTENT_COMMENTS: "ADD_CONTENT_COMMENTS",
  UPDATE_CONTENT_COMMENT: "UPDATE_CONTENT_COMMENT",
  POP_CONTENT_COMMENT: "POP_CONTENT_COMMENT",
  UPDATE_CONTENT_COMMENT_PREV_LIKE: "UPDATE_CONTENT_COMMENT_PREV_LIKE",
  UPDATE_CONTENT_COMMENT_LIKE: "UPDATE_CONTENT_COMMENT_LIKE",
  ADD_CONTENT_COMMENT_COMMENT: "ADD_CONTENT_COMMENT_COMMENT",
  UPDATE_CONTENT_COMMENT_COMMENT: "UPDATE_CONTENT_COMMENT_COMMENT",
  POP_CONTENT_COMMENT_COMMENT: "POP_CONTENT_COMMENT_COMMENT",
  UPDATE_COMMENT_COMMENT_PREV_LIKE: "UPDATE_COMMENT_COMMENT_PREV_LIKE",
  UPDATE_COMMENT_COMMENT_LIKE: "UPDATE_COMMENT_COMMENT_LIKE",
};

export const PLANS = {
  RESET_PLANS: "RESET_PLANS",
  RESET_PLAN: "RESET_PLAN",
  SET_PLANS: "SET_PLANS",
  SET_PLAN: "SET_PLAN",
  FETCH_PLANS: "FETCH_PLANS",
  ADD_PLAN: "ADD_PLAN",
  POP_PLAN: "POP_PLAN",
};

export const CONNECTS = {
  SET_CONNECTS: "SET_CONNECTS",
  RESET_CONNECTS: "RESET_CONNECTS",
};

export const SPACE = {
  SET_SPACE: "SET_SPACE",
  SET_SPACE_TYPE: "SET_SPACE_TYPE",
  SET_SPACE_CHANNELS: "SET_SPACE_CHANNELS",
  POP_SPACE_WIDGET: "POP_SPACE_WIDGET",
  SET_SPACE_WIDGETS: "SET_SPACE_WIDGETS",
};

export const CLIPBOARDS = {
  SET_CLIPBOARD: "SET_CLIPBOARD",
};

export const ONBOARDINGS = {
  SET_STEP: "SET_STEP",
  UPDATE_STEP: "UPDATE_STEP",
  UPDATE_HANDLING_STEP: "UPDATE_HANDLING_STEP",
  SET_ALIAS: "SET_ALIAS",
  UPDATE_ALIAS: "UPDATE_ALIAS",
  ADD_PROFILE: "ADD_PROFILE",
  SET_INTEREST: "SET_INTEREST",
  UPDATE_INTEREST: "ADD_INTEREST",
};

export const MEMBERSHIPS = {
  SET_MEMBERSHIPS: "SET_MEMBERSHIPS",
};

export const NOTICES = {
  SET_NOTICES: "SET_NOTICES",
};

export const LECTURES = {
  SET_LECTURES: "SET_LECTURES",
  SET_LECTURE: "SET_LECTURE",
  UPDATE_LECTURE: "UPDATE_LECTURE",
  SET_LECTURE_SCHEDULES: "SET_LECTURE_SCHEDULES",
  DELETE_LECTURE_SCHEDULE: "DELETE_LECTURE_SCHEDULE",
  SET_LECTURE_CATEGORIES: "SET_LECTURE_CATEGORIES",
  SET_LECTURE_TOPICS: "SET_LECTURE_TOPICS",
  SET_LECTURE_QUESTIONS: "SET_LECTURE_QUESTIONS",
  SET_LECTURE_REVIEWS: "SET_LECTURE_REVIEWS",
  SET_LECTURES_RESERVATIONS: "SET_LECTURES_RESERVATIONS",
  SET_LECTURES_COMMUNITIES: "SET_LECTURES_COMMUNITIES",
  SET_LECTURES_TAGS: "SET_LECTURES_TAGS",
  SET_LECTURE_SCHEDULE_QNA: "SET_LECTURE_SCHEDULE_QNA",
  SET_LECTURES_RESERVATION_COUNTS: "SET_LECTURES_RESERVATION_COUNTS",
  SET_LECTURE_SCHEDULE_FEEDBACK: "SET_LECTURE_SCHEDULE_FEEDBACK",
  SET_LECTURE_ARTICLES: "SET_LECTURE_ARTICLES",
  SET_UPCOMING_LECTURE: "SET_UPCOMING_LECTURE",
  SET_LECTURE_ARTICLE_COMMUNITY_COMMENTS:
    "SET_LECTURE_ARTICLE_COMMUNITY_COMMENTS",
  ADD_LECTURE_ARTICLE_COMMUNITY_COMMENTS:
    "ADD_LECTURE_ARTICLE_COMMUNITY_COMMENTS",
  UPDATE_LECTURE_ARTICLE_COMMUNITY_COMMENTS:
    "UPDATE_LECTURE_ARTICLE_COMMUNITY_COMMENTS",
  POP_LECTURE_ARTICLE_COMMUNITY_COMMENT:
    "POP_LECTURE_ARTICLE_COMMUNITY_COMMENT",
};

export const FILES = {
  SET_UPLOADING_FILE: "SET_UPLOADING_FILE",
  UPDATE_UPLOADING_FILE_LOADED: "UPDATE_UPLOADING_FILE_LOADED",
  COMPLETE_UPLOADING: "COMPLETE_UPLOADING",
};

export const SETTLEMENTS = {
  SET_SETTLEMENTS: "SET_SETTLEMENTS",
  RESET_SETTLEMENTS: "RESET_SETTLEMENTS",
  SET_SETTLEMENT_DETAILS: "SET_SETTLEMENT_DETAILS",
  SET_SETTLEMENT_INFO: "SET_SETTLEMENT_INFO",
  SET_SALE_LIST: "SET_SALE_LIST",
  UPDATE_FILTER_MONTH_INDEX: "UPDATE_FILTER_MONTH_INDEX",
  UPDATE_FILTER_YEAR_INDEX: "UPDATE_FILTER_YEAR_INDEX",
  SET_SETTLEMENT_SUMMARY: "SET_SETTLEMENT_SUMMARY",
  SET_SETTLEMENT_BANKBOOK: "SET_SETTLEMENT_BANKBOOK",
  SET_SETTLEMENT_POINT: "SET_SETTLEMENT_POINT",
};

export const CHATS = {
  SET_CHATS: "SET_CHATS",
  UPDATE_CHATS: "UPDATE_CHATS",
  RESET_CHATS: "RESET_CHATS",
  SET_CHAT: "SET_CHAT",
  RESET_CHAT: "RESET_CHAT",
  SET_MESSAGES: "SET_MESSAGES",
  UPDATE_MESSAGES: "UPDATE_MESSAGES",
  UPDATE_IsRtmDisconnected: "UPDATE_IsRtmDisconnected",
};

export const POLLS = {
  SET_POLLS: "SET_POLLS",
  SET_POLLS_META: "SET_POLLS_META",
  ADD_POLL: "ADD_POLL",
  SET_POLL: "SET_POLL",
  UPDATE_POLL: "UPDATE_POLL",
  DELETE_POLL: "DELETE_POLL",
  SET_POLL_OPTIONS: "SET_POLL_OPTIONS",
  SET_POLL_OPTION: "SET_POLL_OPTION",
  UPDATE_POLL_OPTIONS: "UPDATE_POLL_OPTIONS",
  DELETE_POLL_OPTIONS: "DELETE_POLL_OPTIONS",
};
