import ApiService from "@/api";
import { PLANS } from "../mutation-types";
import _ from "lodash";
import moment from "moment-timezone";

const getDefaultState = () => {
  return {
    plans: [],
    plan: {
      contentExpiredDay: null,
      contents: [],
      createdAt: "",
      currency: null,
      description: "",
      featuredImage: null,
      finishAt: "",
      headline: "",
      id: 0,
      isAudio: false,
      isDelivery: false,
      isEarlyBird: false,
      isPrimary: false,
      isSaleable: true,
      isSubscription: false,
      isVIP: false,
      isVideo: false,
      limitCount: 0,
      listPrice: null,
      listPriceGoods: null,
      listPriceTicket: null,
      meetings: [],
      month: 0,
      monthExtra: 0,
      monthTotal: 0,
      orderCount: null,
      paymentOrderCount: 0,
      price: null,
      priceGoods: null,
      priceImage: "",
      priceImageMo: "",
      priceTicket: null,
      prices: {},
      resourceId: "",
      rewardPercentage: 0,
      salesableCount: null,
      sortNo: null,
      startAt: "",
      status: 0,
      ticketCount: 0,
      ticketCountExtra: 0,
      title: "",
      titleForPaymentOrder: "",
    },
  };
};
const state = getDefaultState();

const getters = {
  plans: (state) => {
    return state.plans;
  },
  plan: (state) => {
    return state.plan;
  },
  isOrderedPlan: (state) => {
    return state.plan.paymentOrderCount > 0;
  },
  sellingFinishedPlan: () => {
    return moment(state.plan.finishAt) < moment();
  },
};

const actions = {
  resetPlans({ commit }) {
    commit(PLANS.RESET_PLANS);
  },
  async resetPlan({ commit }) {
    await commit(PLANS.RESET_PLAN);
  },
  fetchPlans({ commit }, payload) {
    ApiService.getClubPlans(payload).then((res) => {
      if (res.data.success) {
        commit(PLANS.FETCH_PLANS, res.data.data);
      }
    });
  },
  async getPlans({ commit }, payload) {
    await ApiService.getClubPlans(payload.clubResourceId).then(async (res) => {
      if (res.data.success) {
        await commit(PLANS.SET_PLANS, res.data.data);
      }
    });
  },
  async getPlan({ commit }, payload) {
    await ApiService.getClubPlan(
      payload.clubResourceId,
      payload.planResourceId
    ).then(async (res) => {
      if (res.data.success) {
        await commit(PLANS.SET_PLAN, res.data.data);
      }
    });
  },
  async postPlan({ dispatch }, payload) {
    await ApiService.postClubPlans(payload.clubResourceId, payload.data).then(
      async (res) => {
        if (res.data.success) {
          // await commit(PLANS.ADD_PLAN, res.data.data);
          await dispatch("fetchPlans", payload.clubResourceId);
        }
      }
    );
  },
  async putPlan({ commit, dispatch }, payload) {
    await ApiService.putClubPlans(
      payload.clubResourceId,
      payload.planResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await commit(PLANS.SET_PLAN, res.data.data);
        dispatch("fetchPlans", payload.clubResourceId);
      }
    });
  },
  async patchPlan({ commit, dispatch }, payload) {
    await ApiService.patchClubPlans(
      payload.clubResourceId,
      payload.planResourceId,
      payload.data
    ).then((res) => {
      if (res.data.success) {
        commit(PLANS.SET_PLAN, res.data.data);
        dispatch("fetchPlans", payload.clubResourceId);
      }
    });
  },
  async deleteClubPlan({ commit }, payload) {
    ApiService.deleteClubPlans(
      payload.clubResourceId,
      payload.planResourceId
    ).then((res) => {
      if (res.data.success) {
        commit(PLANS.POP_PLAN, { planResourceId: payload.planResourceId });
      }
    });
  },
};

const mutations = {
  [PLANS.RESET_PLANS](state) {
    Object.assign(state, getDefaultState());
  },
  [PLANS.RESET_PLAN](state) {
    state.plan = { ...state.plan, ...getDefaultState().plan };
  },
  [PLANS.SET_PLANS](state, payload) {
    state.plans = payload;
  },
  [PLANS.FETCH_PLANS](state, payload) {
    state.plans = payload;
  },
  [PLANS.SET_PLAN](state, payload) {
    state.plan = { ..._.cloneDeep(state.plan), ..._.cloneDeep(payload) };
    const planIndex = state.plans.findIndex((plan) => {
      return plan.resourceId === payload.resourceId;
    });
    state.plans[planIndex] = payload;
  },
  [PLANS.ADD_PLAN](state, payload) {
    state.plans.push(payload);
  },
  [PLANS.POP_PLAN](state, payload) {
    const planIndex = state.plans.findIndex((item) => {
      return item.resourceId === payload.planResourceId;
    });
    state.plans.splice(planIndex, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
