<template>
  <icon-base
    icon-name="live-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
    :clip-path="clipPath"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 1V11H2V1H1ZM0.5 0C0.223858 0 0 0.223858 0 0.5V11.5C0 11.7761 0.223858 12 0.5 12H2.5C2.77614 12 3 11.7761 3 11.5V0.5C3 0.223858 2.77614 0 2.5 0H0.5ZM6 5V11H7V5H6ZM5.5 4C5.22386 4 5 4.22386 5 4.5V11.5C5 11.7761 5.22386 12 5.5 12H7.5C7.77614 12 8 11.7761 8 11.5V4.5C8 4.22386 7.77614 4 7.5 4H5.5ZM11 11V7H12V11H11ZM10 6.5C10 6.22386 10.2239 6 10.5 6H12.5C12.7761 6 13 6.22386 13 6.5V11.5C13 11.7761 12.7761 12 12.5 12H10.5C10.2239 12 10 11.7761 10 11.5V6.5Z"
      :fill="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "LiveIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 13 12",
    },
    width: {
      type: [Number, String],
      default: 13,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
    clipPath: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
