import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f616610"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-input" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "input-tag__wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "error-msg text-red-50 sub-text-s3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_prefix = _resolveComponent("input-prefix")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: $setup.state.icon,
      class: "channel-icon"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_input_prefix, {
        "default-value": $setup.state.channelAddress,
        "is-focus-out-event": true,
        onUpdateData: _cache[0] || (_cache[0] = (value) => $setup.actions.updateChannelUrl(value)),
        onSubmit: _cache[1] || (_cache[1] = ($event: any) => ($setup.actions.submitEvent()))
      }, null, 8, ["default-value"]),
      (!!$props.errorMassage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString($props.errorMassage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}