
import HeaderComponent from "./partials/Header/HeaderComponent.vue";
import FooterComponent from "./partials/Footer/FooterComponent.vue";
import { onBeforeMount, onUnmounted, defineComponent } from "vue";
export default defineComponent({
  name: "WebDefaultLayout",
  components: { HeaderComponent, FooterComponent },
  setup() {
    onBeforeMount(() => {
      document.getElementById("app").classList.add("web");
    });

    onUnmounted(() => {
      document.getElementById("app").classList.remove("web");
    });
  },
});
