import moment from "moment-timezone";
import dayjs from "dayjs";

export function dateFormat(date, format = "YYYY.MM.DD HH:mm z") {
  return date ? moment(date).tz(moment.tz.guess()).format(format) : "-";
}

export function dateDisplay(utc, format = "YYYY.MM.DD HH:mm") {
  const date = dayjs(utc);
  let result = "";
  if (format === "now") {
    // result = date.fromNow();
    result = dayjs(utc).format(format);
  } else {
    result = dayjs(utc).format(format);
  }

  return result;
}

// 타이머
export function diffDay(expiredAt) {
  const diff = expiredAt.diff(moment());

  const diffDay = String(Math.floor(diff / (1000 * 60 * 60 * 24)));
  const diffHour = String(Math.floor((diff / (1000 * 60 * 60)) % 24)).padStart(
    2,
    "0"
  );
  const diffMin = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(
    2,
    "0"
  );
  const diffSec = String(Math.floor((diff / 1000) % 60)).padStart(2, "0");

  return {
    day: diffDay,
    hour: diffHour,
    min: diffMin,
    sec: diffSec,
  };
}

export function getFastDate(date, compareDate) {
  return date.diff(compareDate) < 0 ? date : compareDate;
}
export function getLateDate(date, compareDate) {
  return date.diff(compareDate) > 0 ? date : compareDate;
}

export function savedAt(date) {
  let now = moment();
  let distDt = moment.duration(now.diff(date));

  if (distDt.asSeconds() < 60) {
    return `몇 초전`;
  }

  if (distDt.asMinutes() < 60) {
    return `${Math.floor(distDt.asMinutes())}분 전`;
  }

  if (distDt.asHours() < 24) {
    return `${Math.floor(distDt.asHours())}시간 전`;
  }

  if (distDt.asDays() < 8) {
    return `${Math.floor(distDt.asDays())}일 전`;
  }

  return moment(date).format("YYYY.MM.DD");
}

// 00:00:00 표기 함수
export function digitalWatch(time) {
  return Math.floor(time) > 9 ? Math.floor(time) : `0${Math.floor(time)}`;
}
