<template>
  <icon-base
    icon-name="share-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5053 4.74737C10.6288 4.99436 10.5286 5.2947 10.2817 5.41819L6.14695 7.48555C5.89996 7.60904 5.59962 7.50893 5.47613 7.26194C5.35263 7.01495 5.45274 6.71461 5.69973 6.59112L9.83444 4.52376C10.0814 4.40027 10.3818 4.50038 10.5053 4.74737Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.55259 8.7764C5.4291 9.02339 5.52921 9.32372 5.7762 9.44722L9.70706 11.4126C9.95405 11.5361 10.2544 11.436 10.3779 11.189C10.5014 10.942 10.4013 10.6417 10.1543 10.5182L6.22341 8.55278C5.97642 8.42929 5.67608 8.52941 5.55259 8.7764Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.75 5.5C12.4404 5.5 13 4.94036 13 4.25C13 3.55964 12.4404 3 11.75 3C11.0596 3 10.5 3.55964 10.5 4.25C10.5 4.94036 11.0596 5.5 11.75 5.5ZM11.75 6.5C12.9926 6.5 14 5.49264 14 4.25C14 3.00736 12.9926 2 11.75 2C10.5074 2 9.5 3.00736 9.5 4.25C9.5 5.49264 10.5074 6.5 11.75 6.5Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.75 13C12.4404 13 13 12.4404 13 11.75C13 11.0596 12.4404 10.5 11.75 10.5C11.0596 10.5 10.5 11.0596 10.5 11.75C10.5 12.4404 11.0596 13 11.75 13ZM11.75 14C12.9926 14 14 12.9926 14 11.75C14 10.5074 12.9926 9.5 11.75 9.5C10.5074 9.5 9.5 10.5074 9.5 11.75C9.5 12.9926 10.5074 14 11.75 14Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.25 9.25C4.94036 9.25 5.5 8.69036 5.5 8C5.5 7.30964 4.94036 6.75 4.25 6.75C3.55964 6.75 3 7.30964 3 8C3 8.69036 3.55964 9.25 4.25 9.25ZM4.25 10.25C5.49264 10.25 6.5 9.24264 6.5 8C6.5 6.75736 5.49264 5.75 4.25 5.75C3.00736 5.75 2 6.75736 2 8C2 9.24264 3.00736 10.25 4.25 10.25Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
export default {
  name: "ShareIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
