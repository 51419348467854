import ApiService from "@/api";
import { SETTLEMENTS } from "../mutation-types";
import { cloneDeep } from "lodash";

const getDefaultState = () => {
  return {
    settlements: [],
    settlementsMeta: {
      pagination: {
        currentPage: 1,
        perPage: 15,
        total: 1,
        lastPage: 1,
      },
    },
    settlementDetail: [],
    settlementDetailMeta: {
      currency: "",
      pagination: {
        currentPage: 1,
        perPage: 15,
        total: 0,
        lastPage: 0,
      },
      settlement: {
        detail: {
          payout: 0,
          amount: 0,
          pgFee: 0,
          serviceFee: 0,
          tax: 0,
          VAT: 0,
          point: 0,
        },
        bankInformation: {
          bank: null,
          bankAccount: null,
          bankAccountHolder: null,
        },
        information: {
          resourceId: "",
          settlementedAt: "",
          failReason: "",
          paidAt: null,
          status: "F",
          amount: 0,
          payout: 0,
        },
      },
    },
    settlementInfo: {
      appliedAt: null,
      bank: "",
      bankAccount: "",
      bankAccountHolder: "",
      bankbookCopyFile: "",
      businessCategory: null,
      businessAddress: "",
      businessCondition: null,
      businessName: null,
      failReason: null,
      id: 0,
      idFile: "",
      idNumber: "",
      name: "",
      resourceId: "",
      status: "",
      type: 0,
      managerName: null,
      managerPhone: null,
      managerEmail: null,
    },
    saleList: [],
    saleListMeta: {
      pagination: {
        currentPage: 1,
        perPage: 15,
        total: 0,
        lastPage: 0,
      },
    },
    filterMonthIndex: 0,
    filterYearIndex: 0,
    settlementSummary: {
      nonSettlementSalesAmount: {
        KRW: {
          currency: "KRW",
          amount: 0,
        },
      },
      totalPoint: 0,
      expectedSettlementAmount: {
        KRW: {
          currency: "KRW",
          amount: 0,
        },
      },
      beforeSettlementPayout: 0,
      totalSalesAmount: {
        KRW: {
          currency: "KRW",
          amount: 0,
        },
      },
    },
    settlementBankbook: [],
    settlementsBankbookMeta: {
      pagination: {
        currentPage: 1,
        perPage: 15,
        total: 0,
        lastPage: 0,
      },
    },
    settlementPoints: [],
    settlementPointsMeta: {
      pagination: {
        currentPage: 1,
        perPage: 15,
        total: 0,
        lastPage: 0,
      },
      savedPoint: 0,
      usablePoints: 0,
    },
  };
};

const state = getDefaultState();

const getters = {
  settlements: (state) => {
    return state.settlements;
  },
  settlementsMeta: (state) => {
    return state.settlementsMeta;
  },
  settlementDetail: (state) => {
    return state.settlementDetail;
  },
  settlementDetailMeta: (state) => {
    return state.settlementDetailMeta;
  },
  settlementInfo: (state) => {
    return state.settlementInfo;
  },
  existSettlementInfo: (state) => {
    return !!state.settlementInfo.resourceId;
  },
  saleList: (state) => {
    return state.saleList;
  },
  saleListMeta: (state) => {
    return state.saleListMeta;
  },
  filterMonthIndex: (state) => {
    return state.filterMonthIndex;
  },
  filterYearIndex: (state) => {
    return state.filterYearIndex;
  },
  settlementSummary: (state) => {
    return state.settlementSummary;
  },
  settlementBankbook: (state) => {
    return state.settlementBankbook;
  },
  settlementPoints: (state) => {
    return state.settlementPoints;
  },
  settlementPointsMeta: (state) => {
    return state.settlementPointsMeta;
  },
};

const actions = {
  resetSettlements: ({ commit }) => {
    commit(SETTLEMENTS.RESET_SETTLEMENTS);
  },
  async getSettlements({ commit }, payload) {
    await ApiService.getSettlements(payload).then(async (res) => {
      if (res.data.success) {
        await commit(SETTLEMENTS.SET_SETTLEMENTS, res.data);
      }
    });
  },
  async getSettlement({ commit }, payload) {
    await ApiService.getSettlement(
      payload.settlementResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await commit(SETTLEMENTS.SET_SETTLEMENT_DETAILS, res.data);
      }
    });
  },
  async getSettlementPoint({ commit }, payload) {
    await ApiService.getSettlementPoint().then(async (res) => {
      if (res.data.success) {
        await commit(SETTLEMENTS.SET_SETTLEMENT_POINT, res.data);
      }
    });
  },
  async setSettlementInfo({ commit, dispatch }, payload) {
    await ApiService.postSettlementInfo(payload).then(async (res) => {
      if (res.data.success) {
        await commit(SETTLEMENTS.SET_SETTLEMENT_INFO, res.data.data);
        await dispatch("getSettlementInfo");
      }
    });
  },
  async getSettlementInfo({ commit }) {
    await ApiService.getSettlementInfo().then(async (res) => {
      if (res.data.success) {
        await commit(SETTLEMENTS.SET_SETTLEMENT_INFO, res.data.data);
      }
    });
  },
  async getSettlementSales({ commit }, params) {
    await ApiService.getSettlementSales(params).then(async (res) => {
      if (res.data.success) {
        await commit(SETTLEMENTS.SET_SALE_LIST, res.data);
      }
    });
  },
  async getSettlementSummary({ commit }) {
    await ApiService.getSettlementSummary().then(async (res) => {
      if (res.data.success) {
        await commit(SETTLEMENTS.SET_SETTLEMENT_SUMMARY, res.data.data);
      }
    });
  },
  async getBankBookList({ commit }) {
    await ApiService.getSettlementBankbook().then(async (res) => {
      if (res.data.success) {
        await commit(SETTLEMENTS.SET_SETTLEMENT_BANKBOOK, res.data);
      }
    });
  },
  updateMonthIndex({ commit }, params) {
    commit(SETTLEMENTS.UPDATE_FILTER_MONTH_INDEX, params.direction);
  },
  updateYearIndex({ commit }, params) {
    commit(SETTLEMENTS.UPDATE_FILTER_YEAR_INDEX, params.direction);
  },
};

const mutations = {
  [SETTLEMENTS.RESET_SETTLEMENTS](state) {
    Object.assign(state, getDefaultState());
  },
  [SETTLEMENTS.SET_SETTLEMENTS](state, payload) {
    state.settlements = cloneDeep(payload.data);
    state.settlementsMeta = cloneDeep(payload.meta);
  },
  [SETTLEMENTS.SET_SETTLEMENT_DETAILS](state, payload) {
    state.settlementDetail = cloneDeep(payload.data);
    state.settlementDetailMeta = cloneDeep(payload.meta);
  },
  [SETTLEMENTS.SET_SETTLEMENT_INFO](state, payload) {
    state.settlementInfo = { ...state.settlementInfo, ...payload };
  },
  [SETTLEMENTS.SET_SALE_LIST](state, payload) {
    state.saleList = cloneDeep(payload.data);
  },
  [SETTLEMENTS.UPDATE_FILTER_MONTH_INDEX](state, payload) {
    state.filterMonthIndex += payload;
  },
  [SETTLEMENTS.UPDATE_FILTER_YEAR_INDEX](state, payload) {
    state.filterYearIndex += payload;
  },
  [SETTLEMENTS.SET_SETTLEMENT_SUMMARY](state, payload) {
    state.settlementSummary = { ...state.settlementSummary, ...payload };
    console.log(state.settlementSummary);
  },
  [SETTLEMENTS.SET_SETTLEMENT_BANKBOOK](state, payload) {
    state.settlementBankbook = cloneDeep(payload.data);
    state.settlementsBankbookMeta = cloneDeep(payload.meta);
  },
  [SETTLEMENTS.SET_SETTLEMENT_POINT](state, payload) {
    state.settlementPoints = cloneDeep(payload.data);
    state.settlementPointsMeta = cloneDeep(payload.meta);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
