import AgoraRTM from "agora-rtm-sdk";
import EventEmitter from "events";

export default class RtmClient {
  constructor() {
    this._logLevel = null;
    this._client = null;
    this._created = false;
    this._logined = false;

    this._channel = null;
    this._joined = false;
    this._leave = false;
    this._uid = 0;
    this._eventBus = new EventEmitter();
  }

  onClient(evt, callback) {
    this._client.on(evt, callback);
  }

  onChannel(evt, callback) {
    this._channel.on(evt, callback);
  }

  createClient(data) {
    if (this._client != null) {
      return this._client;
    }
    const appId = data.appId;
    // const config = {
    //   logFilter:
    //     process.env.NODE_ENV !== "production"
    //       ? "LOG_FILTER_OFF"
    //       : "LOG_FILTER_INFO",
    // };
    const config = {};
    this._client = AgoraRTM.createInstance(appId, config);
    this._created = true;
    return this._client;
  }

  login(uid, token) {
    return new Promise((resolve, reject) => {
      const options = {
        uid: uid,
        token: token,
      };
      this._client
        .login(options)
        .then(() => {
          this._logined = true;
          resolve();
        })
        .catch((e) => {
          this._logined = false;
          reject(e);
        });
    });
  }

  join(channelName) {
    return new Promise((resolve, reject) => {
      this._channel = this._client.createChannel(channelName);
      this._channel
        .join()
        .then(() => {
          this._joined = true;
          resolve();
        })
        .catch((e) => {
          this._joined = false;
          reject(e);
        });
    });
  }

  sendMessage(payload) {
    return new Promise((resolve, reject) => {
      const messagePayload = {
        text: JSON.stringify(payload),
      };
      this._channel
        .sendMessage(messagePayload)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  leave() {
    return new Promise((resolve, reject) => {
      if (this._leave === true) {
        resolve();
        return;
      }

      console.debug("leave()");
      this._leave = true;
      if (!this._client) return resolve();

      this._client
        .logout()
        .then(() => {
          console.log("logout() success");
          this._logined = false;
          this._uid = 0;
        })
        .catch((e) => {
          console.log("leave failed: " + e);
          reject(e);
        });

      this._channel
        .leave()
        .then(() => {
          console.log("leave() success");
          this._leave = true;
          this._uid = 0;
        })
        .catch((e) => {
          console.log("leave failed: " + e);
          reject(e);
        });
      resolve();
    });
  }
}
