<template>
  <icon-base
    icon-name="close-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.37519 3.65015C4.175 3.44995 3.85042 3.44995 3.65022 3.65015C3.45003 3.85034 3.45003 4.17492 3.65022 4.37512L7.27507 7.99996L3.65015 11.6249C3.44995 11.8251 3.44995 12.1497 3.65015 12.3498C3.85034 12.55 4.17492 12.55 4.37512 12.3498L8.00004 8.72493L11.6249 12.3498C11.8251 12.55 12.1497 12.55 12.3499 12.3498C12.55 12.1496 12.55 11.825 12.3499 11.6248L8.72501 7.99996L12.3498 4.37519C12.55 4.17499 12.55 3.85041 12.3498 3.65022C12.1496 3.45002 11.825 3.45002 11.6248 3.65022L8.00004 7.27499L4.37519 3.65015Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
export default {
  name: "CloseIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#818287",
    },
  },
};
</script>

<style scoped></style>
