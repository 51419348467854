<template>
  <icon-base
    icon-name="comment-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 12.4722V12.0252L4.66691 11.7271C3.6424 10.8101 3 9.48074 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8C13 10.7614 10.7614 13 8 13H5V12.4722ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 9.777 2.7725 11.3736 4 12.4722V13V14H5H8ZM6 8.5C6.27614 8.5 6.5 8.27614 6.5 8C6.5 7.72386 6.27614 7.5 6 7.5C5.72386 7.5 5.5 7.72386 5.5 8C5.5 8.27614 5.72386 8.5 6 8.5ZM8 8.5C8.27614 8.5 8.5 8.27614 8.5 8C8.5 7.72386 8.27614 7.5 8 7.5C7.72386 7.5 7.5 7.72386 7.5 8C7.5 8.27614 7.72386 8.5 8 8.5ZM10.5 8C10.5 8.27614 10.2761 8.5 10 8.5C9.72386 8.5 9.5 8.27614 9.5 8C9.5 7.72386 9.72386 7.5 10 7.5C10.2761 7.5 10.5 7.72386 10.5 8Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";

export default {
  name: "CommentIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
