<template>
  <div>
    <label v-if="label" class="label">
      <div>
        <span class="sub-text-s2">{{ label }}</span>
        <span v-if="required" class="text-purple-50">*</span>
      </div>
      <div v-if="subLabel" class="sub-text-s3 text-gray-second sub-label">
        <small>
          {{ subLabel }}
        </small>
      </div>
    </label>
    <div class="input-wrapper">
      <input
        class="b-text-1"
        :class="{ error: !!errorMessage }"
        :type="type"
        :value="defaultValue"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :disabled="disabled"
        @input="actions.updateData($event)"
        @blur="isFocusOutEvent ? actions.focusoutEvent() : ''"
        @keypress.enter="isSubmitEvent ? actions.submitEvent($event) : ''"
        @focus="isFocusEvent ? actions.focusEvent() : ''"
      />
    </div>
    <div
      v-if="errorMessage || caption"
      class="caption"
      :style="{ gridTemplateColumns: errorMessage && caption ? '1fr 1fr' : '' }"
    >
      <span v-if="!!errorMessage" class="error-message sub-text-s3 text-red-50">
        {{ errorMessage }}
      </span>
      <span v-if="!!caption" class="caption-txt sub-text-s3 text-gray-third">
        {{ caption }}
      </span>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "InputBasic",
  props: {
    defaultValue: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    subLabel: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
    caption: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "text",
    },
    maxLength: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    isFocusOutEvent: {
      type: Boolean,
      default: false,
    },
    isSubmitEvent: {
      type: Boolean,
      default: false,
    },
    isFocusEvent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateData", "focusOutEvent", "focusEvent", "submitEvent"],
  setup(props, { emit }) {
    const state = reactive({
      isSubmit: false,
    });

    const actions = {
      updateData: (e) => {
        emit(
          "updateData",
          props.type === "number" ? parseInt(e.target.value) : e.target.value
        );
      },
      focusoutEvent: () => {
        emit("focusOutEvent");
      },
      focusEvent: () => {
        if (props.isFocusEvent) {
          emit("focusEvent");
        }
      },
      submitEvent: (e) => {
        console.log("enter");
        if (e.target.value.trim()) {
          emit("submitEvent", e.target.value);
        }
        e.target.value = "";
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
.label {
  display: block;
  margin-bottom: 4px;
}

.caption {
  display: grid;
  margin-top: 8px;
}

.input-wrapper {
  position: relative;
}

.input-wrapper input {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 14px 16px;
  width: 100%;
  height: 48px;
}

.input-wrapper input::placeholder {
  color: #d0d5de;
}

.input-wrapper input:focus {
  border-color: #9ea0a6;
}

.input-wrapper input.error {
  border-color: #ff4646;
}

.input-wrapper input:disabled {
  background: #ecf1f4;
  border-color: #e6eaef;
  color: #d0d5de;
}

/*Chrome, Safari, Edge, Opera */
.input-wrapper input::-webkit-outer-spin-button,
.input-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-wrapper input[type="number"] {
  -moz-appearance: textfield;
}

.error-message {
  justify-self: start;
}

.caption-txt {
  justify-self: end;
}

.sub-label {
  margin-top: 4px;
}

.sub-label small {
  font-size: inherit;
}
</style>
