const webRoutes = [
  {
    path: "/",
    name: "web.home",
    meta: { requiresAuth: false, layout: "WebDefaultLayout" },
    component: () =>
      import(
        /* webpackChunkName: "web.home" */ "../pages/web/studioLanding/StudioLanding"
      ),
  },
  {
    path: "/about",
    name: "web.about",
    meta: { requiresAuth: false, layout: "WebDefaultLayout" },
    component: () =>
      import(
        /* webpackChunkName: "web.about" */ "../pages/web/studioLanding/StudioLanding"
      ),
  },
  // note 프로모션 페이지 사라짐 홈으로 리다이렉트
  {
    path: "/promotion",
    name: "web.promotion",
    meta: { requiresAuth: false, layout: "WebDefaultLayout" },
    beforeEnter: (to, from, next) => {
      // note 프로모션 페이지 임시 가림처리 -> 홈으로 redirect
      return next({ name: "web.home" });
    },
  },
  {
    path: "/pricing",
    name: "web.pricing",
    meta: { requiresAuth: false, layout: "WebDefaultLayout" },
    component: () =>
      import(
        /* webpackChunkName: "web.pricing" */ "../pages/web/pricingLanding/PricingLanding"
      ),
  },
  {
    path: "/enterprise",
    name: "web.enterprise",
    meta: { requiresAuth: false, layout: "WebDefaultLayout" },
    component: () =>
      import(
        /* webpackChunkName: "web.enterprise" */ "../pages/web/enterpriseLanding/EnterpriseLanding"
      ),
  },
  {
    path: "/club",
    name: "web.club",
    meta: { requiresAuth: false, layout: "WebDefaultLayout" },
    component: () =>
      import(
        /* webpackChunkName: "web.club" */ "../pages/web/clubLanding/ClubLanding"
      ),
  },
  {
    path: "/moment",
    name: "web.moment",
    meta: { requiresAuth: false, layout: "WebDefaultLayout" },
    component: () =>
      import(/* webpackChunkName: "web.moment" */ "../pages/web/MomentLanding"),
  },
];

export default webRoutes;
