<template>
  <button
    ref="buttonBasic"
    :class="{
      round: state.isRoundShape,
      reverse: iconPosition === 'front',
    }"
    :style="{
      backgroundColor: disabled ? '#ecf1f4' : bgColor,
      padding: padding,
      border: border ? `1px solid ${borderColor}` : `none`,
    }"
    :disabled="disabled"
    @mouseenter="actions.mouseOverEvent()"
    @mouseleave="actions.mouseLeaveEvent()"
    @click="actions.onBtnClick()"
  >
    <template v-if="!isLoading">
      <span
        v-if="!!text"
        :class="`sub-title-${textSize}`"
        :style="{ color: state.color }"
      >
        {{ text }}
      </span>
      <slot name="icon"></slot>
    </template>
    <template v-else>
      <loading-spinner-icon
        class="loading-spinner"
        :width="loadingIconWidth"
        :height="loadingIconHeight"
        :view-box="loadingIconviewBox"
        icon-color="none"
        clip-path="url(#clip0_19777_153569)"
      ></loading-spinner-icon>
    </template>
  </button>
</template>

<script>
import { computed, reactive, ref } from "vue";
import LoadingSpinnerIcon from "@/components/console/icons/LoadingSpinnerIcon";

export default {
  name: "ButtonBasic",
  components: { LoadingSpinnerIcon },
  props: {
    text: {
      type: String,
      default: "",
    },
    textSize: {
      type: String,
      default: "s1",
      validator(value) {
        return ["s1", "s2", "s3"].includes(value);
      },
    },
    shape: {
      type: String,
      default: "square",
      validator(value) {
        return ["square", "round"].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "#8E1EFF",
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
    border: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "14px 0",
    },
    hoverBgColor: {
      type: String,
      required: false,
    },
    hoverColor: {
      type: String,
      required: false,
    },
    iconPosition: {
      type: String,
      default: "back",
      validator(value) {
        return ["back", "front"].includes(value);
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingIconviewBox: {
      type: String,
      default: "0 0 24 24",
    },
    loadingIconWidth: {
      type: [Number, String],
      default: 24,
    },
    loadingIconHeight: {
      type: [Number, String],
      default: 24,
    },
  },
  emits: ["action"],
  setup(props, { emit }) {
    const buttonBasic = ref();
    const state = reactive({
      isRoundShape: props.shape === "round",
      color: computed(() => {
        if (props.disabled) {
          return "#d0d5de";
        }
        return props.color;
      }),
    });

    const actions = {
      mouseOverEvent: () => {
        if (props.hoverBgColor && !props.disabled) {
          buttonBasic.value.style.backgroundColor = props.hoverBgColor;
          buttonBasic.value.style.color = props.hoverColor;
        }
      },
      mouseLeaveEvent: () => {
        if (props.hoverBgColor && !props.disabled) {
          buttonBasic.value.style.backgroundColor = `${props.bgColor}`;
          buttonBasic.value.style.color = props.color;
        }
      },
      onBtnClick: () => {
        emit("action");
      },
    };

    return { state, actions, buttonBasic };
  },
};
</script>

<style scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 14px 0;
  width: 100%;
  border-radius: 4px;
}

button.reverse {
  flex-flow: row-reverse;
}

button:disabled {
  background: #ecf1f4;
  border: 1px solid #e6eaef;
  cursor: default;
}

button.round {
  border-radius: 24px;
}

.loading-spinner {
  animation: loading-spinner 1s linear infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
