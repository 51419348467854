
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { reactive, computed, onBeforeMount, watch } from "vue";
import onBoardingConst from "@/consts/onboarding.js";
import OnBoardingPreview from "./OnBoardingPreview/OnBoardingPreview.vue";
import OnBoardingAlias from "./OnBoardingAlias/OnBoardingAlias.vue";
import OnBoardingInterest from "./OnBoardingInterest/OnBoardingInterest.vue";
import OnBoardingChannel from "./OnBoardingChannel/OnBoardingChannel.vue";
import OnBoardingProfile from "./OnBoardingProfile/OnBoardingProfile.vue";
import OnBoardingFinish from "./OnBoardingFinish/OnBoardingFinish.vue";
import OnBoardingProgress from "./OnBoardingProgress/OnBoardingProgress.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import { ChannelForm } from "@/types/onboarding";

export default {
  name: "OnBoardingModal",
  components: {
    OnBoardingProgress,
    OnBoardingFinish,
    OnBoardingProfile,
    OnBoardingChannel,
    OnBoardingInterest,
    OnBoardingAlias,
    OnBoardingPreview,
    ButtonBasic,
    CloseIcon,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      spaceChannels: computed(() => {
        return store.getters["space/spaceChannels"];
      }),
      aliasName: "",
      avatar: "",
      creatorName: "",
      description: "",
      lastStep: computed(() => {
        return store.getters["onBoardings/lastStep"];
      }),
      pageLoading: false,
      isInterestStep: computed(() => {
        return state.lastStep === onBoardingConst.onBoardingSteps.interest;
      }),
      prevChannels: [] as ChannelForm[],
    });

    watch(
      () => state.lastStep,
      (lastStep) => {
        if (lastStep === onBoardingConst.onBoardingSteps.finish) {
          emit("close");
        }
      }
    );

    onBeforeMount(() => {
      if (state.user.aliasName) {
        state.aliasName = state.user.aliasName;
      }
      if (state.user.avatar) {
        state.avatar = state.user.avatar;
      }
      if (state.user.userProfile.displayName) {
        state.creatorName = state.user.userProfile.displayName;
      }
      if (state.user.userProfile.description) {
        state.description = state.user.userProfile.description;
      }
      if (state.spaceChannels) {
        for (let i = 0; i < 5; i++) {
          state.prevChannels.push({
            id: i + 1,
            icon: "",
            code: "",
            url: "",
          });
        }
        const loopCount =
          state.spaceChannels.length > 5 ? 5 : state.spaceChannels.length;
        for (let i = 0; i < loopCount; i++) {
          state.prevChannels[i].icon = state.spaceChannels[i].icon;
          state.prevChannels[i].code = state.spaceChannels[i].code;
          state.prevChannels[i].url = state.spaceChannels[i].url;
        }
      }
    });

    const actions = {
      changeStep: (step) => {
        let payload = {
          step: step,
        };
        store.dispatch("onBoardings/putOnBoardingStep", payload);
      },
      updateUserAliasName: (value) => {
        state.aliasName = value;
      },
      updateCreatorName: (value) => {
        state.creatorName = value;
      },
      updateAvatar: (value) => {
        state.avatar = value;
      },
      updateCreatorDescription: (value) => {
        state.description = value;
      },
      //TODO 로직 정리
      updateChannel: (channel: ChannelForm) => {
        let channelIndex: number = state.prevChannels.findIndex((item) => {
          return item.id === channel.id;
        });

        state.prevChannels[channelIndex] = {
          ...state.prevChannels[channelIndex],
          ...channel,
        };
      },
      //TODO 로직 수정
      completeOnBoarding: (value) => {
        actions.changeStep(onBoardingConst.onBoardingSteps.finish);
        if (value === "brand") {
          router.push({
            name: "console.brand.widgets",
          });
        }
        emit("close");
      },
      closeModal: () => {
        actions.changeStep(onBoardingConst.onBoardingSteps.finish);
        emit("close");
      },
    };

    return {
      state,
      actions,
      onBoardingConst,
    };
  },
};
