import ApiService from "@/api";

class SsoService {
  async postAuthSsoCodes(redirect) {
    const payload = {
      redirect: redirect,
    };
    const result = {
      code: null,
      redirect: null,
    };
    await ApiService.postAuthSsoCodes(payload).then((response) => {
      result.code = response.data.data.code;
      result.redirect = response.data.data.redirect;
    });

    return result;
  }

  async redirect(ssoEndPoint, redirect, isMobile = null, target = "_blank") {
    const sso = await this.postAuthSsoCodes(redirect);
    if (isMobile) {
      window.location.href = `${ssoEndPoint}/?code=${sso.code}&redirect=${sso.redirect}`;
    } else {
      window.open(
        `${ssoEndPoint}/?code=${sso.code}&redirect=${sso.redirect}`,
        "_blank"
      );
    }
  }
}

export default new SsoService();
