import { CLIPBOARDS } from "../mutation-types";

const getDefaultState = () => {
  return {
    text: "",
    html: "",
  };
};

const state = getDefaultState();

const getters = {
  clipboard: (state) => {
    return state;
  },
};

const actions = {
  setClipboard: ({ commit }, { text, html }) => {
    commit(CLIPBOARDS.SET_CLIPBOARD, { text, html });
  },
};

const mutations = {
  [CLIPBOARDS.SET_CLIPBOARD](state, { text, html }) {
    state.text = text;
    state.html = html.replace("<meta charset='utf-8'>", "");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
