import apiConsole from "./apiConsole";
import apiApp from "./apiApp";
import apiConst from "./apiConst";
import apiCoach from "@/api/apiCoach";
import apiConsoleV2 from "./apiConsoleV2";
import apiCommonV2 from "@/api/apiCommonV2";

export default {
  /**
   * Auth
   */
  postAuthSocialApp(payload) {
    return apiConsole.post(`/auth/social`, payload);
  },
  postAuthLogin(payload) {
    return apiConsole.post(`/auth/login`, payload);
  },
  postAuthRegister(payload) {
    return apiConsole.post(`/auth/register`, payload);
  },
  postAuthChange(payload) {
    return apiConsole.post(`/auth/change`, payload);
  },
  postAuthBridge(payload) {
    return apiConsole.post(`/auth/bridge`, payload);
  },
  postAuthSsoCodes(payload) {
    return apiConsole.post(`/auth/sso/codes`, payload);
  },
  postAuthSsoToken(payload) {
    return apiConsole.post(`/auth/sso/token`, payload);
  },
  postAuthPhoneNumber(payload) {
    return apiConsole.post("/auth/phone", payload);
  },
  postAuthPhoneVerificationCode(phoneResourceId, payload) {
    return apiConsole.post(`/auth/phone/${phoneResourceId}`, payload);
  },
  postPasswordRequest(payload) {
    return apiConsole.post("/password/request", payload);
  },
  postResetPassword(payload) {
    return apiConsole.post("/password/reset", payload);
  },

  postOAuthCallback(provider, payload) {
    return apiConsole.post(`/oauth/callback/${provider}`, payload);
  },

  /**
   * User
   */
  getUser() {
    return apiConsole.get("/user");
  },
  putUser(params) {
    return apiConsole.put("/user", params);
  },
  getUserNotifications(params) {
    return apiConsole.get("/user/notifications", { params: params });
  },
  postUserNotificationsMarkAsRead() {
    return apiConsole.post("/user/notifications/markAsRead");
  },
  getUserDashboard() {
    return apiConsole.get("/user/dashboard");
  },
  getNotices(params) {
    return apiConsole.get("/notice", { params: params });
  },
  getUserCurrentMonthEarningGraph() {
    return apiConsole.get("/user/dashboard/graph");
  },
  getUserSelectedMonthEarningGraph(params) {
    return apiConsole.get("/user/dashboard/graph", { params: params });
  },
  getUserProfile() {
    return apiConsole.get("/user/profile");
  },
  getUserAliasAvailable(params) {
    return apiConsole.get("/user/profile/alias", { params: params });
  },
  putUserProfile(payload) {
    return apiConsole.put("/user/profile", payload);
  },
  postUserChild(payload) {
    return apiConsole.post(`/user/child`, payload);
  },
  postUserChildAdd(payload) {
    return apiConsole.post(`/user/child/add`, payload);
  },
  postUserChildRemove(payload) {
    return apiConsole.post(`/user/child/remove`, payload);
  },
  uploadUserProfileCover(payload) {
    return apiConsole.post(`/user/profile/cover`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadUserMobileProfileCover(payload) {
    return apiConsole.post(`/user/profile/mCover`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadUserProfileLogo(payload) {
    return apiConsole.post(`/user/profile/logo`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadUserMobileProfileLogo(payload) {
    return apiConsole.post(`/user/profile/mLogo`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteUserProfileCover(payload) {
    return apiConsole.put(`/user/profile/cover/delete`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteUserMobileProfileCover(payload) {
    return apiConsole.put(`/user/profile/mCover/delete`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteUserProfileLogo(payload) {
    return apiConsole.put(`/user/profile/logo/delete`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteUserMobileProfileLogo(payload) {
    return apiConsole.put(`/user/profile/mLogo/delete`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadUserProfileCardCover(payload) {
    return apiConsole.post(`/user/profile/card/cover`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadUserProfileCardWallpaper(payload) {
    return apiConsole.post(`/user/profile/card/wallpaper`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadUserProfileCardObject(payload) {
    return apiConsole.post(`/user/profile/card/object`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  putUserProfileCoverDelete() {
    return apiConsole.put("/user/profile/cover/delete");
  },
  uploadUserProfileAvatar(payload) {
    return apiConsole.post(`/user/profile/avatar`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteUserProfileAvatar() {
    return apiConsole.delete(`/user/profile/avatar`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  postUserChangePassword(payload) {
    return apiConsole.post("/user/changePassword", payload);
  },
  getUserConnected(params) {
    return apiConsole.get(`/user/connected`, { params: params });
  },
  getUserMembers(params) {
    return apiConsole.get(`/user/members`, { params: params });
  },
  getUserInterest() {
    return apiConsole.get("/user/interest");
  },
  putUserInterest(payload) {
    return apiConsole.put("/user/interest", payload);
  },
  putUserIsSms(payload) {
    return apiConsole.put("/user/profile/isSms", payload);
  },
  putUserIsEmail(payload) {
    return apiConsole.put("/user/profile/isEmail", payload);
  },

  /**
   * Chats
   */
  // getChats(params) {
  //   return apiConsole.get(`/chats`, { params: params });
  // },
  // postChatsJoin(chatResourceId, payload) {
  //   return apiConsole.post(`/chats/${chatResourceId}/join`, payload);
  // },
  // postChatsLeave(chatResourceId, payload) {
  //   return apiConsole.post(`/chats/${chatResourceId}/leave`, payload);
  // },
  // getChatsMessages(chatResourceId, params) {
  //   return apiConsole.get(`/chats/${chatResourceId}/messages`, {
  //     params: params,
  //   });
  // },
  // postChatsMessages(chatResourceId, payload) {
  //   return apiConsole.post(`/chats/${chatResourceId}/messages`, payload);
  // },
  // uploadChatsMessage(chatResourceId, payload) {
  //   return apiConsole.post(`/chats/${chatResourceId}/upload`, payload, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   });
  // },

  /**
   * Clubs to Clubs...
   */
  getClubs(params) {
    return apiConsole.get(`/clubs`, { params: params });
  },
  getClubThemes(params) {
    return apiConsole(`/clubs/theme/${params.type}`);
  },
  getClubAllThemes() {
    return apiConsole(`/clubs/theme`);
  },
  postClubs(payload) {
    return apiConsole.post(`/clubs`, payload);
  },
  postThemeClub(payload) {
    return apiConsole.post(`/clubs/theme`, payload);
  },
  getClub(clubResourceId) {
    return apiConsole.get(`/clubs/${clubResourceId}`);
  },
  copyClub(clubResourceId) {
    return apiConsole.post(`/clubs/${clubResourceId}/replicate`);
  },
  putClub(clubResourceId, payload) {
    if (payload instanceof FormData) {
      return apiConsole.post(`/clubs/${clubResourceId}`, payload);
    }
    return apiConsole.put(`/clubs/${clubResourceId}`, payload);
  },
  postClubFunding(clubResourceId) {
    return apiConsole.post(`/clubs/${clubResourceId}/funding`);
  },
  deleteClub(clubResourceId) {
    return apiConsole.delete(`/clubs/${clubResourceId}`);
  },
  getClubDashboard() {
    return apiConsole.get("/clubs/dashboard");
  },
  getClubMembers(clubResourceId, params) {
    return apiConsole.get(`/clubs/${clubResourceId}/members`, {
      params: params,
    });
  },
  putClubMemberRole(clubResourceId, userResourceId, params) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/members/${userResourceId}/role`,
      params
    );
  },
  sendMessageClubMembers(clubResourceId, payload) {
    return apiConsole.post(
      `/clubs/${clubResourceId}/members/messages`,
      payload
    );
  },

  postClubMember(clubResourceId, payload) {
    return apiConsole.post(`/clubs/${clubResourceId}/members`, payload);
  },
  deleteClubMember(clubResourceId, memberResourceId) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/members/${memberResourceId}`
    );
  },

  uploadClubFeaturedImage(clubResourceId, payload) {
    return apiConsole.post(`/clubs/${clubResourceId}/featuredImage`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteClubFeaturedImage(clubResourceId) {
    return apiConsole.delete(`/clubs/${clubResourceId}/featuredImage`);
  },
  getClubArticles(clubResourceId, params) {
    return apiConsole.get(`/articles`, {
      params: params,
    });
  },
  postClubSection(clubResourceId, payload) {
    return apiConsole.post(`/clubs/${clubResourceId}/sections`, payload);
  },
  putClubSection(clubResourceId, clubSectionResourceId, params) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}`,
      params
    );
  },
  deleteClubSection(clubResourceId, clubSectionResourceId) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}`
    );
  },
  putClubSectionUp(clubResourceId, clubSectionResourceId) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/up`
    );
  },
  putClubSectionDown(clubResourceId, clubSectionResourceId) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/down`
    );
  },

  postClubSectionChapter(clubResourceId, clubSectionResourceId, payload) {
    return apiConsole.post(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}`,
      payload
    );
  },
  putClubSectionChapter(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId,
    payload
  ) {
    return apiConsole.post(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  deleteClubSectionChapter(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId
  ) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}`
    );
  },
  putClubSectionChapterUp(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId
  ) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}/up`
    );
  },
  putClubSectionChapterDown(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId
  ) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}/down`
    );
  },
  putClubSectionChapterContent(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId,
    payload
  ) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}/content`,
      payload
    );
  },
  deleteClubSectionChapterContent(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId
  ) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}/content`
    );
  },
  putClubSectionChapterMeeting(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId,
    payload
  ) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}/meeting`,
      payload
    );
  },
  deleteClubSectionChapterMeeting(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId
  ) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}/meeting`
    );
  },
  getUpcomingMeeting() {
    return apiConsole.get("/meetings/upcoming");
  },

  postClubSectionChapterAttachment(
    clubResourceId,
    clubSectionResourceId,
    clubSectionChapterResourceId,
    payload
  ) {
    return apiConsole.post(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/${clubSectionChapterResourceId}/attachment`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  getClubPlan(clubResourceId, planResourceId) {
    return apiConsole.get(`/clubs/${clubResourceId}/plans/${planResourceId}`);
  },
  getClubPlans(clubResourceId, params) {
    return apiConsole.get(`/clubs/${clubResourceId}/plans`, {
      params: params,
    });
  },
  postClubPlans(clubResourceId, payload) {
    return apiConsole.post(`/clubs/${clubResourceId}/plans`, payload);
  },
  putClubPlans(clubResourceId, planResourceId, payload) {
    if (payload instanceof FormData) {
      return apiConsole.post(
        `/clubs/${clubResourceId}/plans/${planResourceId}`,
        payload
      );
    } else {
      return apiConsole.put(
        `/clubs/${clubResourceId}/plans/${planResourceId}`,
        payload
      );
    }
  },
  putClubPlansSort(clubResourceId, payload) {
    return apiConsole.put(`clubs/${clubResourceId}/plans/sort`, payload);
  },
  patchClubPlans(clubResourceId, planResourceId, payload) {
    return apiConsole.patch(
      `/clubs/${clubResourceId}/plans/${planResourceId}`,
      payload
    );
  },
  deleteClubPlans(clubResourceId, planResourceId) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/plans/${planResourceId}`
    );
  },

  postClubDescription(clubResourceId, payload) {
    return apiConsole.post(`/clubs/${clubResourceId}/descriptions`, payload);
  },
  putClubDescription(clubResourceId, clubDescriptionResourceId, payload) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/descriptions/${clubDescriptionResourceId}`,
      payload
    );
  },
  putClubDescriptionOrder(clubResourceId, payload) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/descriptions/sort`,
      payload
    );
  },
  deleteClubDescription(clubResourceId, clubDescriptionResourceId) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/descriptions/${clubDescriptionResourceId}`
    );
  },
  postClubDescriptionFeaturedImageUpload(
    clubResourceId,
    clubDescriptionResourceId,
    payload
  ) {
    return apiConsole.post(
      `/clubs/${clubResourceId}/descriptions/${clubDescriptionResourceId}/featuredImage`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  deleteClubDescriptionFeaturedImage(
    clubResourceId,
    clubDescriptionResourceId
  ) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/descriptions/${clubDescriptionResourceId}/featuredImage`
    );
  },
  postClubSessionContent(clubResourceId, payload) {
    return apiConsole.post(`/clubs/${clubResourceId}/contents`, payload);
  },
  postClubSessionLive(clubResourceId, payload) {
    return apiConsole.post(`/clubs/${clubResourceId}/meetings`, payload);
  },
  getClubSessionPolls(clubResourceId) {
    return apiConsole.get(`/clubs/${clubResourceId}/polls`);
  },
  postClubSessionPoll(clubResourceId, payload) {
    return apiConsole.post(`/clubs/${clubResourceId}/polls`, payload);
  },
  // 미사용 함수
  deleteClubSessionPoll(clubResourceId, pollResourceId) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/polls/${pollResourceId}`
    );
  },
  putSessionSort(clubResourceId, payload) {
    return apiConsole.put(`/clubs/${clubResourceId}/session/sort`, payload);
  },
  deleteClubSession(clubResourceId, sessionResourceId) {
    return apiConsoleV2.delete(
      `/clubs/${clubResourceId}/sessions/${sessionResourceId}`
    );
  },

  postClubReview(payload) {
    return apiConsole.post(`/reviews`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  putClubReview(reviewResourceId, payload) {
    return apiConsole.post(`/reviews/${reviewResourceId}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  deleteClubReview(reviewResourceId) {
    return apiConsole.delete(`/reviews/${reviewResourceId}`);
  },

  putClubReviewOrder(payload) {
    return apiConsole.put(`/reviews/sort`, payload);
  },

  postClubFaq(payload) {
    return apiConsole.post(`/faqs`, payload);
  },

  putClubFaq(faqResourceId, payload) {
    return apiConsole.put(`/faqs/${faqResourceId}`, payload);
  },

  putClubFaqOrder(payload) {
    return apiConsole.put(`/faqs/sort`, payload);
  },

  putClubSectionChapterOrder(clubResourceId, clubSectionResourceId, payload) {
    return apiConsole.put(
      `/clubs/${clubResourceId}/sections/${clubSectionResourceId}/chapters/sort`,
      payload
    );
  },

  deleteClubFaq(faqResourceId) {
    return apiConsole.delete(`/faqs/${faqResourceId}`);
  },

  getClubContents(params) {
    return apiConsoleV2.get(`/clubs/contents`, {
      params: params,
    });
  },
  getClubLives(params) {
    return apiConsoleV2.get(`/clubs/lives`, {
      params: params,
    });
  },
  getClubPolls(params) {
    return apiConsoleV2.get(`/clubs/polls`, {
      params: params,
    });
  },
  postClubSessions(clubResourceId, payload) {
    return apiConsoleV2.post(`/clubs/${clubResourceId}/sessions`, payload);
  },
  postClubSessionContentConnect(clubResourceId, payload) {
    return apiConsole.post(
      `/clubs/${clubResourceId}/contents/connect`,
      payload
    );
  },
  /**
   * Memberships
   */
  getMemberships(payload) {
    return apiConsole.get("/memberships", payload);
  },
  postMembership(payload) {
    return apiConsole.post(`/memberships`, payload);
  },

  /**
   *  Communities
   */
  getCommunities() {
    return apiConsole.get("/community");
  },
  postCommunity(payload) {
    return apiConsole.post("/community", payload);
  },
  putCommunity(communityResourceId, payload) {
    return apiConsole.put(`/community/${communityResourceId}`, payload);
  },
  sortCommunities(payload) {
    return apiConsole.put("/community/sort", payload);
  },
  deleteCommunity(communityResourceId) {
    return apiConsole.delete(`/community/${communityResourceId}`);
  },
  /**
   * Articles
   */
  getArticles(params) {
    return apiConsole.get(`/articles`, { params: params });
  },
  getArticle(articleResourceId) {
    return apiConsole.get(`/articles/${articleResourceId}`);
  },
  postArticles(payload) {
    return apiConsole.post(`/articles`, payload);
  },
  putArticle(articleResourceId, payload) {
    return apiConsole.put(`/articles/${articleResourceId}`, payload);
  },
  postArticleState(articleResourceId, state) {
    return apiConsole.post(`/articles/${articleResourceId}/${state}`);
  },
  deleteArticles(articleResourceId) {
    return apiConsole.delete(`/articles/${articleResourceId}`);
  },
  postArticleComments(payload) {
    return apiConsole.post(`/comments`, payload);
  },
  deleteArticleComments(commentResourceId) {
    return apiConsole.delete(`/comments/${commentResourceId}`);
  },
  /**
   * Meetings
   */
  getMeetings(params) {
    return apiConsole.get(`/meetings`, { params: params });
  },
  getClubMeetings(clubResourceId) {
    return apiConsole.get(`/clubs/${clubResourceId}/meetings`);
  },
  // Todo 사용하는곳 없음 추후 삭제 예정
  getMeetingsBrowse(params) {
    return apiConsole.get(`/meetings/browse`, { params: params });
  },
  postMeetingsVerification(payload) {
    return apiConsole.post(`/meetings/verification`, payload);
  },
  postMeetings(payload) {
    return apiConsole.post(`/meetings`, payload);
  },
  getMeeting(meetingResourceId, params) {
    return apiConsole.get(`/meetings/${meetingResourceId}`, { params: params });
  },
  postMeetingUser(meetingResourceId, payload) {
    return apiConsole.post(`meetings/${meetingResourceId}/users`, payload);
  },
  getMeetingUsers(meetingResourceId, params) {
    return apiConsole.get(`/meetings/${meetingResourceId}/users`, {
      params: params,
    });
  },
  deleteMeetingUser(meetingResourceId, userResourceId) {
    return apiConsole.delete(
      `/meetings/${meetingResourceId}/users/${userResourceId}`
    );
  },
  toggleMeetingUserAccess(meetingResourceId, userResourceId, payload) {
    return apiConsole.post(
      `/meetings/${meetingResourceId}/users/${userResourceId}/media`,
      payload
    );
  },
  putMeetingUsersMedia(meetingResourceId, userResourceId, payload) {
    return apiConsole.put(
      `/meetings/${meetingResourceId}/users/${userResourceId}/media`,
      payload
    );
  },
  postMeetingJoin(meetingResourceId, payload) {
    return apiConsole.post(`/meetings/${meetingResourceId}/join`, payload);
  },
  postMeetingLeave(meetingResourceId, payload) {
    return apiConsole.post(`/meetings/${meetingResourceId}/leave`, payload);
  },
  putMeetings(meetingResourceId, payload) {
    return apiConsole.put(`/meetings/${meetingResourceId}`, payload);
  },
  postMeetingFeaturedImageUpload(meetingResourceId, payload) {
    return apiConsole.post(
      `/meetings/${meetingResourceId}/featuredImage`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  deleteMeetingFeaturedImage(meetingResourceId) {
    return apiConsole.delete(`/meetings/${meetingResourceId}/featuredImage`);
  },
  deleteMeetings(meetingResourceId) {
    return apiConsole.delete(`/meetings/${meetingResourceId}`);
  },
  deleteClubMeetings(clubResourceId, meetingResourceId) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/meetings/${meetingResourceId}`
    );
  },
  putMeetingsPublish(meetingResourceId, payload) {
    return apiConsole.put(`/meetings/${meetingResourceId}/publish`, payload);
  },
  putMeetingsCreated(meetingResourceId, payload) {
    return apiConsole.put(`/meetings/${meetingResourceId}/created`, payload);
  },
  putMeetingsWaiting(meetingResourceId, payload) {
    return apiConsole.put(`/meetings/${meetingResourceId}/waiting`, payload);
  },
  putMeetingsStart(meetingResourceId, payload) {
    return apiConsole.put(`/meetings/${meetingResourceId}/start`, payload);
  },
  putMeetingsFinish(meetingResourceId, payload) {
    return apiConsole.put(`/meetings/${meetingResourceId}/finish`, payload);
  },
  postMeetingRtcAuth(meetingResourceId, payload) {
    return apiConsole.post(`/meetings/${meetingResourceId}/rtc/auth`, payload);
  },
  postMeetingRtcRecordingStart(meetingResourceId, payload) {
    return apiConsole.post(
      `/meetings/${meetingResourceId}/rtc/recording/start`,
      payload
    );
  },
  postMeetingRtcRecordingStop(meetingResourceId, payload) {
    return apiConsole.post(
      `/meetings/${meetingResourceId}/rtc/recording/stop`,
      payload
    );
  },
  postMeetingRtcUsersMedia(meetingResourceId, userResourceId, payload) {
    return apiConsole.post(
      `/meetings/${meetingResourceId}/rtc/users/${userResourceId}/media`,
      payload
    );
  },
  putMeetingCueAction(meetingResourceId, payload) {
    return apiConsole.put(
      `/meetings/${meetingResourceId}/cues/action`,
      payload
    );
  },

  /**
   * Cues
   */
  getCues(params) {
    return apiConsole.get(`/cues`, { params: params });
  },

  /**
   * Contents
   */
  getContents(params) {
    return apiConsole.get(`/contents`, { params: params });
  },
  getPaidContents() {
    return apiConsole.get(`/contents/paid`);
  },
  getContent(contentResourceId, params) {
    return apiConsole.get(`/contents/${contentResourceId}`, { params: params });
  },
  postContent(payload) {
    return apiConsole.post(`/contents`, payload);
  },
  putContent(contentResourceId, payload) {
    return apiConsole.put(`/contents/${contentResourceId}`, payload);
  },
  deleteContent(contentResourceId) {
    return apiConsole.delete(`/contents/${contentResourceId}`);
  },
  deleteClubContent(clubResourceId, contentResourceId) {
    return apiConsole.delete(
      `/clubs/${clubResourceId}/contents/${contentResourceId}`
    );
  },
  uploadContentFeaturedImage(contentResourceId, payload) {
    return apiConsole.post(
      `/contents/${contentResourceId}/featuredImage`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  deleteContentFeaturedImage(contentResourceId) {
    return apiConsole.delete(`/contents/${contentResourceId}/featuredImage`);
  },
  postContentComments(payload) {
    return apiConsole.post(`/comments`, payload);
  },
  deleteContentComments(commentResourceId) {
    return apiConsole.delete(`/comments/${commentResourceId}`);
  },

  /**
   * 브랜드홈, 온보딩
   */
  getSpaceChannels(params) {
    return apiConsole.get(`/spaces/channels`, { params: params });
  },
  postSpaceChannel(payload) {
    return apiConsole.post(`/spaces/channels`, payload);
  },
  putSpaceChannel(spaceChannelResourceId, payload) {
    return apiConsole.put(
      `/spaces/channels/${spaceChannelResourceId}`,
      payload
    );
  },
  deleteSpaceChannel(spaceChannelResourceId, payload) {
    return apiConsole.delete(
      `/spaces/channels/${spaceChannelResourceId}`,
      payload
    );
  },
  postSpaceChannelResort(payload) {
    return apiConsole.post(`/spaces/channels/resort`, payload);
  },
  postJoinPurpose(payload) {
    return apiConsole.post(`/onboarding/purpose`, payload);
  },

  /**
   * SNS Channel 나올 예정
   */
  getChannels(params) {
    return apiConsole.get(`/channels`, { params: params });
  },
  postChannel(payload) {
    return apiConsole.post(`/channels`, payload);
  },
  putChannel(channelResourceId, payload) {
    return apiConsole.put(`/channels/${channelResourceId}`, payload);
  },
  deleteChannel(channelResourceId, payload) {
    return apiConsole.delete(`/channels/${channelResourceId}`, payload);
  },
  postChannelResort(payload) {
    return apiConsole.post(`/channels/resort`, payload);
  },
  postChannelGather(channelResourceId, payload) {
    return apiConsole.post(`/channels/${channelResourceId}/gather`, payload);
  },
  postChannelMeta(channelResourceId, payload) {
    return apiConsole.post(`/channels/${channelResourceId}/meta`, payload);
  },

  /**
   * Lecture
   */
  getLectures(payload) {
    return apiConsole.get(`/lectures`, payload);
  },
  getLecture(resourceId) {
    return apiConsole.get(`/lecture/${resourceId}`);
  },
  postLectures(payload) {
    return apiConsole.post(`/lecture`, payload);
  },
  putLectures(lectureResourceId, payload) {
    return apiConsole.put(`/lecture/${lectureResourceId}`, payload);
  },
  postLecturePrice(lectureResourceId, payload) {
    return apiConsole.post(`/lecture/${lectureResourceId}/price`, payload);
  },
  putLecturePrice(lectureResourceId, payload) {
    return apiConsole.put(`/lecture/${lectureResourceId}/price`, payload);
  },
  getLectureSchedules(lectureResourceId, params) {
    return apiConsole.get(`/lecture/${lectureResourceId}/schedules`, {
      params: params,
    });
  },
  postLectureSchedule(lectureResourceId, payload) {
    return apiConsole.post(`/lecture/${lectureResourceId}/schedules`, payload);
  },
  deleteLectureSchedule(lectureResourceId, scheduleResourceId) {
    return apiConsole.delete(
      `lecture/${lectureResourceId}/schedule/${scheduleResourceId}`
    );
  },
  postCancelReservedSchedule(
    lectureResourceId,
    reservationResourceId,
    payload
  ) {
    return apiConsole.post(
      `/lecture/${lectureResourceId}/schedule/group/${reservationResourceId}/cancel`,
      payload
    );
  },
  getLectureCategories() {
    return apiConsole.get(`/lecture/categories`);
  },
  getLectureTopics() {
    return apiConsole.get(`/lecture/topics`);
  },
  getLectureQuestions(lectureResourceId) {
    return apiConsole.get(`/lecture/${lectureResourceId}/questions`);
  },
  postLectureQuestion(lectureResourceId, payload) {
    return apiConsole.post(`/lecture/${lectureResourceId}/questions`, payload);
  },
  deleteLectureQuestion(lectureResourceId, questionResourceId) {
    return apiConsole.delete(
      `/lecture/${lectureResourceId}/question/${questionResourceId}`
    );
  },
  getLectureReviews(lectureResourceId) {
    return apiConsole.get(`/lecture/${lectureResourceId}/reviews`);
  },
  postLectureReview(lectureResourceId, payload) {
    return apiConsole.post(`lecture/${lectureResourceId}/reviews`, payload);
  },
  putLectureReview(lectureResourceId, lectureReviewResourceId, payload) {
    return apiConsole.put(
      `/lecture/${lectureResourceId}/review/${lectureReviewResourceId}`,
      payload
    );
  },
  getLectureReservations(lectureResourceId, params) {
    return apiConsole.get(`/lecture/${lectureResourceId}/reservations`, {
      params: params,
    });
  },
  getLectureCommunities(lectureResourceId, params) {
    return apiConsole.get(`/lecture/${lectureResourceId}/communities`, {
      params: params,
    });
  },
  getLectureTags() {
    return apiConsole.get(`/lecture/tags`);
  },
  getLectureScheduleQna(lectureResourceId, reservationResourceId) {
    return apiConsole.get(
      `/lecture/${lectureResourceId}/schedule/group/${reservationResourceId}/qna`
    );
  },
  getReservationCounts(lectureResourceId) {
    return apiConsole.get(`/lecture/${lectureResourceId}/reservations/count`);
  },
  getLectureScheduleFeedback(lectureResourceId, reservationResourceId) {
    return apiConsole.get(
      `/lecture/${lectureResourceId}/schedule/group/${reservationResourceId}/feedback`
    );
  },
  postLectureScheduleFeedback(
    lectureResourceId,
    reservationResourceId,
    payload
  ) {
    return apiConsole.post(
      `/lecture/${lectureResourceId}/schedule/group/${reservationResourceId}/feedback`,
      payload
    );
  },
  getUpcomingLecture(lectureResourceId) {
    return apiConsole.get(`/lecture/${lectureResourceId}/upcoming`);
  },
  /**
   * Space
   */
  getSpace() {
    return apiConsole.get(`/spaces`);
  },
  getSpaceOpenGraph(params) {
    return apiConsole.get("spaces/open-graph", { params: params });
  },
  putSpace(payload) {
    return apiConsole.put(`/spaces`, payload);
  },
  getSpaceWidgetType(payload) {
    return apiConsole.get(`/spaces/widgets/type`, payload);
  },
  getSpaceWidgets() {
    return apiConsole.get(`/spaces/widgets`);
  },
  postSpaceWidget(payload) {
    return apiConsole.post(`/spaces/widgets`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  putSpaceWidget(spaceWidgetResourceId, payload) {
    if (payload instanceof FormData) {
      return apiConsole.post(
        `/spaces/widgets/${spaceWidgetResourceId}`,
        payload
      );
    }
    return apiConsole.put(`/spaces/widgets/${spaceWidgetResourceId}`, payload);
  },
  deleteSpaceWidget(spaceWidgetResourceId) {
    return apiConsole.delete(`/spaces/widgets/${spaceWidgetResourceId}`);
  },
  postSpaceWidgetFeaturedImage(spaceWidgetResourceId, payload) {
    return apiConsole.post(
      `/spaces/widgets/${spaceWidgetResourceId}/featuredImage`,
      payload
    );
  },
  postSpaceWidgetResort(payload) {
    return apiConsole.post(`/spaces/widgets/resort`, payload);
  },
  getSpaceWidget(spaceWidgetResourceId) {
    return apiConsole.get(`/spaces/widgets/${spaceWidgetResourceId}`);
  },
  putSpaceNavigations(spaceNavigationResourceId, payload) {
    return apiConsole.put(
      `/spaces/navigations/${spaceNavigationResourceId}`,
      payload
    );
  },
  postSpaceNavigationsResort(payload) {
    return apiConsole.post(`/spaces/navigations/resort`, payload);
  },
  postSpaceNavigationsDescription(payload) {
    return apiConsole.post("spaces/navigations/description", payload);
  },
  // note spaces pc,mo 비디오 추가 api
  postSpacesCoverImageUrl(type, payload) {
    console.log(type, payload);
    return apiConsole.post(`/spaces/${type}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteSpacesCoverImageUrl(type) {
    return apiConsole.delete(`/spaces/${type}/delete`);
  },
  getSpacesWidgetPolls() {
    return apiConsole.get(`/spaces/widgets/polls`);
  },
  postSpacesWidgetPolls(payload) {
    return apiConsole.post(`/spaces/widgets`, payload);
  },
  putSpacesWidgetPolls(payload) {
    return apiConsole.put(`/spaces/widgets`, payload);
  },
  postSpacesSecret(payload) {
    return apiConsoleV2.post(`/spaces/secret`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  putSpacesSecretPassword(payload) {
    return apiConsoleV2.post(`/spaces/secret/password`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  /**
   * Onboadring
   */
  getOnBoardingStep() {
    return apiConsole.get(`/onboarding/step`);
  },
  putOnBoardingStep(payload) {
    return apiConsole.put(`/onboarding/step`, payload);
  },
  getOnBoardingAlias(params) {
    return apiConsole.get(`/onboarding/alias`, { params: params });
  },
  putOnBoardingAlias(payload) {
    return apiConsole.put(`/onboarding/alias`, payload);
  },
  postOnBoardingProfile(payload) {
    return apiConsole.post(`/onboarding/profile`, payload);
  },
  getOnBoardingInterest() {
    return apiConsole.get(`/onboarding/interest`);
  },
  putOnBoardingInterest(payload) {
    return apiConsole.put(`/onboarding/interest`, payload);
  },

  /**
   * Settlements
   */
  getSettlements(params) {
    return apiConsole.get("/settlements", { params: params });
  },
  getSettlement(settlementsResourceId, params) {
    return apiConsole.get(`/settlements/${settlementsResourceId}`, {
      params: params,
    });
  },
  postSettlementInfo(payload) {
    return apiConsole.post(`/settlements/info`, payload);
  },
  getSettlementInfo() {
    return apiConsole.get(`/settlements/info`);
  },
  getSettlementSales(params) {
    return apiConsole.get(`/settlements/sales`, { params: params });
  },
  getSettlementSummary() {
    return apiConsole.get(`/settlements/summary`);
  },
  getSettlementBankbook() {
    return apiConsole(`/settlements/bankbook`);
  },
  getSettlementPoint() {
    return apiConsole(`/settlements/points`);
  },

  /**
   * Polls
   */
  getPolls(params) {
    return apiConsole.get(`/polls`, { params: params });
  },
  getPoll(pollResourceId) {
    return apiConsole.get(`/polls/${pollResourceId}`);
  },
  postPoll(payload) {
    return apiConsole.post(`/polls`, payload);
  },
  putPoll(pollResourceId, payload) {
    return apiConsole.put(`/polls/${pollResourceId}`, payload);
  },
  deletePoll(pollResourceId) {
    return apiConsole.delete(`/polls/${pollResourceId}`);
  },
  postPollFeaturedImage(pollResourceId, payload) {
    return apiConsole.post(`/polls/${pollResourceId}/featuredImage`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getPollOptions(pollResourceId) {
    return apiConsole.get(`/polls/${pollResourceId}/polloptions`);
  },
  getPollOption(pollResourceId, pollOptionResourceId) {
    return apiConsole.get(
      `/polls/${pollResourceId}/polloptions/${pollOptionResourceId}`
    );
  },
  postPollOptions(pollResourceId, payload) {
    return apiConsole.post(`/polls/${pollResourceId}/polloptions`, payload);
  },
  putPollOptions(pollResourceId, pollOptionResourceId, payload) {
    return apiConsole.put(
      `/polls/${pollResourceId}/polloptions/${pollOptionResourceId}`,
      payload
    );
  },
  deletePollOptions(pollResourceId, pollOptionResourceId) {
    return apiConsole.delete(
      `/polls/${pollResourceId}/polloptions/${pollOptionResourceId}`
    );
  },
  postPollOptionsFeatureImage(pollResourceId, pollOptionResourceId, payload) {
    return apiConsole.post(
      `/polls/${pollResourceId}/polloptions/${pollOptionResourceId}/featuredImage`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postPollOptionsResort(pollResourceId, payload) {
    return apiConsole.post(
      `/polls/${pollResourceId}/polloptions/resort`,
      payload
    );
  },
  // 투표 집계 최신화하는 api
  getPollStatistics(pollResourceId) {
    return apiConsole.post(`/polls/${pollResourceId}/statistics`);
  },
  postPollGeofence(pollResourceId, payload) {
    return apiConsoleV2.post(`/polls/${pollResourceId}/geofence`, payload);
  },
  deletePollGeofence(pollResourceId) {
    return apiConsoleV2.delete(`/polls/${pollResourceId}/geofence`);
  },
  // ==================================================================

  /**
   * Etc
   */
  getContentMetaFromUrl(params) {
    return apiConsole.get("/tools/getContentMetaFromUrl", { params: params });
  },
  postAttachment(payload) {
    return apiConsole.post(`/attachments`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  fileUploadTest(payload, config) {
    return apiConsole.post(`/attachments`, payload, config);
  },
  postContentsAttachment(contentResourceId, payload) {
    return apiConsole.post(
      `/contents/${contentResourceId}/attachment`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  deleteAttachment(attachmentResourceId) {
    return apiConsole.delete(`/attachments/${attachmentResourceId}`);
  },
  postContentsCaption(contentResourceId, payload) {
    return apiConsole.post(`/contents/${contentResourceId}/caption`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteCaption(captionResourceId) {
    return apiConsole.delete(`/captions/${captionResourceId}`);
  },

  getSearchUsers(params) {
    return apiConsole.get(`/search/users`, { params: params });
  },

  postCelebApplication(payload) {
    return apiConsole.post(`/celebs/applications`, payload);
  },

  getTranslations(params) {
    return apiConsole.get(`/translations`, { params: params });
  },
  //댓글 기능
  getComments(params) {
    return apiConsole.get(`/comments`, { params: params });
  },
  postComments(payload) {
    return apiConsole.post(`/comments`, payload);
  },
  putComment(commentResourceId, params) {
    return apiConsole.put(`/comments/${commentResourceId}`, params);
  },
  deleteComments(commentResourceId) {
    return apiConsole.delete(`/comments/${commentResourceId}`);
  },
  //신고하기
  postReport(payload) {
    return apiConsole.post(`/report`, payload);
  },

  postLike(payload) {
    return apiConsole.post(`/interactions/like`, payload);
  },

  postFavorite(payload) {
    return apiConsole.post(`/interactions/favorite`, payload);
  },
  // 국가별 정보조회
  getCountries() {
    return apiCommonV2.get("/country");
  },

  /**
   * App
   */
  postPaymentOrdersToApp(payload) {
    return apiApp.post(`/payments/orders`, payload);
  },

  postPaymentOrdersRequestPayToApp(paymentOrderResourceId, payload) {
    return apiApp.post(
      `/payments/orders/${paymentOrderResourceId}/requestPay`,
      payload
    );
  },

  getUserContacts() {
    return apiApp.get("/user/contacts");
  },

  postWebhookTosspaymentsAccessToken(payload) {
    return apiApp.post(`/webhook/tosspayments/accessToken`, payload);
  },

  postSpeechToText(payload) {
    return apiApp.post(`/labs/labsStt`, payload);
  },

  //  url: '/clubs' , club의 host에 대한 정보가 없어서 url:'/curation/clubs'로 변경 , 추후 api 작업 요청. To do romano
  getClubsToApp(params) {
    return apiApp.get(`/curation/clubs`, { params: params });
  },

  /**
   * Coach
   */
  getChats() {
    return apiCoach.get("/chats");
  },
  getChat(chatResourceId, params) {
    return apiCoach.get(`/chats/${chatResourceId}`, {
      params: params,
    });
  },
  getChatMessages(chatResourceId, params) {
    return apiCoach.get(`/chats/${chatResourceId}/messages`);
  },
  postChatRtmAuth(chatResourceId, payload) {
    return apiCoach.post(`/chats/${chatResourceId}/rtm/auth`, payload);
  },
  postChatMessages(chatResourceId, payload) {
    return apiCoach.post(`/chats/${chatResourceId}/messages`, payload);
  },
  postChatUpload(chatResourceId, payload) {
    return apiCoach.post(`/chats/${chatResourceId}/upload`, payload);
  },
  postChatsView(chatResourceId) {
    return apiCoach.post(`/chats/${chatResourceId}/view`);
  },

  /**
   * Const
   */
  getConstChannels() {
    return apiConst.get(`/channels`);
  },

  /**
   * 테스트 API 입니다.
   */
  apiFormRequestTest(payload) {
    return apiConsole.post("/route/test", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  /**
   * Multipart Api
   */
  postMultipart(params, payload) {
    return apiConsoleV2.post("/contents/multipart", payload, {
      params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getMultipartPresignedUrl(uploadId, index, params) {
    return apiConsoleV2.get(`/contents/multipart/${uploadId}/${index}`, {
      params,
    });
  },
  deleteMultipart(uploadId, params) {
    return apiConsoleV2.delete(`/contents/multipart/${uploadId}`, {
      params,
    });
  },
  completeMultipart(uploadId, params, payload) {
    return apiConsoleV2.post(`/contents/multipart/${uploadId}`, payload, {
      params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // getChatMessages(chatResourceId, params) {
  //   return apiCoach.get(`/chats/${chatResourceId}/messages`);
  // },
  // getContentMetaFromUrl(uploadIdparams) {
  //   return apiConsole.get("/tools/getContentMetaFromUrl", { params: params });
  // },
};
