
import { reactive, defineComponent } from "vue";
import ImageComponent from "@/components/web/ImageComponent/ImageComponent.vue";

export default defineComponent({
  name: "FooterComponent",
  components: { ImageComponent },
  setup() {
    const state = reactive({
      onDropdown: false,
    });

    const actions = {
      openDropdown: () => {
        state.onDropdown = true;
      },
      closeDropdown: () => {
        state.onDropdown = false;
      },
    };

    return {
      state,
      actions,
    };
  },
});
