import { createI18n } from "vue-i18n";
import getBrowserLocale from "../i18n/getBrowserLoacle";
import ApiService from "../api";
import store from "../store";

export const SUPPORT_LOCALES = {
  en: "English",
  ko: "한국어",
};

export function i18nSetup() {
  const options = {
    legacy: false,
    locale: getStartingLocale(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  };
  const i18n = createI18n(options);
  loadLocaleMessages(i18n, options.locale).then();
  return i18n;
}

export function i18nSetLocale(i18n, locale) {
  i18n.global.locale.value = locale;
  loadLocaleMessages(i18n, locale).then();
}

function supportedLocalesInclude(locale) {
  return Object.keys(SUPPORT_LOCALES).includes(locale);
}

function getStartingLocale() {
  if (store.getters["locale/check"]) {
    return store.getters["locale/locale"];
  }
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  let locale = null;
  if (supportedLocalesInclude(browserLocale)) {
    locale = browserLocale;
  } else {
    locale = process.env.VUE_APP_I18N_LOCALE || "en";
  }

  store.dispatch("locale/saveLocale", { locale: locale, remember: true });
  return locale;
}

export async function loadLocaleMessages(i18n, locale) {
  console.log(locale);
  // load locale messages with dynamic import

  // const messages = await ApiService.getTranslations(locale);
  // const messageData = messages.data.data;
  // i18n.global.setLocaleMessage(locale, messageData);

  const messages = await ApiService.getTranslations({
    namespace: "bigcstudio",
  });
  const messageData = messages.data.data;

  for (let key in SUPPORT_LOCALES) {
    i18n.global.setLocaleMessage(key, messageData[key]);
  }
}
