<template>
  <icon-base
    icon-name="arrow-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      :d="state.direction"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "ArrowIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    direction: {
      type: String,
      default: "right",
      validator(value) {
        return ["right", "left", "down", "up"].includes(value);
      },
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      direction: computed(() => {
        if (props.direction === "down") {
          return "M2.14645 5.14645C2.34171 4.95118 2.65829 4.95118 2.85355 5.14645L8 10.2929L13.1464 5.14645C13.3417 4.95118 13.6583 4.95118 13.8536 5.14645C14.0488 5.34171 14.0488 5.65829 13.8536 5.85355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L2.14645 5.85355C1.95118 5.65829 1.95118 5.34171 2.14645 5.14645Z";
        }
        if (props.direction === "up") {
          return "M2.14645 10.8536C2.34171 11.0488 2.65829 11.0488 2.85355 10.8536L8 5.70711L13.1464 10.8536C13.3417 11.0488 13.6583 11.0488 13.8536 10.8536C14.0488 10.6583 14.0488 10.3417 13.8536 10.1464L8.35355 4.64645C8.15829 4.45118 7.84171 4.45118 7.64645 4.64645L2.14645 10.1464C1.95118 10.3417 1.95118 10.6583 2.14645 10.8536Z";
        }
        if (props.direction === "left") {
          return "M10.8536 2.14645C11.0488 2.34171 11.0488 2.65829 10.8536 2.85355L5.70711 8L10.8536 13.1464C11.0488 13.3417 11.0488 13.6583 10.8536 13.8536C10.6583 14.0488 10.3417 14.0488 10.1464 13.8536L4.64645 8.35355C4.45118 8.15829 4.45118 7.84171 4.64645 7.64645L10.1464 2.14645C10.3417 1.95118 10.6583 1.95118 10.8536 2.14645Z";
        }
        return "M5.14645 2.39645C4.95118 2.59171 4.95118 2.90829 5.14645 3.10355L10.2929 8.25L5.14645 13.3964C4.95118 13.5917 4.95118 13.9083 5.14645 14.1036C5.34171 14.2988 5.65829 14.2988 5.85355 14.1036L11.3536 8.60355C11.5488 8.40829 11.5488 8.09171 11.3536 7.89645L5.85355 2.39645C5.65829 2.20118 5.34171 2.20118 5.14645 2.39645Z";
      }),
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
