import * as filestack from "filestack-js";

class FileStackService {
  constructor() {
    this.client = filestack.init(process.env.VUE_APP_FILE_STACK_API_KEY);
    this.options = {
      disableThumbnails: false, // true 일 경우 thumbnail 을 안보여준다.
      disableTransformer: false, // true: 이미지 셀릭트 후 이미지 크롭 못함
      displayMode: "overlay", // "overlay" :default / "inline", , "dropPane" : 엘리먼트 필요 // FIXME 뭔지 모르겠다.
      // dropPane: dropPaneElement.value, // FIXME
      exposeOriginalFile: true, // *** 원래파일 데이터를 보여준다. (이미지 저장하는 API call 할때 쓰는 file data)
      globalDropZone: true, // ??
      hideModalWhenUploading: false, // true: 업도드 시작하면 모달 닫음, false(default) : 엄도드 끝나면 모달 닫음
      // imageDim: [600, 500], // 이미지 가로,세로 비율 세팅 -> width 기준 [800, null], height 기준 [null, 300], 강제 [800, 600]
      // imageMax: [600, 600], // FIXME 이미지 최대 사이즈 -> 이거보다 큰 이미지는  resize 된다.
      // imageMin: [500, 500], // FIXME 이미지 최소사이즈 -> 이거보다 작은 이미지는 해당 사이즈로 resize 된다.
      maxFiles: 1, // 업로드할 수 있는 최대 파일 갯수 -> 쩐다...
      // minFiles: 2, // 업로드 해야하는 최소 파일 갯수
      startUploadingWhenMaxFilesReached: false, // true:파일갯수가 maxFile에 설정한 수와 같으면 바로 업로드를 한다.
      supportEmail: "romano@bigc.im",
      uploadInBackground: false, // disableTransformer: true 일 때만 사용가능! (default: true), 즉시 업로드 후 모달을 닫지 않는다.
      // videoResolution: String, // TODO 업로드하는 비디오의 해상도를 조절할 수 있다. -> 테스트 안해봄
      viewType: "list", // grid or list
      fromSources: ["local_file_system"], // socials
      accept: ["image/*"], // file type
      uploadConfig: {
        retry: 5,
        timeout: 60000,
        onProgress: (fileMetaData) => {
          console.log(fileMetaData);
        },
        onRetry: (fileMetaData) => {
          console.log(fileMetaData);
        },
      },
      onFileCropped: (fileMetaData) => {
        console.log(fileMetaData);
      },
      transformations: {
        crop: {
          aspectRatio: 800 / 1000, // 비율 지정 (가로 / 세로) - default 4 : 5
          force: true, // true : 업로드하기 전에 모든 이미지를 강제로 자릅니다.
        },
        circle: true,
        rotate: true,
      },
      // 업로드창 닫을때, onCancel, onClose 순으로 실행
      onCancel: () => {
        console.log("cancel");
      },
      // 6. uploadFinished 된 이후에 실행
      onClose: () => {
        console.log("close");
      },
      // 2 번 (파일셀렉터에서 파일 선택하고 )
      onFileSelected: (file) => {
        console.log("onFileSelected", file);
        // 업로드 에러 발생시 알럿으로 에러메세지를 보여줌.
        // 기본 Byte 단위
        // if (file.size > 20 * 1000000) {
        //   throw new Error("File too big, select something smaller than 5MB");
        // }
      },
      onFileUploadFailed: () => {
        console.log("file upload failed");
      },
      // 5. 업로드 완료되면 실행 (picker file meta data) => 콜백함수를 실행해야한다.
      onFileUploadFinished: (fileMetaData) => {
        console.log("file upload finished");
        console.log(fileMetaData);
        // this.callback({ `${this.paramKey}`: fileMetaData.url })
      },
      // 3. 업로드 클릭시 두번째로 실행 -> 업로드 시작될 때까지 계속 실행 / 4. 업로드 클릭시 네번쨰로실행 -> 업로드 끝날때까지 계속 실행
      onFileUploadProgress: () => {
        console.log("file upload process");
      },
      // 4. 업로드 클릭시 세번째로 실행
      onFileUploadStarted: () => {
        console.log("file upload start");
      },
      // 7. 창이 닫히고 나서 실행.
      onUploadDone: (res) => {
        console.log("upload done");
        console.log(res);
      },
      // 2. 업로드 버튼 누르면 가장먼저 실행
      onUploadStarted: () => {
        console.log("upload started");
      },
      // accept 옵션에따라서 예외처리가 필요할 경우
      acceptFn: (file, options) => {
        const mimeFromExtension = options.mimeFromExtension(
          file.originalFile.name
        );

        console.log("acceptFn");
        console.log(mimeFromExtension);
        if (
          options.acceptMime.length &&
          options.acceptMime.length &&
          !options.acceptMime.includes(mimeFromExtension) &&
          options.acceptMime[0] !== "image/*"
        ) {
          return Promise.reject("Cannot accept that file");
        }
        return Promise.resolve();
      },
    };
  }

  open(options) {
    console.log("options", options);
    this.client.picker(options).open();
  }
}

export default FileStackService;
