<template>
  <!-- todo html 재수정 필요 -->
  <div class="meeting-alert__modal">
    <div class="mo">
      <button @click="actions.toggleFoldModal()">
        <live-icon-animation></live-icon-animation>
      </button>
      <div v-if="!state.isFold" class="info" @click="actions.onBtnSSO()">
        <p class="sub-text-s2">{{ state.meeting.title }}</p>
        <button class="sub-title-s2">
          바로 입장하기 <arrow-icon></arrow-icon>
        </button>
      </div>
    </div>

    <div class="pc" :class="{ 'is-fold': state.isFold }">
      <div class="header" @click="actions.toggleFoldModal()">
        <live-icon-animation></live-icon-animation>
        <button v-if="!state.isFold" class="fold-btn">
          <arrow-icon
            width="20"
            height="20"
            fill-color="#818287"
            stroke-size="normal"
            direction="down"
          ></arrow-icon>
        </button>
      </div>

      <h3 v-if="!state.isFold">
        라이브가 <b>{{ state.displayTimer }}</b>
        <br />
        이후 시작됩니다.
      </h3>

      <div v-if="!state.isFold" class="info">
        <avatar :avatar-img="state.meeting.host.avatar" size="sm"></avatar>
        <div class="txt">
          <p class="sub-text-s2">{{ state.meeting.title }}</p>
          <span>
            <img src="/assets/images/svg/ic_clock_line_primary.svg" />
            {{ state.startTime }}
            ~
            {{ state.endTime }}
          </span>
        </div>
      </div>

      <button-basic
        v-if="!state.isFold"
        text="바로 입장하기"
        @action="actions.onBtnSSO()"
      ></button-basic>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, onBeforeUnmount, reactive, ref } from "vue";
import moment from "moment-timezone";
import SsoService from "@/services/SsoService";
import { useStore } from "vuex";
import { dateFormat, digitalWatch } from "@/helper/date.js";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import LiveIconAnimation from "@/components/console/icons/LiveIconAnimation.vue";
import Avatar from "@/components/console/avatars/Avatar.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "MeetingAlertModal",
  components: { ButtonBasic, Avatar, LiveIconAnimation, ArrowIcon },
  props: {},
  setup() {
    const store = useStore();

    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const timer = ref();

    const state = reactive({
      meeting: computed(() => {
        return store.getters["meetings/upcomingMeeting"];
      }),
      startAt: computed(() => {
        return moment(state.meeting.scheduledAt);
      }),
      countdownTimer: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      isFinished: false,
      displayTimer: computed(() => {
        return `${state.countdownTimer.hours}:${state.countdownTimer.minutes}:${state.countdownTimer.seconds}`;
      }),
      isFold: false,
      startTime: computed(() => {
        return moment(state.meeting.scheduledAt).format("HH:mm");
      }),
      endTime: computed(() => {
        return moment(state.meeting.scheduledFinishAt).format("HH:mm");
      }),
    });

    const countdownTimer = (ms) => {
      state.countdownTimer = {
        ...state.countdownTimer,
        ...{
          hours: digitalWatch(Math.floor(ms / _hour)),
          minutes: digitalWatch((ms % _hour) / _minute),
          seconds: digitalWatch((ms % _minute) / _second),
        },
      };
    };

    const displayTime = {
      beforeStart: (ms) => {
        state.isFinished = false;
        countdownTimer(ms);
      },
      afterStart: (ms) => {
        ms = ms * -1;
        state.isFinished = true;
        countdownTimer(ms);
      },
    };

    const calculateTime = () => {
      let now = moment();
      let distDt = moment.duration(state.startAt.diff(now));
      distDt > 0
        ? displayTime.beforeStart(distDt)
        : displayTime.afterStart(distDt);
    };

    onBeforeMount(() => {
      timer.value = setInterval(calculateTime, 1000);
    });

    onBeforeUnmount(() => {
      clearInterval(calculateTime);
    });

    const actions = {
      onBtnSSO: () => {
        const liveUrl = process.env.VUE_APP_LIVE_URL;
        const redirect = `${liveUrl}/live/${state.meeting.resourceId}`;
        const ssoEndPoint = `${liveUrl}/auth/sso`;

        SsoService.redirect(ssoEndPoint, redirect);
      },
      toggleFoldModal: () => {
        state.isFold = !state.isFold;
      },
    };

    return { state, actions, dateFormat };
  },
};
</script>

<style src="./style.css" scoped></style>
