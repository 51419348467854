import axios from "axios";
import store from "../store";

const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}/api/v1/coach`;

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const ApiConsole = axios.create({
  baseURL,
});

// Request interceptor
ApiConsole.interceptors.request.use(
  (request) => {
    const token = store.getters["auth/token"];
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    console.log(error);
  }
);

export default ApiConsole;
