import ApiService from "@/api";
import { ARTICLES } from "../mutation-types";
import _ from "lodash";

const getDefaultState = () => {
  return {
    articles: [],
    articlesMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
    article: {
      attachments: [],
      attachmentsCount: null,
      commentCount: 0,
      comments: [],
      community: {},
      content: "",
      contentHtml: "",
      contentText: "",
      createdAt: "",
      favoritersCount: 0,
      featuredImage: null,
      headline: null,
      id: 0,
      isFavorited: false,
      isLiked: false,
      isNotice: false,
      isPinned: false,
      isSecret: false,
      isShow: false,
      keywords: [],
      likersCount: 0,
      reportsCount: null,
      resourceId: "",
      status: 1,
      title: "",
      type: null,
      updatedAt: "",
      user: {},
    },
    clubArticles: [],
    clubArticlesMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
    clubArticle: {
      attachments: [],
      comments: [],
      content: "",
      contentHtml: "",
      createdAt: "",
      favoritersCount: 0,
      featuredImage: "",
      headline: null,
      id: 0,
      isFavorited: false,
      isLiked: false,
      keywords: null,
      likersCount: 0,
      resourceId: "",
      status: 0,
      title: "",
      type: null,
      updatedAt: "",
      user: {
        aliasName: "",
        avatar: "",
        externalUUID: "",
        id: 0,
        initial: "",
        name: "",
        resourceId: "",
      },
    },
  };
};

const state = getDefaultState();

const getters = {
  articles: (state) => {
    return state.articles;
  },
  articlesMeta: (state) => {
    return state.articlesMeta;
  },
  article: (state) => {
    return state.article;
  },
  currentArticlePage: (state) => {
    return state.articlesMeta.pagination.currentPage;
  },
  currentClubArticlePage: (state) => {
    return state.clubArticlesMeta.pagination.currentPage;
  },
};

const actions = {
  resetArticles({ commit }) {
    commit(ARTICLES.RESET_ARTICLES);
  },
  async getArticles(context, payload) {
    await ApiService.getArticles(payload).then((res) => {
      if (res.data.success) {
        context.commit(ARTICLES.SET_ARTICLES, res.data);
      }
    });
  },
  async getMoreArticles({ commit }, payload) {
    await ApiService.getArticles(payload).then((res) => {
      if (res.data.success) {
        commit(ARTICLES.ADD_ARTICLES, res.data);
      }
    });
  },

  async fetchArticles(context, payload) {
    const params = {
      ...payload,
      communityResourceId:
        context.rootGetters["communities/community"].resourceId,
    };
    await ApiService.getArticles(params).then(async (res) => {
      if (res.data.success) {
        await context.commit(ARTICLES.FETCH_ARTICLES, res.data);
      }
    });
  },
  async getArticle(context, payload) {
    await ApiService.getArticle(payload.articleResourceId).then(async (res) => {
      if (res.data.success) {
        await context.commit(ARTICLES.SET_ARTICLE, res.data.data);
      }
    });
  },
  postArticle({ dispatch }, payload) {
    ApiService.postArticles(payload).then(async (res) => {
      if (res.data.success) {
        dispatch("communities/getCommunities", {}, { root: true });
        await dispatch("fetchArticles");
      }
    });
  },
  async putArticle({ commit, dispatch }, payload) {
    await ApiService.putArticle(payload.articleResourceId, payload.params).then(
      async (res) => {
        if (res.data.success) {
          await commit(ARTICLES.SET_ARTICLE, res.data.data);
          dispatch("communities/getCommunities", {}, { root: true });
          dispatch("fetchArticles");
        }
      }
    );
  },
  async postArticleState({ dispatch }, payload) {
    await ApiService.postArticleState(
      payload.articleResourceId,
      payload.state
    ).then((res) => {
      if (res.data.success) {
        dispatch("fetchArticles");
      }
    });
  },
  uploadArticlesAttachment(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postAttachment(payload).then(
        (res) => {
          if (res.data.success) {
            resolve(res.data.data);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async deleteArticle({ dispatch }, payload) {
    await ApiService.deleteArticles(payload.articleResourceId).then((res) => {
      if (res.data.success) {
        dispatch("communities/getCommunities", {}, { root: true });
        dispatch("fetchArticles");
      }
    });
  },
  async deleteArticleAttachment({ commit, dispatch }, payload) {
    await ApiService.deleteAttachment(payload.attachmentResourceId).then(
      (res) => {
        if (res.data.success) {
          commit(ARTICLES.POP_ARTICLE_ATTACHMENTS, payload);
          dispatch("fetchArticles");
        }
      }
    );
  },
  postArticleLike({ dispatch }, payload) {
    ApiService.postLike(payload).then((res) => {
      if (res.data.success) {
        dispatch("fetchArticles");
      }
    });
  },
  postArticleFavorite({ dispatch }, payload) {
    ApiService.postFavorite(payload).then((res) => {
      if (res.data.success) {
        dispatch("fetchArticles");
      }
    });
  },
  /**
   * Club Article 관련 actions
   */
  getClubArticles({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getClubArticles(payload).then(
        async (res) => {
          if (res.data.success) {
            await commit(ARTICLES.SET_CLUB_ARTICLES, res.data);
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async getClubArticle({ commit }, payload) {
    await ApiService.getArticle(payload.articleResourceId).then(async (res) => {
      if (res.data.success) {
        await commit(ARTICLES.SET_CLUB_ARTICLE, res.data.data);
      }
    });
  },
  async fetchClubArticles(context, payload) {
    await ApiService.getClubArticles(payload).then(async (res) => {
      if (res.data.success) {
        await context.commit(ARTICLES.FETCH_CLUB_ARTICLES, res.data);
      }
    });
  },
  async postClubArticle({ commit, dispatch }, payload) {
    await ApiService.postArticles(payload).then(async (res) => {
      if (res.data.success) {
        await commit(ARTICLES.ADD_CLUB_ARTICLE, res.data.data);
        dispatch("fetchClubArticles", {
          clubResourceId: payload.clubResourceId,
        });
      }
    });
  },
  async putClubArticle({ commit, dispatch }, payload) {
    await ApiService.putArticle(payload.articleResourceId, payload.params).then(
      async (res) => {
        if (res.data.success) {
          await commit(ARTICLES.SET_CLUB_ARTICLE, res.data.data);
          dispatch("fetchClubArticles", {
            clubResourceId: payload.clubResourceId,
          });
        }
      }
    );
  },
  async deleteClubArticle({ commit, dispatch }, payload) {
    await ApiService.deleteArticles(payload.articleResourceId).then(
      async (res) => {
        if (res.data.success) {
          await commit(ARTICLES.POP_CLUB_ARTICLE, {
            articleResourceId: payload.articleResourceId,
          });
          dispatch("fetchClubArticles", {
            clubResourceId: payload.clubResourceId,
          });
        }
      }
    );
  },
  async deleteClubArticleAttachment({ commit, dispatch, rootState }, payload) {
    await ApiService.deleteAttachment(payload).then((res) => {
      if (res.data.success) {
        commit(ARTICLES.POP_CLUB_ARTICLE_ATTACHMENTS, payload);
        dispatch("fetchClubArticles", {
          clubResourceId: rootState.clubs.club.resourceId,
        });
      }
    });
  },
  postClubArticleLike({ dispatch, rootState }, payload) {
    ApiService.postLike(payload).then((res) => {
      if (res.data.success) {
        dispatch("fetchClubArticles", {
          clubResourceId: rootState.clubs.club.resourceId,
        });
      }
    });
  },
};

const mutations = {
  [ARTICLES.RESET_ARTICLES](state) {
    Object.assign(state, getDefaultState());
  },
  [ARTICLES.SET_ARTICLES](state, payload) {
    // todo 게시글을 생성하면 값이 두번 들어감
    // state.articles.push(..._.cloneDeep(payload.data));
    state.articles = payload.data;
    state.articlesMeta = {
      ..._.cloneDeep(state.articlesMeta),
      ..._.cloneDeep(payload.meta),
    };
  },

  [ARTICLES.SET_ARTICLE](state, payload) {
    state.article = { ..._.cloneDeep(state.article), ..._.cloneDeep(payload) };
  },
  [ARTICLES.FIND_SET_ARTICLE](state, payload) {
    const article = state.articles.find((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.article = { ..._.cloneDeep(state.article), ..._.cloneDeep(article) };
  },
  [ARTICLES.FETCH_ARTICLES](state, payload) {
    state.articles = _.cloneDeep(payload.data);
    state.articlesMeta = {
      ..._.cloneDeep(state.articlesMeta),
      ..._.cloneDeep(payload.meta),
    };
  },
  [ARTICLES.POP_ARTICLE_ATTACHMENTS](state, payload) {
    state.article.attachments = state.article.attachments.filter((item) => {
      return item.resourceId !== payload;
    });
  },
  [ARTICLES.UPDATE_ARTICLE_PREV_LIKE](state) {
    state.article.isLiked = !state.article.isLiked;
    state.article.isLiked
      ? state.article.likersCount++
      : state.article.likersCount--;
  },
  [ARTICLES.SET_CLUB_ARTICLES](state, payload) {
    state.clubArticles.push(..._.cloneDeep(payload.data));
    state.clubArticlesMeta = {
      ..._.cloneDeep(state.clubArticlesMeta),
      ..._.cloneDeep(payload.meta),
    };
  },
  [ARTICLES.ADD_CLUB_ARTICLE](state, payload) {
    state.clubArticles.unshift(payload);
  },
  [ARTICLES.FETCH_CLUB_ARTICLES](state, payload) {
    state.clubArticles = _.cloneDeep(payload.data);
    state.clubArticlesMeta = {
      ..._.cloneDeep(state.clubArticlesMeta),
      ..._.cloneDeep(payload.meta),
    };
  },
  [ARTICLES.FIND_SET_CLUB_ARTICLE](state, payload) {
    const article = state.clubArticles.find((item) => {
      return item.resourceId === payload.resourceId;
    });
    state.clubArticle = {
      ..._.cloneDeep(state.clubArticle),
      ..._.cloneDeep(article),
    };
  },
  [ARTICLES.POP_CLUB_ARTICLE](state, payload) {
    state.clubArticles = state.clubArticles.filter((item) => {
      return item.resourceId !== payload.articleResourceId;
    });
  },
  [ARTICLES.SET_CLUB_ARTICLE](state, payload) {
    state.clubArticle = {
      ..._.cloneDeep(state.clubArticle),
      ..._.cloneDeep(payload),
    };
  },
  [ARTICLES.POP_CLUB_ARTICLE_ATTACHMENTS](state, payload) {
    state.clubArticle.attachments = state.clubArticle.attachments.filter(
      (item) => {
        return item.resourceId !== payload;
      }
    );
  },
  [ARTICLES.UPDATE_CLUB_ARTICLE_PREV_LIKE](state) {
    state.clubArticle.isLiked = !state.clubArticle.isLiked;
    state.clubArticle.isLiked
      ? state.clubArticle.likersCount++
      : state.clubArticle.likersCount--;
  },
  [ARTICLES.UPDATE_CLUB_ARTICLE_LIKE](state, payload) {
    state.clubArticle.isLiked = payload.isLiked;
    state.clubArticle.likersCount = payload.likersCount;
  },
  [ARTICLES.ADD_ARTICLES](state, payload) {
    state.articles = [...state.articles, ...payload.data];
    state.articlesMeta = {
      ...state.articlesMeta,
      ...payload.meta,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
