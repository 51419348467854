// https://developers.channel.io/docs/web-installation

import store from "../store";
import ApiService from "@/api";
import helper from "@/helper";

class ChannelIOService {
  constructor() {
    // 모바일에서 channel IO 제거
    if (!helper.isMobile()) {
      this.loadScript();
    }
  }

  loadScript() {
    var w = window;
    if (w.ChannelIO) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return (window.console.error || window.console.log || function () {})(
        "ChannelIO script included twice."
      );
    }
    // var d = window.document;
    var ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
      s.charset = "UTF-8";
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === "complete") {
      l();
    } else if (window.attachEvent) {
      window.attachEvent("onload", l);
    } else {
      window.addEventListener("DOMContentLoaded", l, false);
      window.addEventListener("load", l, false);
    }
  }

  boot(settings) {
    window.ChannelIO("boot", settings);
  }

  async start() {
    const user = store.getters["auth/user"];

    let settings = {
      pluginKey: process.env.VUE_APP_CHANNEL_TALK_KEY,
      hideChannelButtonOnBoot: false,
      customLauncherSelector: ".channel-io-launcher",
    };

    if (user) {
      const { data } = await ApiService.getUserContacts();
      const userContacts = data.data;

      settings.memberId = userContacts.resourceId;
      settings.profile = {
        name: userContacts.name,
        avatarUrl: userContacts.avatar,
        email: userContacts.email,
      };
      if (userContacts.phone) {
        settings.profile.mobileNumber = userContacts.phone;
      }
    }
    window.ChannelIO("boot", settings);
  }

  shutdown() {
    window.ChannelIO("shutdown");
  }

  showMessenger() {
    window.ChannelIO("showMessenger");
  }
  hideMessenger() {
    window.ChannelIO("hideMessenger");
  }
}

export default new ChannelIOService();
