import ApiService from "@/api";
import { CHANNELS } from "../mutation-types";
import _ from "lodash";

const getDefaultState = () => {
  return {
    channels: [],
    channel: {
      code: "",
      counts: { subscriber: null, post: null, view: null },
      post: null,
      subscriber: null,
      view: null,
      createdAt: "",
      description: null,
      descriptionHtml: "",
      featuredImage: null,
      gatheredAt: null,
      identifier: null,
      isGather: false,
      isSync: false,
      resourceId: "",
      status: 0,
      syncedAt: null,
      title: null,
      type: 0,
      url: "",
    },
    constChannels: [],
  };
};

const state = getDefaultState();

const getters = {
  channels: (state) => {
    return state.channels;
  },
  channel: (state) => {
    return state.channel;
  },
  constChannels: (state) => state.constChannels,
};

const actions = {
  resetChannels({ commit }) {
    commit(CHANNELS.RESET_CHANNELS);
  },
  async getChannels({ commit }, payload) {
    await ApiService.getChannels(payload).then(async (res) => {
      if (res.data.success) {
        await commit(CHANNELS.SET_CHANNELS, res.data.data);
      }
    });
  },

  async postChannel({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postChannel(payload).then(
        async (res) => {
          if (res.data.success) {
            await commit(CHANNELS.ADD_CHANNELS, res.data.data);
            await dispatch("getChannels");
            resolve(res);
          } else {
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  async putChannel({ dispatch }, payload) {
    return ApiService.putChannel(payload.channelResourceId, payload.data).then(
      async (res) => {
        if (res) {
          await dispatch("getChannels");
        }
      }
    );
  },
  async updateChannelsOrder({ dispatch }, payload) {
    await ApiService.postChannelResort(payload).then(async (res) => {
      if (res.data.success) {
        await dispatch("getChannels");
      }
    });
  },

  async postChannelMeta({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postChannelMeta(payload.channelResourceId).then(
        async (res) => {
          if (res.data.success) {
            await commit(CHANNELS.UPDATE_CHANNEL, res.data.data);
            dispatch("getChannels");
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  async postChannelGather({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postChannelGather(payload.channelResourceId).then(
        async (res) => {
          if (res.data.success) {
            dispatch("getChannels");
            resolve(res);
          } else {
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  async deleteChannel({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.deleteChannel(payload.channelResourceId).then(
        async (res) => {
          if (res.data.success) {
            await commit(CHANNELS.POP_CHANNELS, {
              channelResourceId: payload.channelResourceId,
            });
            await dispatch("getChannels");
            resolve(res);
          } else {
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async getConstChannels({ commit }) {
    await ApiService.getConstChannels().then(async (res) => {
      if (res.status === 200) {
        await commit(CHANNELS.SET_CONST_CHANNEL, res.data);
      }
    });
  },
};

const mutations = {
  [CHANNELS.SET_CHANNELS](state, payload) {
    state.channels = _.cloneDeep(payload);
  },
  [CHANNELS.UPDATE_CHANNELS](state, payload) {
    state.channels = {
      ..._.cloneDeep(state.channels),
      ..._.cloneDeep(payload),
    };
  },
  [CHANNELS.UPDATE_CHANNEL](state, payload) {
    const index = state.channels.findIndex((item) => {
      return item.resourceId === payload.resourceId;
    });

    state.channels[index] = {
      ..._.cloneDeep(state.channels[index]),
      ..._.cloneDeep(payload),
    };
  },
  [CHANNELS.POP_CHANNELS](state, payload) {
    state.channels = state.channels.filter((item) => {
      return item.resourceId !== payload.channelResourceId;
    });
  },
  [CHANNELS.ADD_CHANNELS](state, payload) {
    state.channels.unshift(payload);
  },
  [CHANNELS.RESET_CHANNELS](state) {
    Object.assign(state, getDefaultState());
  },
  [CHANNELS.SET_CONST_CHANNEL](state, payload) {
    state.constChannels = [...payload];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
