<template>
  <div class="prefix-input__wrapper">
    <span
      v-if="fixedValue"
      ref="fixedValueElement"
      class="prefix b-text1"
      :style="fixedValueStyle"
    >
      {{ fixedValue }}
    </span>
    <input
      ref="prefixInput"
      v-model="state.inputValue"
      class="b-text1"
      type="text"
      :placeholder="placeholder"
      @input="actions.updateData($event)"
      @blur="isFocusOutEvent ? actions.submit() : ''"
    />
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";

export default {
  name: "InputPrefix",
  props: {
    placeholder: {
      type: String,
      default: "https://",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    fixedValue: {
      type: String,
      default: "",
    },
    fixedValueStyle: {
      type: Object,
      required: false,
    },
    isFocusOutEvent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit", "updateData"],
  setup(props, { emit }) {
    const prefixInput = ref();
    const fixedValueElement = ref();

    const state = reactive({
      inputValue: "",
    });

    watch(
      () => props.defaultValue,
      (value) => {
        updateInputValue(value);
      }
    );

    const updateInputValue = (value) => {
      state.inputValue = value;
      //NOTE 기존로직
      // if (!props.fixedValue) {
      //   state.inputValue = value;
      // }
    };

    onMounted(() => {
      updateInputValue(props.defaultValue);

      if (props.fixedValue) {
        prefixInput.value.style.paddingLeft = `${
          fixedValueElement.value.clientWidth + 30
        }px`;
      }
    });

    const actions = {
      updateData: (e) => {
        emit("updateData", e.target.value);
      },
      submit: () => {
        emit("submit");
      },
    };

    return { state, actions, prefixInput, fixedValueElement };
  },
};
</script>

<style scoped>
.prefix-input__wrapper {
  position: relative;
  flex: 1;
}

.prefix-input__wrapper .prefix {
  position: absolute;
  top: 50%;
  transform: translate(16px, -50%);
}

.prefix-input__wrapper input {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 14px 16px;
  width: 100%;
}

.prefix-input__wrapper input::placeholder {
  color: #d0d5de;
}

.prefix-input__wrapper input:focus {
  border: 1px solid #9ea0a6;
}
</style>
