<template>
  <icon-base
    icon-name="edit-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.39238 12.7317H3.26834V10.6076L8.57094 5.30502L10.695 7.42906L5.39238 12.7317ZM11.4151 6.70898L12.5208 5.60328L10.3967 3.47924L9.29102 4.58494L11.4151 6.70898ZM2.25 13.2408V10.3967C2.25 10.2617 2.30364 10.1322 2.39913 10.0367L10.0367 2.39913C10.2355 2.20029 10.5579 2.20029 10.7568 2.39913L13.6009 5.24325C13.7997 5.44209 13.7997 5.76448 13.6009 5.96332L5.96332 13.6009C5.86783 13.6964 5.73832 13.75 5.60328 13.75H2.75917C2.47796 13.75 2.25 13.522 2.25 13.2408Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>

    <template v-if="fill">
      <g clip-path="url(#clip0_51_2562)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 13.2538V10.3967C2.25 10.2641 2.30268 10.1369 2.39645 10.0432L10.0432 2.39645C10.2384 2.20118 10.555 2.20118 10.7503 2.39645L13.6073 5.25352C13.8026 5.44879 13.8026 5.76537 13.6073 5.96063L5.96063 13.6073C5.86686 13.7011 5.73969 13.7538 5.60708 13.7538H2.75C2.47386 13.7538 2.25 13.5299 2.25 13.2538ZM9.66068 4.64652C9.46542 4.45126 9.14884 4.45126 8.95358 4.64652C8.75831 4.84178 8.75831 5.15837 8.95358 5.35363L10.6506 7.05069C10.8459 7.24595 11.1625 7.24595 11.3577 7.05069C11.553 6.85542 11.553 6.53884 11.3577 6.34358L9.66068 4.64652Z"
          :fill="fillColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_51_2562">
          <rect
            width="11.5038"
            height="11.5038"
            fill="white"
            transform="translate(2.25 2.25)"
          />
        </clipPath>
      </defs>
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "EditIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#ffffff",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
