<template>
  <icon-base
    icon-name="community-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5 7.5C7.60457 7.5 8.5 6.60457 8.5 5.5C8.5 4.39543 7.60457 3.5 6.5 3.5C5.39543 3.5 4.5 4.39543 4.5 5.5C4.5 6.60457 5.39543 7.5 6.5 7.5ZM9.5 5.5C9.5 6.44182 9.066 7.28227 8.38708 7.83228C10.4951 8.60228 12 10.6254 12 13C12 13.2761 11.7761 13.5 11.5 13.5C11.2239 13.5 11 13.2761 11 13C11 10.5147 8.98528 8.5 6.5 8.5C4.01472 8.5 2 10.5147 2 13C2 13.2761 1.77614 13.5 1.5 13.5C1.22386 13.5 1 13.2761 1 13C1 10.6254 2.50485 8.60228 4.61292 7.83228C3.934 7.28227 3.5 6.44182 3.5 5.5C3.5 3.84315 4.84315 2.5 6.5 2.5C8.15685 2.5 9.5 3.84315 9.5 5.5ZM10.2181 4.16257C10.4323 4.05873 10.6739 4 10.9311 4C11.8282 4 12.5427 4.71458 12.5427 5.5805C12.5427 6.37966 11.8451 7 10.875 7C10.5989 7 10.375 7.22386 10.375 7.5C10.375 7.77614 10.5989 8 10.875 8C11.4578 8 12.2108 8.3301 12.8295 8.96481C13.4381 9.58917 13.8751 10.4679 13.8751 11.5C13.8751 11.7761 14.0989 12 14.3751 12C14.6512 12 14.8751 11.7761 14.8751 11.5C14.8751 10.1742 14.3119 9.05293 13.5456 8.26677C13.2436 7.95699 12.9032 7.69262 12.5449 7.4868C13.1384 7.06985 13.5427 6.41474 13.5427 5.5805C13.5427 4.14835 12.3664 3 10.9311 3C10.5199 3 10.1294 3.09424 9.78187 3.26275C9.53339 3.38322 9.42962 3.68231 9.55009 3.93079C9.67056 4.17927 9.96965 4.28304 10.2181 4.16257Z"
        :fill="fillColor"
      />
    </template>

    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5 3.5C8.5 4.6736 7.82611 5.68979 6.84417 6.18275C8.95276 6.76986 10.5 8.70424 10.5 11H0.5C0.5 8.70424 2.04724 6.76986 4.15583 6.18275C3.17389 5.6898 2.5 4.6736 2.5 3.5C2.5 1.84315 3.84315 0.5 5.5 0.5C7.15685 0.5 8.5 1.84315 8.5 3.5ZM9.21813 2.16257C9.43231 2.05873 9.67392 2 9.93111 2C10.8282 2 11.5427 2.71458 11.5427 3.5805C11.5427 4.37966 10.8451 5 9.875 5C9.59886 5 9.375 5.22386 9.375 5.5C9.375 5.77614 9.59886 6 9.875 6C10.4578 6 11.2108 6.3301 11.8295 6.96481C12.4381 7.58917 12.8751 8.4679 12.8751 9.5C12.8751 9.77614 13.0989 10 13.3751 10C13.6512 10 13.8751 9.77614 13.8751 9.5C13.8751 8.17421 13.3119 7.05293 12.5456 6.26677C12.2436 5.95699 11.9032 5.69262 11.5449 5.4868C12.1384 5.06985 12.5427 4.41474 12.5427 3.5805C12.5427 2.14835 11.3664 1 9.93111 1C9.51993 1 9.12943 1.09424 8.78187 1.26275C8.53339 1.38322 8.42962 1.68231 8.55009 1.93079C8.67056 2.17927 8.96965 2.28304 9.21813 2.16257Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";

export default {
  name: "CommunityIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
