import ApiService from "@/api";
import { DASHBOARD } from "../mutation-types";
import _ from "lodash";

const getDefaultState = () => {
  return {
    dashboard: {
      articles: [],
      clubs: [],
      meetings: [],
      notices: [],
      paidUsersRank: [],
      stats: {
        articles: { total: 0 },
        comments: { total: 0 },
        connects: { total: 0 },
        countries: [],
        earnings: { total: 0 },
        paidUsers: { total: 0 },
        salesTickets: { total: 0 },
      },
    },
    graphData: {
      endDay: "",
      month: "",
      payments: [],
      startDay: "",
      year: "",
    },
  };
};

const state = getDefaultState();

const getters = {
  dashboard: (state) => {
    return state.dashboard;
  },
  graphData: (state) => {
    return state.graphData;
  },
};

const actions = {
  resetDashboard({ commit }) {
    commit(DASHBOARD.RESET_DASHBOARD);
  },
  async getDashboard(context) {
    await ApiService.getUserDashboard().then(async (res) => {
      if (res.data.success) {
        await context.commit(DASHBOARD.GET_DASHBOARD, res.data.data);
      }
    });
  },
  async getUserCurrentMonthEarningGraph({ commit }) {
    await ApiService.getUserCurrentMonthEarningGraph().then(async (res) => {
      if (res.status === 200) {
        await commit(DASHBOARD.GET_DASHBOARD_GRAPH_DATA, res.data);
      }
    });
  },
  async getUserSelectedMonthEarningGraph({ commit }, params) {
    await ApiService.getUserSelectedMonthEarningGraph(params).then((res) => {
      if (res.status === 200) {
        commit(DASHBOARD.GET_DASHBOARD_GRAPH_DATA, res.data);
      }
    });
  },
  async getUserConnected({ commit }, payload) {
    await ApiService.getUserConnected(payload).then((res) => {
      if (res.data.success) {
        commit(DASHBOARD.SET_CONNECTS, res.data);
      }
    });
  },
  async getArticles(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getArticles(payload).then(
        (res) => {
          if (res.data.success) {
            context.commit(DASHBOARD.SET_ARTICLES, res.data);
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  [DASHBOARD.RESET_DASHBOARD](state) {
    Object.assign(state, getDefaultState());
  },
  [DASHBOARD.GET_DASHBOARD](state, payload) {
    state.dashboard = {
      ..._.cloneDeep(state.dashboard),
      ..._.cloneDeep(payload),
    };
  },
  [DASHBOARD.GET_DASHBOARD_GRAPH_DATA](state, payload) {
    state.graphData = {
      ..._.cloneDeep(state.graphData),
      ..._.cloneDeep(payload),
    };
  },
  [DASHBOARD.SET_CONNECTS](state, payload) {
    state.dashboard.connectedUsers = payload.data;
  },
  [DASHBOARD.SET_ARTICLES](state, payload) {
    state.dashboard.articles = payload.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
