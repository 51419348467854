<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    fill="none"
  >
    <title :id="iconName" lang="en">{{ iconName }}</title>
    <g :fill="iconColor" :clip-path="clipPath">
      <slot></slot>
    </g>
    <defs>
      <slot name="defs"></slot>
    </defs>
  </svg>
</template>

<script>
/**
 * props 설명
 * width, height : svg 가 그려지는 영역,
 * viewBox: SVG 영역까지 확대, 축소, 위치지정, 분할 하는 역할, (ex: width:100, height: 100, viewBox: '0 0 50 50' 인경우 svg 아이콘 50의 크기를 width 100 에 맞춰 두배로 확장시켜준다.)
 */
export default {
  name: "IconBase",
  props: {
    viewBox: {
      type: String,
      default: "0 0 24 24",
    },
    iconName: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: "",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    clipPath: {
      type: String,
      default: "",
    },
  },
};
</script>
