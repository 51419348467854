
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  reactive,
  defineComponent,
} from "vue";
import ChannelIOService from "../../services/ChannelIOService.js";
import VideoUploader from "../../components/console/modals/VideoUploader.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { VideoUploader },
  setup() {
    const store = useStore();

    const state = reactive({
      showSideNav: true,
      existUploadingFile: computed(() => {
        return store.getters["file/existUploadingFile"];
      }),
    });

    onBeforeMount(() => {
      document.getElementById("app").classList.add("basic-layout");
    });

    onMounted(() => {
      if (window.ChannelIO) {
        ChannelIOService.start();
      }
    });

    onUnmounted(() => {
      document.getElementById("app").classList.remove("basic-layout");
    });

    return { state };
  },
});
