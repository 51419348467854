import ApiService from "@/api";
import { CONNECTS } from "../mutation-types";
import _ from "lodash";

const getDefaultState = () => {
  return {
    connectedUsers: [],
    connectedUsersMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
      dashboard: {
        membershipUserCount: null,
        paidUserCount: 0,
        paidUserReport: 0,
        followerCount: 0,
      },
      memberType: {
        // 1: "멤버십",
        2: "팔로워",
        3: "유료 구매자",
      },
    },
  };
};

const state = getDefaultState();

const getters = {
  connectedUsers: (state) => {
    return state.connectedUsers;
  },
  connectedUsersMeta: (state) => {
    return state.connectedUsersMeta;
  },
  currentPage: (state) => {
    return state.connectedUsersMeta.pagination.currentPage;
  },
};

const actions = {
  resetConnects({ commit }) {
    commit(CONNECTS.RESET_CONNECTS);
  },
  async getUserConnected({ commit }, payload) {
    await ApiService.getUserConnected(payload).then((res) => {
      if (res.data.success) {
        commit(CONNECTS.SET_CONNECTS, res.data);
      }
    });
  },
  async getUserMembers({ commit }, payload) {
    await ApiService.getUserMembers(payload).then((res) => {
      if (res.data.success) {
        commit(CONNECTS.SET_CONNECTS, res.data);
      }
    });
  },
};

const mutations = {
  [CONNECTS.SET_CONNECTS](state, payload) {
    state.connectedUsers = _.cloneDeep(payload.data);
    state.connectedUsersMeta = {
      ...state.connectedUsersMeta,
      ...payload.meta,
    };
    state.pagination = _.cloneDeep(state.connectedUsersMeta).pagination;
  },
  [CONNECTS.RESET_CONNECTS](state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
