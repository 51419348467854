
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
  defineComponent,
} from "vue";
import DrawerIcon from "../../components/console/icons/DrawerIcon.vue";
import SideNavNew from "./partials/SideNavNew.vue";
import ChannelIOService from "../../services/ChannelIOService.js";
import { useStore } from "vuex";
import helper from "@/helper/index.js";
import { useRoute } from "vue-router";
import onBoardingConst from "../../consts/onboarding.js";
import MeetingAlertModal from "@/layouts/console/partials/MeetingAlertModal/MeetingAlertModal.vue";
import OnBoardingModal from "@/pages/console/OnBoardingModal/OnBoardingModal.vue";
import LectureAlertModal from "@/layouts/console/partials/LectureAlertModal/LectureAlertModal.vue";
const Cookies = require("js-cookie");
import ApiService from "@/api";
import moment from "moment-timezone";

export default defineComponent({
  name: "NewLayout",
  components: {
    LectureAlertModal,
    OnBoardingModal,
    MeetingAlertModal,
    SideNavNew,
    DrawerIcon,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const studioLayout = ref();
    const sideNav = ref();
    const openGnbBtn = ref();
    const pageWrapper = ref();

    const state = reactive({
      innerScrollPage: computed(() => {
        return (
          route.name === "console.articles" ||
          route.name === "console.labs.Sticky" ||
          route.name === "console.openHourReview"
        );
      }),
      onBoardingStep: computed(() => {
        return store.getters["auth/user"].userProfile.onBoardingStep;
      }),
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      showMeetingAlertModal: computed(() => {
        return !!store.getters["meetings/upcomingMeeting"].resourceId;
      }),
      showOnBoardingModal: false,
      showLectureAlertModal: false,
      upcomingLecture: computed(() => {
        return store.getters["lectures/upcomingLecture"];
      }),
      upcomingLectureMeetingId: "",
      // note  position: sticky 을 요구하는 디자인 있는 경우 사용, 페이지 route.name 으로 position: sticky를 사용하는지 안하는지 구분.
      isStickyLayout: computed(() => {
        let routeName = String(route.name);
        return (
          routeName === "console.ent.clubs.show" ||
          routeName.includes("console.brand")
        );
      }),
    });

    watch(
      () => state.innerScrollPage,
      (value) => {
        if (value) {
          pageWrapper.value.classList.add("inner-scroll");
        } else {
          pageWrapper.value.classList.remove("inner-scroll");
        }
      }
    );

    // let pollingGetMeeting;
    // let pollingGetLecture;

    const closeNav = (e) => {
      if (
        helper.isTabletSize() &&
        !sideNav.value.contains(e.target) &&
        !openGnbBtn.value.contains(e.target)
      ) {
        studioLayout.value.classList.remove("nav-open");
      }
    };

    onBeforeMount(async () => {
      if (state.onBoardingStep !== onBoardingConst.onBoardingSteps.finish) {
        await store.dispatch("onBoardings/getOnBoardingStep").then(async () => {
          await store.dispatch("space/getSpaceChannels").then(() => {
            state.showOnBoardingModal = true;
          });
        });
      }

      // NOTE 기존 온보딩 로직 router/index.ts 에서 사용했었음
      // const lastOnBoardingStep =
      //   store.getters["auth/user"].userProfile.onBoardingStep;
      //
      // if (
      //   getAppName() === "console" &&
      //   lastOnBoardingStep !== onBoardingConst.onBoardingSteps.finish &&
      //   isAuth &&
      //   !routerToName.includes("console.onboarding")
      // ) {
      //   if (lastOnBoardingStep) {
      //     const stepName = onBoardingConst.stepNames[lastOnBoardingStep];
      //
      //     return next({
      //       name: `console.onboarding.${stepName}`,
      //     });
      //   } else {
      //     return next({
      //       name: `console.onboarding`,
      //     });
      //   }
      // }
    });

    onMounted(() => {
      if (state.innerScrollPage) {
        pageWrapper.value.classList.add("inner-scroll");
      } else {
        pageWrapper.value.classList.remove("inner-scroll");
      }

      window.addEventListener("click", (e) => {
        closeNav(e);
      });

      document.getElementById("app").classList.add("console");
      if (window.ChannelIO) {
        ChannelIOService.start();
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("click", (e) => {
        closeNav(e);
      });
      document.getElementById("app").classList.remove("console");
      // clearInterval(pollingGetMeeting);
      // clearInterval(pollingGetLecture);
    });

    const setCookieOfLectureAlertModal = () => {
      let exDate = new Date();
      exDate.setMinutes(exDate.getMinutes() + 10);
      Cookies.set("hideLectureAlertModal", true, { expires: exDate });
    };

    const actions = {
      openNav: () => {
        studioLayout.value.classList.add("nav-open");
      },
      closeSideNav: () => {
        if (helper.isTabletSize()) {
          studioLayout.value.classList.remove("nav-open");
        }
      },
      closeOnBoardingModal: () => {
        state.showOnBoardingModal = false;
      },
      closeLectureAlertModal: () => {
        state.showLectureAlertModal = false;
        setCookieOfLectureAlertModal();
        store.dispatch("lectures/resetUpcomingLecture");
      },
    };

    return { studioLayout, sideNav, openGnbBtn, pageWrapper, state, actions };
  },
});
