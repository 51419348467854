<template>
  <div class="profile-section">
    <div class="profile-input-area">
      <h2 class="title">크리에이터님의 정보를 <br class="mo" />알려주세요</h2>
      <p class="sub-text-s2 text-gray-second desc">
        브랜드 홈에서 유저들에게 보이는 정보입니다.<br />
        크리에이터님에 대해 간단히 소개해 주세요.
      </p>

      <div class="user-avatar-area">
        <div class="user-avatar" @click="actions.openFileStack()">
          <avatar :avatar-img="state.featuredImage" size="100"></avatar>
          <div class="edit-icon">
            <edit-icon stroke-size="nomal"></edit-icon>
          </div>
        </div>
      </div>

      <div>
        <div class="creator-name">
          <input-basic
            placeholder="활동명 혹은 채널명을 입력해주세요"
            label="크리에이터명"
            :required="true"
            :default-value="state.creatorName"
            :max-length="20"
            :caption="`${state.creatorName.length}/20`"
            @updateData="(value) => actions.updateCreatorName(value)"
          ></input-basic>
        </div>
        <div class="creator-introduction">
          <!-- NOTE v-model에 emit 기능 적용하기 https://v3-migration.vuejs.org/breaking-changes/v-model.html-->
          <input-text-box
            label="간단한 소개 및 활동 계획"
            placeholder="크리에이터님에 대한 소개와 활동 계획을 입력해주세요
오른쪽 브랜드 홈에서 두 줄로 나오는게 가장 예쁘게 보여요
"
            :max-length="500"
            :rows="4"
            :height-resize="false"
            :border-none="false"
            :model-value="state.description"
            @update:modelValue="
              (value) => {
                actions.updateCreatorDescription(value);
              }
            "
          ></input-text-box>
        </div>
      </div>
    </div>

    <button-basic
      text="다음"
      :disabled="state.saveBtnDisabled"
      @action="actions.goToInterest()"
    ></button-basic>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import InputTextBox from "../../../../components/console/inputs/InputTextBox";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import FileStackService from "@/services/FileStackService";
import Avatar from "@/components/console/avatars/Avatar.vue";
import EditIcon from "@/components/console/icons/EditIcon";
import ApiService from "@/api";

export default {
  name: "OnBoardingProfile",
  components: { EditIcon, Avatar, ButtonBasic, InputTextBox, InputBasic },
  props: {
    avatarImg: {
      type: String,
      default: "",
    },
    creatorName: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  emits: [
    "updateAvatar",
    "updateCreatorName",
    "updateCreatorDescription",
    "complete",
  ],
  setup(props, { emit }) {
    const store = useStore();
    let userOriginalImage = "";

    const state = reactive({
      creatorName: "",
      description: "",
      featuredImage: computed(() => {
        return props.avatarImg;
      }),
      saveBtnDisabled: computed(() => {
        return state.creatorName.trim().length === 0;
      }),
    });

    onBeforeMount(() => {
      state.creatorName = props.creatorName ? props.creatorName : "";
      state.description = props.description ? props.description : "";
      userOriginalImage = props.avatarImg ? props.avatarImg : "";
    });

    const actions = {
      goToInterest: () => {
        let formData = new FormData();
        if (userOriginalImage !== state.featuredImage) {
          formData.append("avatar", state.featuredImage);
        }
        formData.append("displayName", state.creatorName);
        formData.append("description", state.description);
        store
          .dispatch("onBoardings/postOnBoardingProfile", { data: formData })
          .then(() => {
            emit("complete");
          });
      },
      openFileStack: () => {
        const fileStack = new FileStackService();

        fileStack.options.transformations.crop = false;
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          const formData = new FormData();
          formData.append("file", fileMetaData.url);
          ApiService.postAttachment(formData).then((res) => {
            if (res.data.success) {
              let avatarImage = res.data.data.url;
              emit("updateAvatar", avatarImage);
            }
          });
        };

        fileStack.open(fileStack.options);
      },
      updateCreatorName: (value) => {
        state.creatorName = value;
        emit("updateCreatorName", value);
      },
      updateCreatorDescription: (value) => {
        state.description = value;
        emit("updateCreatorDescription", value);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
