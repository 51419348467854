import ApiService from "@/api";
import { MEETINGS } from "../mutation-types";
import _ from "lodash";

const getDefaultState = () => {
  return {
    meetings: [],
    meetingsMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
      statuses: [
        { status: null, label: "전체", count: 0 },
        { status: 0, label: "예정", count: 0 },
        { status: 1, label: "대기", count: 0 },
        { status: 3, label: "시작", count: 0 },
        { status: 5, label: "종료", count: 0 },
        { status: 9, label: "삭제됨", count: 0 },
      ],
    },
    meeting: {
      resourceId: null,
      status: 0,
      type: "seminar",
      headline: "",
      title: "",
      description: "",
      featuredImage: "",
      scheduledAt: "",
      scheduledFinishAt: "",
      isPublished: true,
      publishedAt: "",
    },
    upcomingMeeting: {
      resourceId: null,
      status: 0,
      type: "",
      headline: "",
      title: "",
      description: "",
      featuredImage: "",
      scheduledAt: "",
      scheduledFinishAt: "",
      isPublished: true,
      publishedAt: "",
    },
  };
};

const state = getDefaultState();

const getters = {
  meetings: (state) => {
    return state.meetings;
  },
  meetingsMeta: (state) => {
    return state.meetingsMeta;
  },
  meeting: (state) => {
    return state.meeting;
  },
  upcomingMeeting: (state) => {
    return state.upcomingMeeting;
  },
  meetingUsers: (state) => {
    return state.meetingUsers;
  },
};

const actions = {
  resetMeetings({ commit }) {
    commit(MEETINGS.RESET_MEETINGS);
  },
  async fetchMeetings({ commit }) {
    await ApiService.getMeetings().then((res) => {
      if (res.data.success) {
        commit(MEETINGS.FETCH_MEETINGS, res.data);
      }
    });
  },
  async getMeetings(context, params) {
    await ApiService.getMeetings(params).then(async (res) => {
      if (res.data.success) {
        await context.commit(MEETINGS.GET_MEETINGS, res.data);
      }
    });
  },
  async getMeeting(context, payload) {
    await ApiService.getMeeting(payload.meetingResourceId).then(async (res) => {
      if (res.data.success) {
        await context.commit(MEETINGS.GET_MEETING, res.data.data);
        await context.dispatch("getMeetingUsers", {
          meetingResourceId: payload.meetingResourceId,
        });
      }
    });
  },
  async getMeetingUsers({ commit }, payload) {
    await ApiService.getMeetingUsers(payload.meetingResourceId, {}).then(
      async (res) => {
        if (res.data.success) {
          await commit(MEETINGS.SET_MEETING_USERS, res.data.data);
        }
      }
    );
  },
  postMeetingUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postMeetingUser(payload.meetingResourceId, payload.data).then(
        (res) => {
          if (res.data.success) {
            commit(MEETINGS.ADD_MEETING_USERS, res.data.data);
          }
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async updateMeetingUser({ commit }, payload) {
    await ApiService.toggleMeetingUserAccess(
      payload.meetingResourceId,
      payload.userResourceId,
      payload.data
    ).then((res) => {
      if (res.data.success) {
        commit(MEETINGS.UPDATE_MEETING_USER, res.data.data);
      }
    });
  },
  deleteMeetingUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.deleteMeetingUser(
        payload.meetingResourceId,
        payload.userResourceId
      ).then(
        (res) => {
          if (res.data.success) {
            commit(MEETINGS.POP_MEETING_USER, payload.userResourceId);
          }
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async postMeeting({ dispatch }, payload) {
    await ApiService.postMeetings(payload).then(async () => {
      if (payload.clubResourceId) {
        await dispatch("clubs/fetchClub", {}, { root: true });
      }
      dispatch("getMeetings");
    });
  },
  //  작업중
  async deleteMeetings({ commit, dispatch }, payload) {
    await ApiService.deleteMeetings(payload.meetingResourceId).then(
      async () => {
        await commit(MEETINGS.POP_MEETING, {
          meetingResourceId: payload.meetingResourceId,
        });
        await dispatch("getMeetings");
      }
    );
  },
  async putMeetingsPublish({ dispatch, commit }, payload) {
    await ApiService.putMeetingsPublish(payload.meetingResourceId).then(
      async () => {
        await commit(MEETINGS.UPDATE_MEETING_PUBLISH);
        await dispatch("getMeeting", {
          meetingResourceId: payload.meetingResourceId,
        });
      }
    );
  },
  async putMeetingsCreated({ dispatch, commit }, payload) {
    await ApiService.putMeetingsCreated(payload.meetingResourceId).then(
      async () => {
        await commit(MEETINGS.UPDATE_MEETING_STATUS, 0);
        await dispatch("getMeetings");
      }
    );
  },
  async putMeetingsWaiting({ dispatch, commit }, payload) {
    await ApiService.putMeetingsWaiting(payload.meetingResourceId).then(
      async () => {
        await commit(MEETINGS.UPDATE_MEETING_STATUS, 1);
        await dispatch("getMeetings");
      }
    );
  },
  async putMeetingsStart({ commit, dispatch }, payload) {
    ApiService.putMeetingsStart(payload.meetingResourceId).then(async () => {
      await commit(MEETINGS.UPDATE_MEETING_STATUS, 3);
      await dispatch("getMeetings");
    });
  },
  async putMeetingsFinish({ commit, dispatch }, payload) {
    ApiService.putMeetingsFinish(payload.meetingResourceId).then(async () => {
      await commit(MEETINGS.UPDATE_MEETING_STATUS, 5);
      await dispatch("getMeetings");
    });
  },
  async putMeetings({ dispatch }, payload) {
    await ApiService.putMeetings(payload.meetingResourceId, payload.data).then(
      async () => {
        await dispatch("getMeeting", {
          meetingResourceId: payload.meetingResourceId,
        });
        dispatch("fetchMeetings");
      }
    );
  },
  uploadMeetingAttachment(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postAttachment(payload).then(
        (res) => {
          if (res.data.success) {
            resolve(res.data.data);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async deleteMeetingAttachment(context, payload) {
    await ApiService.deleteAttachment(payload.attachmentResourceId);
  },
  async postMeetingFeaturedImageUpload({ commit }, payload) {
    await ApiService.postMeetingFeaturedImageUpload(
      payload.meetingResourceId,
      payload.data
    ).then(async (res) => {
      await commit(MEETINGS.UPDATE_MEETING_FEATURED_IMAGE, {
        featuredImage: res.data.data.featuredImage,
      });
    });
  },
  async deleteMeetingFeaturedImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.deleteMeetingFeaturedImage(payload.meetingResourceId).then(
        async (res) => {
          if (res.data.success) {
            await commit(MEETINGS.UPDATE_MEETING_FEATURED_IMAGE, {
              featuredImage: res.data.data.featuredImage,
            });
            resolve(res.data.data);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async getUpcomingMeeting({ commit }) {
    await ApiService.getUpcomingMeeting().then(async (res) => {
      if (res.data.success) {
        if (res.data.data) {
          await commit(MEETINGS.SET_UPCOMING_MEETING, res.data.data);
        } else {
          await commit(MEETINGS.RESET_UPCOMING_MEETING);
        }
      }
    });
  },
};

const mutations = {
  [MEETINGS.RESET_MEETINGS](state) {
    Object.assign(state, getDefaultState());
  },
  [MEETINGS.FETCH_MEETINGS](state, payload) {
    state.meetings = payload.data;
    state.meetingsMeta = payload.meta;
  },
  [MEETINGS.GET_MEETINGS](state, payload) {
    state.meetings = payload.data;
    state.meetingsMeta = payload.meta;
  },
  [MEETINGS.GET_MEETING](state, payload) {
    state.meeting = { ..._.cloneDeep(state.meeting), ..._.cloneDeep(payload) };
  },
  [MEETINGS.UPDATE_MEETING](state, payload) {
    state.meeting = { ..._.cloneDeep(state.meeting), ..._.cloneDeep(payload) };
  },
  [MEETINGS.POP_MEETING](state, payload) {
    state.meetings = state.meetings.filter((item) => {
      return item.resourceId !== payload.meetingResourceId;
    });
  },
  [MEETINGS.UPDATE_MEETING_PUBLISH](state) {
    state.meeting.isPublished = !state.meeting.isPublished;
  },
  [MEETINGS.UPDATE_MEETING_STATUS](state, status) {
    state.meeting.status = status;
  },
  [MEETINGS.UPDATE_MEETING_FEATURED_IMAGE](state, payload) {
    state.meeting.featuredImage = payload.featuredImage;
  },
  [MEETINGS.SET_UPCOMING_MEETING](state, payload) {
    state.upcomingMeeting = {
      ..._.cloneDeep(state.upcomingMeeting),
      ..._.cloneDeep(payload),
    };
  },
  [MEETINGS.RESET_UPCOMING_MEETING](state) {
    state.upcomingMeeting = getDefaultState().upcomingMeeting;
  },
  [MEETINGS.SET_MEETING_USERS](state, payload) {
    state.meetingUsers = payload;
  },
  [MEETINGS.ADD_MEETING_USERS](state, payload) {
    state.meetingUsers.push(payload);
  },
  [MEETINGS.POP_MEETING_USER](state, userResourceId) {
    const meetingUserIndex = state.meetingUsers.findIndex((item) => {
      return item.user.resourceId === userResourceId;
    });
    state.meetingUsers.splice(meetingUserIndex, 1);
  },
  [MEETINGS.UPDATE_MEETING_USER](state, payload) {
    const meetingUserIndex = state.meetingUsers.findIndex((item) => {
      return item.user.resourceId === payload.user.resourceId;
    });
    state.meetingUsers[meetingUserIndex] = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
