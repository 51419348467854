<template>
  <icon-base
    icon-name="brand-home-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 11.0001V4.53524L6 1.2019L1 4.53524V11.0001H4V8.10005C4 7.49254 4.49249 7.00005 5.1 7.00005H6.9C7.50751 7.00005 8 7.49254 8 8.10005V11.0001H11ZM0.4453 3.70319C0.167101 3.88865 0 4.20088 0 4.53524V11.0001C0 11.5523 0.447715 12.0001 1 12.0001H4C4.55228 12.0001 5 11.5523 5 11.0001V8.10005C5 8.04483 5.04477 8.00005 5.1 8.00005H6.9C6.95523 8.00005 7 8.04483 7 8.10005V11.0001C7 11.5523 7.44772 12.0001 8 12.0001H11C11.5523 12.0001 12 11.5523 12 11.0001V4.53524C12 4.20088 11.8329 3.88865 11.5547 3.70319L6.5547 0.369854C6.2188 0.145921 5.7812 0.145921 5.4453 0.369854L0.4453 3.70319Z"
        :fill="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>
    <template v-if="fill">
      <path
        d="M0 4.53524C0 4.20088 0.167101 3.88865 0.4453 3.70319L5.4453 0.369854C5.7812 0.145921 6.2188 0.145921 6.5547 0.369854L11.5547 3.70319C11.8329 3.88865 12 4.20088 12 4.53524V11.0001C12 11.5523 11.5523 12.0001 11 12.0001H9C8.44772 12.0001 8 11.5523 8 11.0001V8.30005C8 8.13437 7.86569 8.00005 7.7 8.00005H4.3C4.13431 8.00005 4 8.13437 4 8.30005V11.0001C4 11.5523 3.55228 12.0001 3 12.0001H1C0.447715 12.0001 0 11.5523 0 11.0001V4.53524Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "BrandHomeIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#D0D5DE",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
