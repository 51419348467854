import ApiService from "@/api";
import { MEMBERSHIPS } from "../mutation-types";

const getDefaultState = () => {
  return {
    memberships: [],
    membershipsMeta: {
      pagination: {
        currentPage: 1,
        perPage: 15,
        total: 0,
        lastPage: 1,
      },
    },
  };
};

const state = getDefaultState();

const getters = {
  memberships: (state) => {
    return state.memberships;
  },
};

const actions = {
  async getMemberships({ commit }, payload) {
    await ApiService.getMemberships(payload).then(async (res) => {
      if (res.data.success) {
        await commit(MEMBERSHIPS.SET_MEMBERSHIPS, res.data);
      }
    });
  },
  async postMembership({ dispatch }, payload) {
    await ApiService.postMembership(payload).then((res) => {
      if (res.data.success) {
        dispatch("getMemberships");
      }
    });
  },
};

const mutations = {
  [MEMBERSHIPS.SET_MEMBERSHIPS](state, payload) {
    state.memberships = payload.data;
    state.membershipsMeta = payload.meta;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
