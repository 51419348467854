
import { useStore } from "vuex";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic.vue";
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  PropType,
  reactive,
  watch,
} from "vue";
import ChannelInput from "../ChannelInput/ChannelInput.vue";
import helper from "@/helper/index.js";
import { ChannelCode, ChannelForm } from "@/types/onboarding";

export default {
  name: "OnBoardingChannel",
  components: {
    ChannelInput,
    ButtonBasic,
  },
  props: {
    channels: {
      type: Array as PropType<ChannelForm[]>,
      default: () => {
        return [];
      },
    },
  },
  emits: ["complete", "updateData", "updateChannel"],
  setup(props, { emit }) {
    const store = useStore();
    const snsCodes = [
      "instagram" as ChannelCode,
      "youtube" as ChannelCode,
      "twitter" as ChannelCode,
      "tiktok" as ChannelCode,
      "facebook" as ChannelCode,
    ];
    const { proxy } = getCurrentInstance();

    const state = reactive({
      channelInput1: {} as ChannelForm,
      channelInputErrorMsg1: "",
      channelInput2: {} as ChannelForm,
      channelInputErrorMsg2: "",
      channelInput3: {} as ChannelForm,
      channelInputErrorMsg3: "",
      channelInput4: {} as ChannelForm,
      channelInputErrorMsg4: "",
      channelInput5: {} as ChannelForm,
      channelInputErrorMsg5: "",
      activeNextBtn: computed(() => {
        return (
          !!state.channelInput1.url ||
          !!state.channelInput2.url ||
          !!state.channelInput3.url ||
          !!state.channelInput4.url ||
          !!state.channelInput5.url
        );
      }),
    });

    watch(
      () => props.channels,
      (updatedChannels) => {
        syncChannels(updatedChannels);
      },
      { deep: true }
    );

    onBeforeMount(() => {
      syncChannels(props.channels);
    });

    const syncChannels = (channels) => {
      if (props.channels.length > 0) {
        let loopCount = channels.length > 5 ? 5 : channels.length;
        for (let i = 0; i < loopCount; i++) {
          state[`channelInput${i + 1}`] = {
            ...state[`channelInput${i + 1}`],
            ...channels[i],
          };
        }
      }
    };

    const createSpaceChannel = (url) => {
      let res = store.dispatch("space/postSpaceChannel", { url: url });
      return res;
    };

    const createChannels = (url) => {
      if (url) {
        createSpaceChannel(url);
      }
    };

    const parseChannelFromUrl = (index: number): ChannelCode => {
      return snsCodes[index - 1];
    };

    const parseChannelIcon = (code) => {
      return `https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_${code}.svg`;
    };

    const actions = {
      goToFinish: () => {
        createChannels(state.channelInput1.url);
        createChannels(state.channelInput2.url);
        createChannels(state.channelInput3.url);
        createChannels(state.channelInput4.url);
        createChannels(state.channelInput5.url);
        emit("complete");
      },
      updateChannelInput: (url, inputIndex) => {
        if (!helper.isValidURL(url) && !!url) {
          state[`channelInputErrorMsg${inputIndex}`] =
            "유효하지않은 주소 형식입니다.";
          return;
        }

        if (state[`channelInputErrorMsg${inputIndex}`]) {
          state[`channelInputErrorMsg${inputIndex}`] = "";
        }

        let code = parseChannelFromUrl(inputIndex);
        let icon = parseChannelIcon(code);

        state[`channelInput${inputIndex}`].id = inputIndex;
        state[`channelInput${inputIndex}`].url = url;
        state[`channelInput${inputIndex}`].code = code;
        state[`channelInput${inputIndex}`].icon = icon;

        emit("updateChannel", state[`channelInput${inputIndex}`]);
      },
    };

    return { state, actions };
  },
};
