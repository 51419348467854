<template>
  <div class="studio-full-layout">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "FullLayout",
  components: {},
  setup() {
    const actions = {};

    return { actions };
  },
};
</script>

<style scoped>
.studio-full-layout {
  height: 100%;
}
</style>
