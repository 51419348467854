<template>
  <icon-base
    icon-name="loading-spinner-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
    :clip-path="clipPath"
  >
    <circle
      opacity="0.5"
      cx="12"
      cy="12"
      r="10"
      :stroke="fillColor"
      stroke-width="3"
    />
    <path
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2"
      :stroke="fillColor"
      stroke-width="3"
      stroke-linecap="round"
    ></path>
    <template #defs>
      <clipPath id="clip0_19777_153569">
        <rect width="24" height="24" :fill="fillColor" />
      </clipPath>
    </template>
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "LoadingSpinnerIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#FFFFFF",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
    clipPath: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
