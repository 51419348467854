import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9cb9fcbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-modal" }
const _hoisted_2 = { class: "dialog" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "on-boarding-wrapper" }
const _hoisted_5 = {
  key: 0,
  class: "on-boarding-preview pc"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "profile-page"
}
const _hoisted_8 = {
  key: 2,
  class: "social-page"
}
const _hoisted_9 = {
  key: 3,
  class: "interest-page"
}
const _hoisted_10 = {
  key: 4,
  class: "finish-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_on_boarding_preview = _resolveComponent("on-boarding-preview")!
  const _component_on_boarding_alias = _resolveComponent("on-boarding-alias")!
  const _component_on_boarding_profile = _resolveComponent("on-boarding-profile")!
  const _component_on_boarding_channel = _resolveComponent("on-boarding-channel")!
  const _component_on_boarding_interest = _resolveComponent("on-boarding-interest")!
  const _component_on_boarding_progress = _resolveComponent("on-boarding-progress")!
  const _component_on_boarding_finish = _resolveComponent("on-boarding-finish")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_button_basic = _resolveComponent("button-basic")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!$setup.state.isInterestStep)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_on_boarding_preview, {
                  "alias-name": $setup.state.aliasName,
                  "avatar-img": $setup.state.avatar,
                  "creator-name": $setup.state.creatorName,
                  description: $setup.state.description,
                  channels: $setup.state.prevChannels
                }, null, 8, ["alias-name", "avatar-img", "creator-name", "description", "channels"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["on-boarding-input-view", { 'full-page': $setup.state.isInterestStep }])
          }, [
            ($setup.state.lastStep === null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_on_boarding_alias, {
                    "alias-name": $setup.state.aliasName,
                    onUpdateAliasName: _cache[0] || (_cache[0] = 
                  (value) => {
                    $setup.actions.updateUserAliasName(value);
                  }
                ),
                    onComplete: _cache[1] || (_cache[1] = ($event: any) => (
                  $setup.actions.changeStep($setup.onBoardingConst.onBoardingSteps.alias)
                ))
                  }, null, 8, ["alias-name"])
                ]))
              : _createCommentVNode("", true),
            ($setup.state.lastStep === $setup.onBoardingConst.onBoardingSteps.alias)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_on_boarding_profile, {
                    "creator-name": $setup.state.creatorName,
                    description: $setup.state.description,
                    "avatar-img": $setup.state.avatar,
                    onUpdateCreatorName: _cache[2] || (_cache[2] = 
                  (value) => {
                    $setup.actions.updateCreatorName(value);
                  }
                ),
                    onUpdateAvatar: _cache[3] || (_cache[3] = 
                  (value) => {
                    $setup.actions.updateAvatar(value);
                  }
                ),
                    onUpdateCreatorDescription: _cache[4] || (_cache[4] = 
                  (value) => {
                    $setup.actions.updateCreatorDescription(value);
                  }
                ),
                    onComplete: _cache[5] || (_cache[5] = ($event: any) => (
                  $setup.actions.changeStep($setup.onBoardingConst.onBoardingSteps.profile)
                ))
                  }, null, 8, ["creator-name", "description", "avatar-img"])
                ]))
              : _createCommentVNode("", true),
            ($setup.state.lastStep === $setup.onBoardingConst.onBoardingSteps.profile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_on_boarding_channel, {
                    channels: $setup.state.prevChannels,
                    onUpdateChannel: _cache[6] || (_cache[6] = 
                  (channel) => {
                    $setup.actions.updateChannel(channel);
                  }
                ),
                    onComplete: _cache[7] || (_cache[7] = ($event: any) => (
                  $setup.actions.changeStep($setup.onBoardingConst.onBoardingSteps.channel)
                ))
                  }, null, 8, ["channels"])
                ]))
              : _createCommentVNode("", true),
            ($setup.state.lastStep === $setup.onBoardingConst.onBoardingSteps.channel)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_on_boarding_interest, {
                    onComplete: _cache[8] || (_cache[8] = ($event: any) => (
                  $setup.actions.changeStep($setup.onBoardingConst.onBoardingSteps.interest)
                ))
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              (
                  $setup.state.lastStep !== $setup.onBoardingConst.onBoardingSteps.interest
                )
                ? (_openBlock(), _createBlock(_component_on_boarding_progress, {
                    key: 0,
                    "last-step": $setup.state.lastStep
                  }, null, 8, ["last-step"]))
                : _createCommentVNode("", true)
            ]),
            ($setup.state.lastStep === $setup.onBoardingConst.onBoardingSteps.interest)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_on_boarding_finish, {
                    "alias-name": $setup.state.aliasName,
                    "avatar-img": $setup.state.avatar,
                    "creator-name": $setup.state.creatorName,
                    description: $setup.state.description,
                    channels: $setup.state.spaceChannels,
                    onComplete: _cache[9] || (_cache[9] = 
                  (value) => {
                    $setup.actions.completeOnBoarding(value);
                  }
                )
                  }, null, 8, ["alias-name", "avatar-img", "creator-name", "description", "channels"])
                ]))
              : _createCommentVNode("", true),
            ($setup.state.lastStep !== null)
              ? (_openBlock(), _createBlock(_component_button_basic, {
                  key: 5,
                  class: "close-btn",
                  "bg-color": "transparent",
                  padding: "0",
                  onAction: _cache[10] || (_cache[10] = ($event: any) => ($setup.actions.closeModal()))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_close_icon, {
                      width: "24",
                      height: "24"
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ])
  ]))
}