<template>
  <icon-base
    icon-name="dashboard-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.375 2.8125C3.375 2.50184 3.12316 2.25 2.8125 2.25C2.50184 2.25 2.25 2.50184 2.25 2.8125V14.625C2.25 15.2463 2.75368 15.75 3.375 15.75H15.1875C15.4982 15.75 15.75 15.4982 15.75 15.1875C15.75 14.8768 15.4982 14.625 15.1875 14.625H3.375V2.8125ZM15.5852 5.46025C15.8049 5.24058 15.8049 4.88442 15.5852 4.66475C15.3656 4.44508 15.0094 4.44508 14.7898 4.66475L10.125 9.3295L9.233 8.4375C8.79366 7.99816 8.08134 7.99816 7.642 8.4375L4.66475 11.4148C4.44508 11.6344 4.44508 11.9906 4.66475 12.2102C4.88442 12.4299 5.24058 12.4299 5.46025 12.2102L8.4375 9.233L9.3295 10.125C9.76884 10.5643 10.4812 10.5643 10.9205 10.125L15.5852 5.46025Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>
    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 2C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H12.5C13.3284 14 14 13.3284 14 12.5V3.5C14 2.67157 13.3284 2 12.5 2H3.5ZM12.8536 4.85355C13.0488 4.65829 13.0488 4.34171 12.8536 4.14645C12.6583 3.95118 12.3417 3.95118 12.1464 4.14645L8 8.29289L7.20711 7.5C6.81658 7.10948 6.18342 7.10948 5.79289 7.5L3.14645 10.1464C2.95118 10.3417 2.95118 10.6583 3.14645 10.8536C3.34171 11.0488 3.65829 11.0488 3.85355 10.8536L6.5 8.20711L7.29289 9C7.68342 9.39052 8.31658 9.39052 8.70711 9L12.8536 4.85355Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import { computed, reactive } from "vue";
import IconBase from "../../common/IconBase";

export default {
  name: "DashboardIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 18 18",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
