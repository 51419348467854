import ApiService from "@/api";
import { ONBOARDINGS } from "../mutation-types";

const getDefaultState = () => {
  return {
    lastStep: null,
    alias: {
      data: null,
      message: null,
      meta: null,
      success: null,
    },
    aliasServerMessage: "",
    interestTags: [],
  };
};

const state = getDefaultState();

const getters = {
  lastStep: (state) => {
    return state.lastStep;
  },
  alias: (state) => {
    return state.alias;
  },
  aliasServerMessage: (state) => {
    return state.aliasServerMessage;
  },
  interestTags: (state) => {
    return state.interestTags;
  },
};

const actions = {
  async getOnBoardingStep({ commit }) {
    await ApiService.getOnBoardingStep().then(async (res) => {
      if (res.data.success) {
        await commit(ONBOARDINGS.SET_STEP, res.data.data);
      }
    });
  },

  async putOnBoardingStep({ dispatch }, payload) {
    await ApiService.putOnBoardingStep(payload).then(async (res) => {
      if (res.data.success) {
        await dispatch("auth/fetchUser", {}, { root: true });
        await dispatch("getOnBoardingStep");
      }
    });
  },
  async getOnBoardingAlias({ commit }, payload) {
    await ApiService.getOnBoardingAlias(payload).then((res) => {
      commit(ONBOARDINGS.SET_ALIAS, res.data);
    });
  },
  async putOnBoardingAlias({ commit, dispatch }, payload) {
    await ApiService.putOnBoardingAlias(payload).then(async (res) => {
      if (!res.data.success) {
        commit(ONBOARDINGS.SET_ALIAS, res.data);
      } else {
        commit(ONBOARDINGS.SET_ALIAS, res.data);
        await dispatch("getOnBoardingStep");
      }
    });
  },
  async postOnBoardingProfile({ dispatch }, payload) {
    await ApiService.postOnBoardingProfile(payload.data).then(async (res) => {
      if (res.data.success) {
        dispatch("auth/fetchUser", {}, { root: true });
        await dispatch("getOnBoardingStep");
      }
    });
  },
  async putOnBoardingInterest({ dispatch }, payload) {
    await ApiService.putOnBoardingInterest(payload).then(async (res) => {
      if (res.data.success) {
        await dispatch("getOnBoardingStep");
        await dispatch("auth/fetchUser", {}, { root: true }); // 온보딩 완료 된 시점에 user fetch
      }
    });
  },
  async postJoinPurpose({ dispatch }, payload) {
    await ApiService.postJoinPurpose(payload).then(async (res) => {
      if (res.data.success) {
        await dispatch("getOnBoardingStep");
        await dispatch("auth/fetchUser", {}, { root: true });
      }
    });
  },
};

const mutations = {
  [ONBOARDINGS.SET_STEP](state, payload) {
    state.lastStep = payload.lastStep;
  },
  [ONBOARDINGS.SET_ALIAS](state, payload) {
    state.alias = { ...state.alias, ...payload };
  },
  [ONBOARDINGS.UPDATE_ALIAS](state, payload) {
    state.alias.success = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
