<template>
  <icon-base
    icon-name="exit-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 2C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H9.5C9.77614 14 10 13.7761 10 13.5C10 13.2239 9.77614 13 9.5 13H3.5C3.22386 13 3 12.7761 3 12.5V3.5C3 3.22386 3.22386 3 3.5 3H9.5C9.77614 3 10 2.77614 10 2.5C10 2.22386 9.77614 2 9.5 2H3.5ZM10.6464 10.8536C10.4512 10.6583 10.4512 10.3417 10.6464 10.1464L12.2929 8.5H7.5C7.22386 8.5 7 8.27614 7 8C7 7.72386 7.22386 7.5 7.5 7.5H12.2929L10.6464 5.85355C10.4512 5.65829 10.4512 5.34171 10.6464 5.14645C10.8417 4.95118 11.1583 4.95118 11.3536 5.14645L13.8536 7.64645C14.0488 7.84171 14.0488 8.15829 13.8536 8.35355L11.3536 10.8536C11.1583 11.0488 10.8417 11.0488 10.6464 10.8536Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
export default {
  name: "ExitIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
