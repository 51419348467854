<template>
  <icon-base
    icon-name="arrow-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :d="state.d"
      :fill="fillColor"
    />
    <path
      d="M11.6085 1.82882C11.666 1.6398 11.9336 1.6398 11.9911 1.82882L12.4688 3.39812C12.4882 3.46189 12.5381 3.51181 12.6019 3.53122L14.1712 4.00886C14.3602 4.06639 14.3602 4.334 14.1712 4.39153L12.6019 4.86917C12.5381 4.88858 12.4882 4.9385 12.4688 5.00227L11.9911 6.57157C11.9336 6.76059 11.666 6.76059 11.6085 6.57157L11.1308 5.00227C11.1114 4.9385 11.0615 4.88858 10.9977 4.86917L9.42843 4.39153C9.23941 4.334 9.23941 4.06639 9.42843 4.00886L10.9977 3.53122C11.0615 3.51181 11.1114 3.46189 11.1308 3.39812L11.6085 1.82882Z"
      :fill="fillColor"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "EmojiTwoIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      d: computed(() => {
        if (props.fill) {
          return "M13.434 5.45281C13.7971 6.22599 14 7.0893 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C8.91105 2 9.77468 2.20305 10.5481 2.56637L10.4899 2.75765C10.4705 2.82143 10.4206 2.87134 10.3568 2.89075L9.19564 3.24416C8.25054 3.53182 8.25055 4.86985 9.19564 5.1575L10.4588 5.54197L10.8433 6.80515C11.1309 7.75024 12.469 7.75024 12.7566 6.80515L13.11 5.64401C13.1294 5.58024 13.1794 5.53033 13.2431 5.51091L13.434 5.45281ZM5.92278 8.38462C6.30513 8.38462 6.61508 8.07466 6.61508 7.69231C6.61508 7.30996 6.30513 7 5.92278 7C5.54043 7 5.23047 7.30996 5.23047 7.69231C5.23047 8.07466 5.54043 8.38462 5.92278 8.38462ZM10.0771 8.38462C10.4594 8.38462 10.7694 8.07466 10.7694 7.69231C10.7694 7.30996 10.4594 7 10.0771 7C9.69472 7 9.38477 7.30996 9.38477 7.69231C9.38477 8.07466 9.69472 8.38462 10.0771 8.38462ZM10.7089 10.1706C10.8324 9.95692 10.7593 9.68359 10.5457 9.56007C10.332 9.43655 10.0587 9.50961 9.93519 9.72326C9.73887 10.0628 9.45669 10.3447 9.11696 10.5407C8.77723 10.7367 8.39192 10.8399 7.99971 10.8399C7.60749 10.8399 7.22218 10.7368 6.88245 10.5408C6.54272 10.3448 6.26053 10.0628 6.06421 9.72329C5.94069 9.50965 5.66736 9.43659 5.45372 9.56012C5.24008 9.68364 5.16702 9.95697 5.29055 10.1706C5.56534 10.6459 5.96033 11.0405 6.43586 11.3148C6.91139 11.5892 7.45072 11.7336 7.99972 11.7336C8.54871 11.7336 9.08804 11.5892 9.56357 11.3148C10.0391 11.0405 10.4341 10.6458 10.7089 10.1706Z";
        } else {
          return "M3 8C3 5.23858 5.23858 3 8 3C8.43239 3 8.85127 3.05477 9.25037 3.15749C9.5178 3.22632 9.79039 3.06533 9.85922 2.7979C9.92805 2.53048 9.76706 2.25789 9.49963 2.18906C9.01978 2.06555 8.51718 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 7.48282 13.9345 6.98022 13.8109 6.50037C13.7421 6.23294 13.4695 6.07195 13.2021 6.14078C12.9347 6.20961 12.7737 6.4822 12.8425 6.74963C12.9452 7.14873 13 7.56761 13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8ZM6.61508 7.69231C6.61508 8.07466 6.30513 8.38462 5.92278 8.38462C5.54043 8.38462 5.23047 8.07466 5.23047 7.69231C5.23047 7.30996 5.54043 7 5.92278 7C6.30513 7 6.61508 7.30996 6.61508 7.69231ZM10.7694 7.69231C10.7694 8.07466 10.4594 8.38462 10.0771 8.38462C9.69472 8.38462 9.38477 8.07466 9.38477 7.69231C9.38477 7.30996 9.69472 7 10.0771 7C10.4594 7 10.7694 7.30996 10.7694 7.69231ZM10.5457 9.56007C10.7593 9.68359 10.8324 9.95692 10.7089 10.1706C10.4341 10.6458 10.0391 11.0405 9.56357 11.3148C9.08804 11.5892 8.54871 11.7336 7.99972 11.7336C7.45072 11.7336 6.91139 11.5892 6.43586 11.3148C5.96033 11.0405 5.56534 10.6459 5.29055 10.1706C5.16702 9.95697 5.24008 9.68364 5.45372 9.56012C5.66736 9.43659 5.94069 9.50965 6.06421 9.72329C6.26053 10.0628 6.54272 10.3448 6.88245 10.5408C7.22218 10.7368 7.60749 10.8399 7.99971 10.8399C8.39192 10.8399 8.77723 10.7367 9.11696 10.5407C9.45669 10.3447 9.73887 10.0628 9.93519 9.72326C10.0587 9.50961 10.332 9.43655 10.5457 9.56007Z";
        }
      }),
    });

    return { state };
  },
};
</script>
