<template>
  <icon-base
    icon-name="view-feed-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 3.20013H13V6.70013H3L3 3.20013ZM2 3.20013C2 2.64785 2.44772 2.20013 3 2.20013H13C13.5523 2.20013 14 2.64785 14 3.20013V6.70013C14 7.25242 13.5523 7.70013 13 7.70013H3C2.44772 7.70013 2 7.25242 2 6.70013V3.20013ZM3 9.70013H13V13.2001H3L3 9.70013ZM2 9.70013C2 9.14785 2.44772 8.70013 3 8.70013H13C13.5523 8.70013 14 9.14785 14 9.70013V13.2001C14 13.7524 13.5523 14.2001 13 14.2001H3C2.44772 14.2001 2 13.7524 2 13.2001V9.70013Z"
      :fill="fillColor"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
export default {
  name: "ViewFeedIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#D0D5DE",
    },
  },
};
</script>
