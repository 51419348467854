<template>
  <icon-base
    icon-name="wallet-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 3H4C3.44772 3 3 3.44772 3 4H11C11 3.44772 10.5523 3 10 3ZM4 2C2.89543 2 2 2.89543 2 4V5V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V6C14 4.89543 13.1046 4 12 4C12 2.89543 11.1046 2 10 2H4ZM11 5H12C12.5523 5 13 5.44772 13 6V12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12V5H11ZM10.75 9.75C11.1642 9.75 11.5 9.41421 11.5 9C11.5 8.58579 11.1642 8.25 10.75 8.25C10.3358 8.25 10 8.58579 10 9C10 9.41421 10.3358 9.75 10.75 9.75Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
export default {
  name: "WalletIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
