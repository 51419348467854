let onBoardingConst = {
  onBoardingSteps: {
    start: 11,
    profile: 31,
    interest: 51, // 일반유저 동일
    alias: 21, // 안쓰는 값.... =>
    purpose: 22,
    channel: 41, // 심사있을 때 온보딩에서 - 심사단계의 경우
    approved: 52, // 심사 승인
    review: 53, // 반려
    recommended_creator: 55, // 일반유저만 사용(스튜디오에서 사용 안함.)
    finish: 61, // 일반유저의 경우 추천크리에이터 페이지로 사용중
  },

  stepNames: {
    11: "profile",
    31: "interest",
    51: "purpose",
    22: "channel",
    41: "complete",
    52: "approved",
    53: "review",
    61: "finish",
  },
};

export default onBoardingConst;
