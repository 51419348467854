<template>
  <base-modal
    title="연결된 유저 보기"
    :is-footer-section="false"
    @closeModal="$emit('hideModal')"
  >
    <template #modalBody>
      <ul style="height: 300px; overflow: scroll">
        <li
          v-for="(user, index) in children"
          :key="`user-${index}`"
          class="children-user"
          @click="actions.changeUser(user)"
        >
          <avatar-with-name :user="user"></avatar-with-name>
        </li>
      </ul>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";
import AvatarWithName from "../console/avatars/AvatarWithName";
import { useStore } from "vuex";

export default {
  name: "UserChildrenModal",
  components: { AvatarWithName, BaseModal },
  props: {
    children: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const actions = {
      changeUser: (user) => {
        store
          .dispatch("auth/changeUser", { userResourceId: user.resourceId })
          .then(() => {
            window.location.href = "/console";
          });

        emit("hideModal");
      },
    };

    return { actions };
  },
};
</script>

<style>
.children-user {
  display: flex;
  margin-bottom: 8px;
}
.children-user:hover {
  background-color: #f5f5f5;
}
</style>
