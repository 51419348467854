<template>
  <div ref="baseModal" class="base-modal">
    <div ref="contentDialog" class="dialog" :class="position">
      <div ref="contentModal" class="content">
        <h3>{{ title }}</h3>

        <p v-if="subTitle" class="modal-sub-title sub-text-s2 text-gray-second">
          {{ subTitle }}
        </p>

        <button v-if="isCloseBtn" ref="closeBtn" class="close-icon">
          <close-icon width="24" height="24"></close-icon>
        </button>

        <slot name="modalBody"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, ref } from "vue";
import CloseIcon from "../console/icons/CloseIcon.vue";

export default {
  name: "BaseModal",
  components: { CloseIcon },
  props: {
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
    position: {
      type: String,
      default: "center",
      validator(value) {
        return ["center", "top-right"].includes(value);
      },
    },
    isCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const baseModal = ref(null);
    const contentDialog = ref(null);
    const contentModal = ref(null);
    const closeBtn = ref(null);

    const state = reactive({});

    const closeModal = ($event) => {
      if (
        (baseModal.value.contains($event.target) &&
          !contentModal.value.contains($event.target)) ||
        (props.isCloseBtn && closeBtn.value.contains($event.target))
      ) {
        emit("closeModal");
      }
    };

    onMounted(() => {
      baseModal.value.addEventListener("click", closeModal);

      // 백그라운드 스크롤 방지
      document.getElementById("app").style.overflow = "hidden";
    });

    onBeforeUnmount(() => {
      document.getElementById("app").style.overflow = "auto";
      baseModal.value.removeEventListener("click", closeModal);
    });

    return { baseModal, contentModal, contentDialog, closeBtn, state };
  },
};
</script>

<style scoped>
.base-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.base-modal .dialog {
  position: relative;
  width: 80%;
  pointer-events: none;
}

.base-modal .dialog.top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 78px;
  margin-right: 10px;
}

.base-modal .dialog.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.base-modal .dialog .content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background: #ffffff;
  border: 1px solid rgba(28, 28, 28, 0.2);
  border-radius: 8px;
  outline: 0;
  padding: 20px;
}

.base-modal .dialog .content h3 {
  width: 100%;
  padding-right: 32px;
}

.base-modal .dialog .content .close-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

.modal-sub-title {
  margin-bottom: 0;
}

.close-icon {
}

@media (min-width: 768px) {
  .base-modal .dialog {
    width: 50%;
    max-width: 500px;
  }

  .base-modal .dialog .content {
    padding: 32px;
  }

  .base-modal .dialog .content .close-icon {
    padding: 32px;
  }
}
</style>
