import ApiService from "@/api";
import { NOTIFICATIONS } from "../mutation-types";
import _ from "lodash";

const getDefaultState = () => {
  return {
    count: 0,
    notifications: [],
    notificationsMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
  };
};

const state = getDefaultState();

const getters = {
  count: (state) => {
    return state.count;
  },
  notifications: (state) => {
    return state.notifications;
  },
  currentPage: (state) => {
    return state.notificationsMeta.pagination.currentPage;
  },
};

const actions = {
  resetNotifications({ commit }) {
    commit(NOTIFICATIONS.RESET_NOTIFICATIONS);
  },
  getUserNotifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getUserNotifications(payload).then(
        (res) => {
          if (res.data.success) {
            commit(NOTIFICATIONS.SET_NOTIFICATIONS, res.data);
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async fetchUserNotifications({ commit }) {
    await ApiService.getUserNotifications().then((res) => {
      if (res.data.success) {
        commit(NOTIFICATIONS.FETCH_NOTIFICATIONS, res.data);
      }
    });
  },
  async postUserNotificationsMarkAsRead({ dispatch }) {
    await ApiService.postUserNotificationsMarkAsRead().then(async (res) => {
      if (res.data.success) {
        await dispatch("fetchUserNotifications");
      }
    });
  },
  increaseCount({ commit }) {
    commit(NOTIFICATIONS.INCREASE_COUNT);
  },
  resetCount({ commit }) {
    commit(NOTIFICATIONS.RESET_COUNT);
  },
};

const mutations = {
  [NOTIFICATIONS.RESET_NOTIFICATIONS](state) {
    Object.assign(state, getDefaultState());
  },
  [NOTIFICATIONS.SET_NOTIFICATIONS](state, payload) {
    state.notifications.push(..._.cloneDeep(payload.data));
    state.notificationsMeta = {
      ..._.cloneDeep(state.notificationsMeta),
      ..._.cloneDeep(payload.meta),
    };
  },
  [NOTIFICATIONS.FETCH_NOTIFICATIONS](state, payload) {
    state.notifications = _.cloneDeep(payload.data);
    state.notificationsMeta = {
      ..._.cloneDeep(state.notificationsMeta),
      ..._.cloneDeep(payload.meta),
    };
  },
  [NOTIFICATIONS.INCREASE_COUNT](state) {
    state.count = state.count + 1;
  },
  [NOTIFICATIONS.RESET_COUNT](state) {
    state.count = 0;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
