<template>
  <!--  todo 로직 수정-->
  <div class="on-boarding-progress-section">
    <div class="ellipse" :class="{ active: lastStep === null }"></div>
    <div
      class="ellipse"
      :class="{
        active: lastStep === onBoardingConst.onBoardingSteps.alias,
      }"
    ></div>
    <div
      class="ellipse"
      :class="{
        active: lastStep === onBoardingConst.onBoardingSteps.profile,
      }"
    ></div>
    <div
      class="ellipse"
      :class="{
        active: lastStep === onBoardingConst.onBoardingSteps.channel,
      }"
    ></div>
  </div>
</template>
<script>
import onBoardingConst from "@/consts/onboarding";

export default {
  name: "OnBoardingProgress",
  props: {
    lastStep: {
      type: [String, Number],
      default: "",
    },
  },
  setup() {
    return {
      onBoardingConst,
    };
  },
};
</script>
<style src="./style.css" scoped></style>
