import ApiService from "@/api";
import { NOTICES } from "../mutation-types";

const getDefaultState = () => {
  return {
    notices: [],
    noticesMeta: {
      pagination: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    },
  };
};

const state = getDefaultState();

const getters = {
  notices: (state) => {
    return state.notices;
  },
  noticesMeta: (state) => {
    return state.noticesMeta;
  },
};

const actions = {
  async getNotices({ commit }, payload) {
    await ApiService.getNotices(payload.data).then((res) => {
      if (res.data.success) {
        commit(NOTICES.SET_NOTICES, res.data);
      }
    });
  },
};

const mutations = {
  [NOTICES.SET_NOTICES](state, payload) {
    state.notices = payload.data;
    state.noticesMeta = { ...state.noticesMeta, ...payload.meta };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
