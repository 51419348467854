<template>
  <div>
    <label v-if="label" class="label">
      <div>
        <span class="sub-text-s2">{{ label }}</span>
        <span v-if="required" class="text-purple-50">*</span>
      </div>
      <div v-if="subLabel" class="sub-label sub-text-s3 text-gray-second">
        <small>
          {{ subLabel }}
        </small>
      </div>
    </label>
    <textarea
      ref="textBox"
      class="textarea b-text-2"
      wrap="hard"
      :class="state.inputClass"
      :value="modelValue"
      :rows="rows"
      :placeholder="placeholder"
      :maxlength="maxLength"
      @input="$emit('update:modelValue', $event.target.value)"
      @keyup="heightResize && actions.heightResize($event)"
      @keydown="heightResize && actions.heightResize($event)"
    ></textarea>
    <div v-if="caption" class="caption">
      <small class="sub-text-s3 text-gray-third">{{ caption }}</small>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, ref } from "vue";

export default {
  name: "InputTextBox",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    subLabel: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
    caption: {
      type: String,
      required: false,
    },
    rows: {
      type: Number,
      required: false,
    },
    heightResize: {
      type: Boolean,
      default: true,
    },
    inputClass: {
      type: String,
      default: "",
      required: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    borderNone: {
      type: Boolean,
      default: true,
    },
    newlineDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const textBox = ref();

    const state = reactive({
      inputClass: `${props.inputClass} ${
        props.borderNone ? "border-0 px-0 py-0" : ""
      }`,
    });

    const actions = {
      heightResize: (e) => {
        e.target.style.height = "1px";
        e.target.style.height = 12 + e.target.scrollHeight + "px";
      },
    };

    onMounted(() => {
      // 초기 textArea 높이 세팅
      if (props.heightResize && !props.rows) {
        textBox.value.style.height = "1px";
        textBox.value.style.height = 12 + textBox.value.scrollHeight + "px";
      }

      // 줄바꿈 안되게 처리
      if (props.newlineDisabled) {
        textBox.value.addEventListener("keypress", (e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
          }
        });
      }
    });

    onBeforeUnmount(() => {
      textBox.value.removeEventListener("keypress", (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      });
    });

    return { actions, textBox, state };
  },
};
</script>

<style scoped>
.label {
  display: block;
  margin-bottom: 4px;
}

.textarea {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  width: 100%;
  resize: none;
  padding: 14px 16px;
}

.textarea:focus {
  border: 1px solid #9ea0a6;
}

.textarea::placeholder {
  color: #d0d5de !important;
}

.caption {
  display: flex;
  justify-content: flex-end;
}

.sub-label {
  margin-top: 4px;
}

.sub-label small {
  font-size: inherit;
}
</style>
