import Cookies from "js-cookie";
import apiService from "../../api";
import { LOCALE } from "../mutation-types";

const getDefaultState = () => {
  return {
    locale: Cookies.get("locale"),
    translations: null,
  };
};

const state = getDefaultState();

const getters = {
  locale: (state) => {
    return state.locale;
  },
  check: (state) => {
    return state.locale !== undefined;
  },
};

const actions = {
  resetLocale({ commit }) {
    commit(LOCALE.RESET_LOCALE);
  },
  saveLocale({ commit }, payload) {
    commit(LOCALE.SAVE_LOCALE, payload);
  },
  changeLocale({ commit }, payload) {
    commit(LOCALE.CHANGE_LOCALE, payload);
  },
  async fetchTranslation({ commit }) {
    const { data } = await apiService.getTranslations("");
    const translations = data.data;

    commit(LOCALE.FETCH_TRANSLATIONS, { translations: translations });
  },
};

const mutations = {
  [LOCALE.RESET_LOCALE](state) {
    Object.assign(state, getDefaultState());
  },
  [LOCALE.SAVE_LOCALE](state, { locale, remember }) {
    state.locale = locale;
    Cookies.set("locale", locale, { expires: remember ? 365 : null });
  },
  [LOCALE.CHANGE_LOCALE](state, { locale, remember }) {
    state.locale = locale;
    Cookies.set("locale", locale, { expires: remember ? 365 : null });
  },
  [LOCALE.FETCH_TRANSLATIONS](state, { translations }) {
    state.translations = translations;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
