<template>
  <picture>
    <source type="image/webp" :srcset="`${imagePath}/${fileName}.webp`" />
    <img :src="`${imagePath}/${fileName}.${extension}`" :alt="alt" />
  </picture>
</template>

<script>
export default {
  name: "ImageComponent",
  props: {
    imagePath: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    extension: {
      type: String,
      default : "jpg"
    },
    alt: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
img,
source {
  width: 100%;
}
</style>
