<template>
  <div class="finish-section">
    <div>
      <h2 class="title">
        이제 빅크스튜디오를 <br class="mo" />사용해보실 수 있습니다.<br />
        {{ creatorName }}님의 브랜드 홈을 바로 꾸며보세요
      </h2>
      <div class="btn-wrapper">
        <button-basic
          text="브랜드 홈 꾸미기"
          padding="14px"
          text-size="s1"
          @action="actions.goToFinish('brand')"
        ></button-basic>
        <button-basic
          class="studio-link-btn"
          text="스튜디오 바로가기"
          padding="5px"
          bg-color="#ffffff"
          color="#818287"
          text-size="s1"
          @action="actions.goToFinish()"
        ></button-basic>
      </div>
    </div>
    <div class="profile-wrapper">
      <div class="profile">
        <avatar class="user-avatar" :avatar-img="avatarImg" size="68"> </avatar>
        <div class="creator-name-area">
          <div
            v-if="state.isCreatorName"
            class="creator-name text-gray-reverse"
          >
            {{ creatorName }}
          </div>
          <div v-if="!state.isCreatorName" class="empty-text"></div>
        </div>
        <div class="introduction-area">
          <div
            v-if="state.isDescription"
            class="introduction text-gray-reverse"
          >
            {{ description }}
          </div>
          <div v-if="!state.isDescription" class="empty-text"></div>
        </div>
        <div class="follow">
          <div class="share-icon">
            <share-icon
              width="12"
              height="12"
              fill-color="#ffffff"
            ></share-icon>
          </div>
          <div class="text">
            <heart-icon width="8" height="8"></heart-icon>
            <span> 팔로우 </span>
          </div>
        </div>
        <div class="channel">
          <div
            v-for="(channel, index) in state.channels"
            :key="`channel-${index}`"
            :class="{ active: channel.url }"
            class="channel-logo-cover"
          >
            <div class="channel-logo-color"></div>
            <img :src="channel.icon" class="channel-logo" />
          </div>
        </div>
        <div class="empty-content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic";
import Avatar from "../../../../components/console/avatars/Avatar";
import ShareIcon from "@/components/console/icons/ShareIcon";
import HeartIcon from "@/components/console/icons/HeartIcon";

export default {
  name: "OnBoardingFinish",
  components: {
    ButtonBasic,
    Avatar,
    ShareIcon,
    HeartIcon,
  },
  props: {
    avatarImg: {
      type: String,
      default: "",
    },
    creatorName: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    channels: {
      types: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["complete"],
  setup(props, { emit }) {
    const state = reactive({
      isCreatorName: computed(() => {
        return props.creatorName;
      }),
      isDescription: computed(() => {
        return props.description;
      }),
      channels: computed(() => {
        let channels = props.channels;
        if (channels.length > 5) {
          return channels.slice(0, 5);
        }
        return channels;
      }),
    });

    const actions = {
      goToFinish: (value = "studio") => {
        emit("complete", value);
      },
    };

    return {
      state,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
