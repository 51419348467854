<template>
  <component :is="state.layout">
    <slot />
  </component>
</template>

<script>
import WebDefaultLayout from "./web/DefaultLayout.vue";
import WebBasicLayout from "./web/BasicLayout.vue";
import ConsoleBasicLayout from "./console/BasicLayaout.vue";
import NewLayout from "./console/NewLayout.vue";
import FullLayout from "./console/FullLayout.vue";
import { computed, reactive } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "AppLayout",
  components: {
    WebDefaultLayout,
    WebBasicLayout,
    NewLayout,
    ConsoleBasicLayout,
    FullLayout,
  },
  setup() {
    const route = useRoute();

    const state = reactive({
      layout: computed(() => {
        return route.meta.layout || "ConsoleBasicLayout";
      }),
    });

    return { state };
  },
};
</script>

<style lang="scss">
@import "../assets/scss/style";
</style>
