<template>
  <icon-base
    icon-name="locked-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 6V5C11 3.34315 9.65685 2 8 2C6.34315 2 5 3.34315 5 5V6H11ZM4 5V6H3.6C3.26863 6 3 6.26863 3 6.6V12C3 13.1046 3.89543 14 5 14H11C12.1046 14 13 13.1046 13 12V6.6C13 6.26863 12.7314 6 12.4 6H12V5C12 2.79086 10.2091 1 8 1C5.79086 1 4 2.79086 4 5ZM11 7H12V12C12 12.5523 11.5523 13 11 13H5C4.44772 13 4 12.5523 4 12V7H5H11ZM8 8.5C7.72386 8.5 7.5 8.72386 7.5 9V10.5C7.5 10.7761 7.72386 11 8 11C8.27614 11 8.5 10.7761 8.5 10.5V9C8.5 8.72386 8.27614 8.5 8 8.5Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>
    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 7V5C11 3.34315 9.65685 2 8 2C6.34315 2 5 3.34315 5 5V7H11ZM8 1C5.79086 1 4 2.79086 4 5V8H12V5C12 2.79086 10.2091 1 8 1Z"
        :fill="fillColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.6 6C3.26863 6 3 6.26863 3 6.6V12C3 13.1046 3.89543 14 5 14H11C12.1046 14 13 13.1046 13 12V6.6C13 6.26863 12.7314 6 12.4 6H3.6ZM8 8.5C7.72386 8.5 7.5 8.72386 7.5 9V10.5C7.5 10.7761 7.72386 11 8 11C8.27614 11 8.5 10.7761 8.5 10.5V9C8.5 8.72386 8.27614 8.5 8 8.5Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import { computed, reactive } from "vue";
import IconBase from "../../common/IconBase";

export default {
  name: "LockedIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#818287",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
