<template>
  <icon-base
    icon-name="list-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 3.5C3.22386 3.5 3 3.72386 3 4C3 4.27614 3.22386 4.5 3.5 4.5H4.5C4.77614 4.5 5 4.27614 5 4C5 3.72386 4.77614 3.5 4.5 3.5H3.5ZM3.5 7.5C3.22386 7.5 3 7.72386 3 8C3 8.27614 3.22386 8.5 3.5 8.5H4.5C4.77614 8.5 5 8.27614 5 8C5 7.72386 4.77614 7.5 4.5 7.5H3.5ZM3 12C3 11.7239 3.22386 11.5 3.5 11.5H4.5C4.77614 11.5 5 11.7239 5 12C5 12.2761 4.77614 12.5 4.5 12.5H3.5C3.22386 12.5 3 12.2761 3 12ZM6.5 3.5C6.22386 3.5 6 3.72386 6 4C6 4.27614 6.22386 4.5 6.5 4.5H12.5C12.7761 4.5 13 4.27614 13 4C13 3.72386 12.7761 3.5 12.5 3.5H6.5ZM6.5 7.5C6.22386 7.5 6 7.72386 6 8C6 8.27614 6.22386 8.5 6.5 8.5H12.5C12.7761 8.5 13 8.27614 13 8C13 7.72386 12.7761 7.5 12.5 7.5H6.5ZM6 12C6 11.7239 6.22386 11.5 6.5 11.5H12.5C12.7761 11.5 13 11.7239 13 12C13 12.2761 12.7761 12.5 12.5 12.5H6.5C6.22386 12.5 6 12.2761 6 12Z"
      :fill="fillColor"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
export default {
  name: "ListIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
