<template>
  <icon-base
    icon-name="Heart-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.2083 3.00835C3.98005 3.00835 3 3.90043 3 5.27713C3 6.30455 3.38108 6.89984 3.70309 7.40287C3.71739 7.4252 3.73157 7.44735 3.74559 7.46935C4.58832 8.7912 6.00163 10.5791 8 12.8403C9.99837 10.5791 11.4117 8.7912 12.2544 7.46935C12.2684 7.44735 12.2826 7.4252 12.2969 7.40288C12.6189 6.89984 13 6.30455 13 5.27713C13 3.90043 12.0199 3.00835 10.7917 3.00835C10.1125 3.00835 9.50342 3.32299 9.0965 3.82306L9.02381 3.91761L8.5631 4.54669C8.28331 4.92874 7.71669 4.92874 7.4369 4.54669L6.97619 3.91761L6.9035 3.82306C6.49658 3.32298 5.88749 3.00835 5.2083 3.00835ZM2 5.27713C2 3.32628 3.44504 2 5.2083 2C6.20576 2 7.09539 2.46535 7.682 3.19014L7.6899 3.1999L7.77726 3.31377L8 3.61815L8.22981 3.30435L8.31788 3.19004C8.90449 2.46525 9.79424 2 10.7917 2C12.555 2 14 3.32628 14 5.27713C14 6.60467 13.4821 7.412 13.1434 7.93987C13.127 7.96543 13.1111 7.99034 13.0956 8.01462C12.1777 9.45441 10.6475 11.374 8.5211 13.7655C8.24311 14.0782 7.75689 14.0782 7.4789 13.7655C5.35254 11.374 3.82233 9.45441 2.90441 8.01462C2.88893 7.99034 2.87295 7.96543 2.85655 7.93987C2.51792 7.41201 2 6.60467 2 5.27713Z"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "HeartIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
